<button
  class="close-button stat-button-close"
  data-dtm="close modal"
  mat-icon-button
  (click)="closePopup()"
  style="float: right"
>
  <mat-icon>close</mat-icon>
</button>
<p style="margin: 1rem" [innerHTML]="displayedText"></p>

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { EarningsResponse } from 'src/app/_shared/models/earning-models/earnings-response';
import { CurrentEarningsResponse } from 'src/app/_shared/models/statement-credit/current-earnings-response';

@Injectable({
  providedIn: 'root'
})
export class SummaryService {
  private cardRedemptionInfoSubject: BehaviorSubject<EarningsResponse> =
    new BehaviorSubject<EarningsResponse>(null);
  private currentEarningsInfoSubject: BehaviorSubject<CurrentEarningsResponse> =
    new BehaviorSubject<CurrentEarningsResponse>(null);
  private cardRedemptionInfo: EarningsResponse;
  private currentEarnings: CurrentEarningsResponse;

  get cardRedemption$(): Observable<EarningsResponse> {
    return this.cardRedemptionInfoSubject.asObservable();
  }

  get currentEarnings$(): Observable<CurrentEarningsResponse> {
    return this.currentEarningsInfoSubject.asObservable();
  }

  getTotalEarnings(): Observable<number> {
    return of(this.cardRedemptionInfo.earnings.availableEarnings.total);
  }

  getCardMemberRedemptionInfo(): EarningsResponse {
    return this.cardRedemptionInfo;
  }

  getCurrentEarnings(): CurrentEarningsResponse {
    return this.currentEarnings;
  }

  setCardMemberRedemptionInfo(earningResponse: EarningsResponse) {
    this.cardRedemptionInfo = earningResponse;
    this.cardRedemptionInfoSubject.next(earningResponse);
  }

  setCurrentEarnings(currentEarningsResp: CurrentEarningsResponse) {
    this.currentEarnings = currentEarningsResp;
    this.currentEarningsInfoSubject.next(currentEarningsResp);
  }
}

<div class="container" *ngIf="showLoader">
  <div class="row">
    <div class="column" style="padding: 5%">
      <h2>Loading your redemption history...</h2>
      <mat-spinner [diameter]="150"></mat-spinner>
    </div>
  </div>
</div>

<div class="container" *ngIf="!showLoader">
  <h1 class="title">Redemption History</h1>

  <div class="redemption-history-table">
    <div class="row header">
      <div>Redemption Date</div>
      <div>Redemption Amount</div>
      <div>Redemption Type</div>
      <div>Authorization Number</div>
    </div>

    <ng-container *ngIf="redemptionHistory">
      <div class="row" *ngFor="let redemption of redemptionHistory">
        <div>{{ redemption.date | date : 'MM/dd/yyyy' }}</div>
        <div>{{ redemption.redemptionAmount | currency : 'USD' }}</div>
        <div>{{ redemption.redemptionType }}</div>
        <div>{{ redemption.authorizationNumber }}</div>
      </div>
    </ng-container>

    <div class="row no-history" *ngIf="!redemptionHistory">
      <div>There is no Redemption History to display.</div>
    </div>
  </div>
</div>

<div class="container" *ngIf="!showLoader">
  <ul class="link-list">
    <li>
      <a [routerLink]="'../summary'" class="return"
        >Return to your Earnings Statement</a
      >
    </li>
    <li>
      <a class="cta-primary hide-on-mobile" (click)="printHistory()"
        >Print Redemption History</a
      >
    </li>
  </ul>
</div>

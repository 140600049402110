import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { RedeemAmountService } from 'src/app/authenticated/services/redeem-amount.service';
import { SummaryService } from '../../../../authenticated/services/summary.service';
import { Subject } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { CurrentEarningsResponse } from '../../../../_shared/models/statement-credit/current-earnings-response';
import { Router } from '@angular/router';
import { CardService } from '../../../services/card.service';
import { Card } from '../../../../_shared/models/card-models/card';
import { ApiService } from 'src/app/_shared/services/api.service';
import { AdobeDtmService } from '../../../../_shared/services/adobe-dtm.service';
import { PageInfo } from '../../../../_shared/models/adobe/page-info';
import { CurrencyPipe } from '@angular/common';
import { Title } from '@angular/platform-browser';

export interface Wallet {
  availableEarnings: number;
  newGMVehicle: number;
}

const STATEMENT_DATA: Wallet[] = [
  { availableEarnings: 0.0, newGMVehicle: 0.0 }
];

@Component({
  selector: 'app-redeem-amount',
  templateUrl: './redeem-amount.component.html',
  styleUrls: ['./redeem-amount.component.scss']
})
export class RedeemAmountComponent implements OnInit, OnDestroy, AfterViewInit {
  wallet: Wallet[] = STATEMENT_DATA;
  redeemAmount: string;
  redeemAmountForm: UntypedFormGroup;
  tableColumns = ['newGMVehicle', 'availableEarnings'];
  componentDestroyed = new Subject();
  currentEarnings: CurrentEarningsResponse;
  cardType;

  constructor(
    private redeemService: RedeemAmountService,
    private dataSummary: SummaryService,
    private dataCard: CardService,
    private dataApi: ApiService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private readonly adobeDTMService: AdobeDtmService,
    private currencyPipe: CurrencyPipe,
    private readonly titleService: Title
  ) {}

  numericValidator(control: AbstractControl): { [key: string]: any } | null {
    const value = control.value;
  
    // Check if the value contains only numeric characters
    if (!/^\d*\.?\d*$/.test(value)) {
      return { 'invalidNumericValue': true };
    }
  
    return null; // Return null to indicate the input is valid
  }

  ngOnInit() {
    this.redeemService
      .getAmountObservable()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(amount => (this.redeemAmount = amount));

    this.dataSummary.currentEarnings$
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((currentEarn: CurrentEarningsResponse) => {
        this.wallet[0].availableEarnings = currentEarn?.earningsForRedemption;
        this.redeemService.setCurrentTotal(
          String(this.wallet[0].availableEarnings)
        );

        this.redeemAmountForm = this.formBuilder.group({
          statementCreditAmount: [
            '',
            [
              this.numericValidator,
              Validators.required,
              Validators.min(50),
              Validators.max(Number(this.wallet[0].availableEarnings))
            ]
          ]
        });
      });

    this.dataCard
      .getCurrentCard()
      .pipe(
        takeUntil(this.componentDestroyed),
        switchMap((card: Card) => {
          this.cardType = card;
          this.titleService.setTitle(
            `Statement Credit Redemption | ${card.name}`
          );
          return this.dataApi.getCurrentEarnings(card.trackId, 'GmVehicle');
        })
      )
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        this.wallet[0].newGMVehicle = data.earningsForRedemption;
      });
  }

  ngAfterViewInit() {
    this.sendTrackingInfo();
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  updateAmount() {
    const redemptionCredit =
      this.redeemAmountForm.controls.statementCreditAmount.value;
    this.redeemService.changeAmount(redemptionCredit);
    this.redeemService.changeRemaining(redemptionCredit);
    this.router.navigate(['redeem-confirm']);
  }

  sendTrackingInfo() {
    let digitalData: PageInfo = {
      pageInfo: {
        cardType: this.cardType.analyticsType,
        cardTypeOwned: this.cardType.analyticsTypeOwned,
        loginType: this.cardType.analyticsLoginType,
        offerID: '',
        pageGoal: 'nogoal',
        pageName: 'redemption.step1 | logged | ' + this.cardType.analyticsType,
        pageSubType: '',
        pageType: 'redeem',
        visitorStatus: 'logged'
      },
      formInfo: {
        formName: 'redeem form',
        formFieldName: '',
        errorMessage: ''
      }
    };

    digitalData = this.adobeDTMService.setupWindowPageInfoData(
      digitalData,
      null,
      'Redeem'
    );

    this.adobeDTMService.setWindowObjectData(digitalData);
    this.adobeDTMService.sendAdobeLaunchData();
  }

}
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VehiclesService {

  filterVehiclesByType(type, data) {
    type = type.toLowerCase();
    const chevrolet = data
      .filter(el => el[0] === 'Chevrolet')[0][1]
      //console.info(chevrolet, "chevroletArray");
      .filter(vehicle => vehicle.type === type);
    const gmc = data
      .filter(el => el[0] === 'GMC')[0][1]
      .filter(vehicle => vehicle.type === type);
    const buick = data
      .filter(el => el[0] === 'Buick')[0][1]
      .filter(vehicle => vehicle.type === type);
    const cadillac = data
      .filter(el => el[0] === 'Cadillac')[0][1]
      .filter(vehicle => vehicle.type === type);

    return [
      ['Chevrolet', chevrolet],
      ['Buick', buick],
      ['GMC', gmc],
      ['Cadillac', cadillac]
    ];
  }

  orderVehicleArrayByYear(data: any[]) {
    return data.sort((vehiclea, vehicleb) =>
      this.compareYears(vehiclea, vehicleb)
    );
  }

  compareYears(vehicleA, vehicleB) {
    if (vehicleA.year > vehicleB.year) {
      return -1;
    } else if (vehicleA.year < vehicleA.year) {
      return 1;
    } else {
      return 0;
    }
  }

  orderVehicleData(data) {
    // Filter removed vehicles from the API on vehicleId
    const removedVehicles = [
      '32315', // 2020 Buick Regal Sportback
      '32316' // 2020 Buick TourX
    ];

    const chevrolet = data
      .filter(el => el[0] === 'Chevrolet')[0][1]
      .filter(el => !removedVehicles.includes(el.vehicleId));
    const gmc = data
      .filter(el => el[0] === 'GMC')[0][1]
      .filter(el => !removedVehicles.includes(el.vehicleId));
    const buick = data
      .filter(el => el[0] === 'Buick')[0][1]
      .filter(el => !removedVehicles.includes(el.vehicleId));
    const cadillac = data
      .filter(el => el[0] === 'Cadillac')[0][1]
      .filter(el => !removedVehicles.includes(el.vehicleId));

    return [
      ['Chevrolet', this.orderVehicleArrayByYear(chevrolet)],
      ['Buick', this.orderVehicleArrayByYear(buick)],
      ['GMC', this.orderVehicleArrayByYear(gmc)],
      ['Cadillac', this.orderVehicleArrayByYear(cadillac)]
    ];
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/authenticated/services/user.service';
import { ContentService } from 'src/app/_shared/services/content.service';
import { AccountDetails } from '../../models/account-models/account-details';
import { CardService } from 'src/app/authenticated/services/card.service';
import { Card } from '../../models/card-models/card';
import { DisclaimerModalComponent } from '../disclaimer-modal/disclaimer-modal.component';
import { LegalModalComponent } from '../legal-modal/legal-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { AemService } from '../../../_shared/services/aem-content.service';
import { ApiService } from 'src/app/_shared/services/api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() isAuthenticated: boolean;
  windowSubscription: any;
  componentDestroyed = new Subject();
  user: AccountDetails;
  userSubscription: Subscription;
  routerSubscription: Subscription;
  currentCard: Card;
  disclaimers: Array<any>;
  contactUsLink: string;

  constructor(
    public dialog: MatDialog,
    private readonly userService: UserService,
    private readonly contentService: ContentService,
    private readonly cardService: CardService,
    public router: Router,
    public location: Location,
    private domSanitizer: DomSanitizer,
    private aem: AemService,
    private readonly apiService: ApiService
  ) {}

  ngOnInit(): void {
    this.contactUsLink =
      this.contentService.getContactUsLink() + '?evar25=gm_card_footer';
    this.userSubscription = this.userService.getAccount().subscribe(user => {
      this.user = user;
      this.disclaimerCheckUserCard();
    });

    this.router.routerState.root.fragment.subscribe((fragment: string) => {
      if (fragment === 'print') {
        this.location.replaceState(this.location.path(false));
        // It's late, I'm tired
        const dirtyHack = setTimeout(() => {
          clearTimeout(dirtyHack);
          window.print();
        }, 3000);
      }
    });

    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.getRouteDisclaimers();
        window.scrollTo(0, 0);
      }
    });
  }

  disclaimerCheckUserCard(): void {
    this.cardService.getCurrentCard().subscribe(card => {
      this.currentCard = card;
      this.getRouteDisclaimers();
    });
  }

  getRouteDisclaimers(): void {
    this.apiService.getAemPage('json-content').subscribe(data =>{
      this.disclaimerService(data)
    })
  }

  disclaimerService(disclaimerData){
    const disclaimersJson = JSON.parse(disclaimerData?.disclaimersJson);
    let routeUrl = this.router.url;
    routeUrl = routeUrl.split('#')[0];
    routeUrl = routeUrl.split('?')[0];

    if (typeof disclaimersJson[routeUrl] === 'undefined') {
      this.disclaimers = disclaimerData['default'];
      console.log('No route disclaimers found, loaded default disclaimer');
      return;
    }

    if (
      typeof disclaimersJson[routeUrl][this.currentCard.path] !== 'undefined'
    ) {
      this.disclaimers = disclaimersJson[routeUrl][this.currentCard.path];
    } else if (typeof disclaimersJson[routeUrl]['default'] !== 'undefined') {
      this.disclaimers = disclaimersJson[routeUrl]['default'];
    } else {
      this.disclaimers = disclaimersJson['default'];
      console.log(
        'No disclaimers found for this route, loaded default disclaimer'
      );
    }

    for (let i = 0; i < this.disclaimers.length; i++) {
      this.disclaimers[i] = this.domSanitizer.bypassSecurityTrustHtml(
        this.disclaimers[i]
      );
    }

    if (routeUrl.indexOf('/offers') !== -1) {
      console.log('Hide route disclaimer');
      setTimeout(() => {
        document.getElementById(
          'dynamic_offer_disclaimer'
        ).parentElement.style.display = 'none';
      }, 1000);
    }
  }

  navigateToFooter(): void {
    window.scrollTo(0, 0);
    void this.router.navigate(['faqs']);
  }

  openLegalModal(): void {
    this.dialog.open(LegalModalComponent, {
      panelClass: 'full-size-dialog'
    });
  }

  openDisclaimerModal(): void {
    this.dialog.open(DisclaimerModalComponent, {
      panelClass: 'full-size-dialog',
      data: { disclaimers: this.disclaimers }
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
    this.userSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }
}

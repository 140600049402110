<div class="d-flex flex-row justify-content-between align-items-center">
  <h1 class="center-text">
    We can't find that address associated with a GM Account
  </h1>
</div>

<mat-dialog-content>
  <p class="center-text">
    To access your GM Rewards Card Account info, click “Create Account” below.
    Then follow the prompts to link your card(s) to your new GM Account in order
    to access your GM Rewards Card(s) information.
  </p>
</mat-dialog-content>

<button
  (click)="createAccount()"
  class="gb-primary-button popup-white-button stat-button-link"
  data-dtm="modal:not found"
>
  Create Account
</button>
<div
  class="cancel-link stat-text-link d-flex flex-row justify-content-center align-items-center"
  (click)="close()"
  data-dtm="modal:not found"
>
  Cancel
</div>

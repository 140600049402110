<div class="container">
  <div class="row-no-center">
    <div class="column-no-justify">
      <h1>Request Canceled</h1>

      <p>
        Your statement credit request has now been canceled. If you’d like to<br />
        request a statement credit, you can start the request over again below.
      </p>

      <div class="button-container">
        <button
          class="gb-primary-button green-button stat-button-link"
          [routerLink]="'../redeem-amount'"
          data-dtm="cash redemption:cancelled"
        >
          Start Over
        </button>
      </div>
    </div>
  </div>
</div>

import { AfterViewInit, Component, Input } from '@angular/core';
import { AdobeDtmService } from 'src/app/_shared/services/adobe-dtm.service';
import { PageInfo } from 'src/app/_shared/models/adobe/page-info';
import { Card } from 'src/app/_shared/models/card-models/card';
import { ApiService } from 'src/app/_shared/services/api.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-efc-program-rules',
  templateUrl: './efc-program-rules.component.html',
  styleUrls: ['./efc-program-rules.component.scss']
})
export class EfcProgramRulesComponent implements AfterViewInit {
  @Input() card: Card;
  contents: Array<any>;

  constructor(private readonly adobeDTMService: AdobeDtmService,
    private readonly apiService: ApiService,
    private domSanitizer:DomSanitizer
    ) {}

  ngOnInit(){
    this.getContent()
  }

  ngAfterViewInit() {
    this.sendTrackingInfo();
  }

  getContent(): void {
    this.apiService.getAemPage('json-content').subscribe(data =>{
      this.programRules(data);
    })
  }

  programRules(programRulesData){
    const programRulesJson = JSON.parse(programRulesData?.programRules);
    this.contents = programRulesJson['program-rules-efc'];
    if(this.contents !== undefined){
    for (let i = 0; i < this.contents.length; i++) {
      this.contents[i] = this.domSanitizer.bypassSecurityTrustHtml(
        this.contents[i]
      )
    }
  }
}
  sendTrackingInfo() {
    let digitalData: PageInfo = {
      pageInfo: {
        cardType: this.card.analyticsType,
        cardTypeOwned: this.card.analyticsTypeOwned,
        loginType: this.card.analyticsLoginType,
        offerID: '',
        pageGoal: 'nogoal',
        pageName: 'program.rules | logged | ' + this.card.analyticsType,
        pageSubType: '',
        pageType: 'info',
        seoStrategyPageName: 'Program Rules | efc',
        visitorStatus: 'logged'
      },
      formInfo: {
        formName: '',
        formFieldName: '',
        errorMessage: ''
      }
    };

    digitalData = this.adobeDTMService.setupWindowPageInfoData(
      digitalData,
      null,
      'Program Rules | efc'
    );

    this.adobeDTMService.setWindowObjectData(digitalData);
    this.adobeDTMService.sendAdobeLaunchData();
  }
}

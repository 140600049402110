<div class="link-account row-no-center">
  <div class="link-account__details column-no-justify">
    <h2>Link Your Marcus Account</h2>
    <p>
      Single sign-on lets you link your Marcus and
      <span class="nowrap">GM Rewards Card</span> accounts so there's no need to
      login separately. You can change this at any time in your My GM Account
      settings.
    </p>
  </div>
  <div class="link-account__link column">
    <a
      [href]="accountPath + 'rewards/account-settings?evar25=gm_card_manage_your_card'"
      class="stat-button-link cta-primary"
      data-dtm="manage your card"
      rel="external"
      target="_blank"
      title="open in a new tab"
      (click)="sendTrackingInfo()"
      >MANAGE SINGLE SIGN-ON<img
        [src]="'/assets/img/icons/link_out_white.png'"
        alt="Click this link to go to an external site"
        class="link-out"
    /></a>
  </div>
</div>

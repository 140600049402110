<div class="container">
  <div class="row-no-center">
    <div class="column-no-justify">
      <h1>Statement Credit Redemption Review</h1>
      <div class="amount-container">
        <h3>
          You're about to request a statement credit redemption <br />in the
          amount of
        </h3>
        <h2>{{amountToRedeem | currency}}</h2>
        <br class="d-block d-md-none" />
        <a
          class="stat-text-link"
          id="changeAmount"
          href=""
          [routerLink]="'../redeem-amount'"
          data-dtm="cash redemption:step2"
          >Change Amount</a
        >
      </div>

      <div class="remaining-container">
        <h2>{{amountRemaining | currency}}</h2>
        <p>
          <span>
            Your remaining 
            <br class="d-block d-md-none"/>
            balance after redemption.
          </span>
        </p>
      </div>

      <p class="error" *ngIf="showError">{{errorMessage}}</p>

      <div class="button-container">
        <button
          class="gb-primary-button light-grey-button stat-button-link"
          [routerLink]="'../redeem-cancel'"
          data-dtm="cash redemption:step2"
        >
          CANCEL
        </button>

        <button
          class="gb-primary-button green-button stat-button-link"
          [disabled]="loading"
          (click)="processRedemptionStatement()"
          data-dtm="cash redemption:step2"
        >
          SUBMIT
        </button>
      </div>

      <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-error-notice',
  templateUrl: './error-notice.component.html',
  styleUrls: ['./error-notice.component.scss']
})
export class ErrorNoticeComponent implements OnInit {
  error: string;
  constructor(private dialogRef: MatDialogRef<ErrorNoticeComponent>) {}

  ngOnInit(): void {}

  close(): void {
    this.dialogRef.close();
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ContentService } from '../../../_shared/services/content.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Card } from '../../../_shared/models/card-models/card';
import { CardService } from '../../../authenticated/services/card.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss'],
  encapsulation: ViewEncapsulation.None // Needed so child components can use parent styling
})
export class FaqsComponent implements OnInit {
  componentDestroyed = new Subject();
  cardType: Card;
  faqData: any;
  contactUsLink: string;

  constructor(
    private readonly contentService: ContentService,
    private readonly cardService: CardService,
    private readonly titleService: Title
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.contactUsLink =
      this.contentService.getContactUsLink() + '?evar25=gm_card_get_answers';
    this.cardService
      .getCurrentCard()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(card => {
        this.cardType = card;
        this.titleService.setTitle(`FAQS | ${card.name}`);
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }
}

import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AuthenticatedModule } from './authenticated/authenticated.module';
import { UnauthenticatedModule } from './unauthenticated/unauthenticated.module';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule, Location } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CustomHttpInterceptor } from './_shared/services/http.interceptor';
import { ApiService } from './_shared/services/api.service';
import { CardService } from './authenticated/services/card.service';
import { UserService } from './authenticated/services/user.service';
import { UserData } from './_shared/models/card-models/user-data';
import { BnNgIdleService } from 'bn-ng-idle';
import { AemService } from './_shared/services/aem-content.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    AuthenticatedModule,
    UnauthenticatedModule,
    RouterModule.forRoot([]),
    BrowserAnimationsModule,
    HttpClientModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
      deps: [Location]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initSessionData,
      multi: true,
      deps: [ApiService, CardService, UserService]
    },
    {
      provide: BnNgIdleService
    },
    AemService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function initSessionData(
  apiService: ApiService,
  cardService: CardService,
  userService: UserService
) {
  return () => {
    return apiService
      .getVerifyUserSession()
      .toPromise()
      .then(
        (userData: UserData) => {
          if (userData) {
            cardService.setUserCards(userData?.cards?.userCards);
            userService.setUserId(userData?.iamProfileId);
            cardService
              .getUsersRewardCards()
              .subscribe(c => console.log(' === user cards: ', c));
          }
        },
        error => {
          return error;
        }
      );
  };
}

import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SecurityContext
} from '@angular/core';
import { Card } from '../../../../_shared/models/card-models/card';
import { ContentService } from '../../../../_shared/services/content.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EarningsCalculator } from '../../../../_shared/models/summary-models/earnings-calculator';
import { CurrencyPipe } from '@angular/common';
import { MatSliderChange } from '@angular/material/slider';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AemService } from '../../../../_shared/services/aem-content.service';

@Component({
  selector: 'app-earnings-calculator',
  templateUrl: './earnings-calculator.component.html',
  styleUrls: ['./earnings-calculator.component.scss']
})
export class EarningsCalculatorComponent implements OnInit, OnDestroy {
  @Input() cardType: Card;
  @Input() earnings: number;
  calculator: EarningsCalculator;
  calculatedCashValue = 0;
  yearlyEarnings = 0;
  private componentDestroyed = new Subject();

  constructor(
    private contentService: ContentService,
    private currencyPipe: CurrencyPipe,
    private domSanitizer: DomSanitizer,
    private aem: AemService
  ) { }

  ngOnInit(): void {
    const cardPath = this.cardType?.path ?? 'flex';
    this.aem
      .currentContent
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(calculator => {
        if (cardPath === 'copperplatinum') {
          const cpJson = JSON.parse(calculator.cpEarningsCalculatorJson);
          this.calculator = cpJson;
        } else if (cardPath === 'bluegold') {
          const bgJson = JSON.parse(calculator.blueGoldEarningsCalculatorJson);
          this.calculator = bgJson;
        } else if (cardPath === 'bpbc') {
          const bpbcJson = JSON.parse(calculator.bpbcEarningsCalculatorJson);
          this.calculator = bpbcJson;
        } else if (cardPath === 'efc') {
          const efcJson = JSON.parse(calculator.efcEarningsCalculatorJson);
          this.calculator = efcJson;
        }

        this.calculator.disclaimer.final =
          this.domSanitizer.bypassSecurityTrustHtml(
            this.domSanitizer.sanitize(
              SecurityContext.HTML,
              this.calculator.disclaimer.final
            )
          );
        this.calculator.disclaimer.content =
          this.domSanitizer.bypassSecurityTrustHtml(
            this.domSanitizer.sanitize(
              SecurityContext.HTML,
              this.calculator.disclaimer.content
            )
          );
        for (const sliderConfig of this.calculator?.sliders) {
          sliderConfig.value = 0;
        }
        this.setupTrackLimits();
      });
  }

  setupTrackLimits(): void {
    for (let i = 0; i < this.calculator?.sliders.length; i++) {
      const sliderConfig = this.calculator?.sliders[i];
      this.calculator.sliders[i].value = 0;
      if (sliderConfig.sliderLimitCap) {
        // As much as I hate this, we need to give the element a moment to become visible. I'm sure there's probably a better way
        // ngAfterViewOnit doesn't seem to work here for whatever reason, and the launch deadline approaches :/
        setTimeout(function () {
          const sliderFillEl = document.querySelector(
            `#slider${i} > div > div.mat-slider-track-wrapper > div.mat-slider-track-fill`
          );
          const newSliderFillEl = sliderFillEl.cloneNode(true);
          (newSliderFillEl as HTMLElement).className += ' secondary-track-fill';
          sliderFillEl.parentNode.appendChild(newSliderFillEl);
        }, 1000);
      }
    }
  }

  formatLabel(value: number): string {
    return this.currencyPipe.transform(value, 'USD', 'symbol', '1.0-0');
  }

  updateEarnings(sliderIndex: number, event: MatSliderChange): void {
    this.calculateYearlyEarnings(sliderIndex, event.value);
    this.drawSliderLimit(sliderIndex, event.value);
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  private calculateYearlyEarnings(
    sliderIndex: number,
    sliderEventValue: number
  ) {
    this.yearlyEarnings = 0;

    for (let i = 0; i < this.calculator?.sliders.length; i++) {
      const sliderConfig = this.calculator?.sliders[i];
      let sliderValue = sliderConfig.value;

      // The active sliders value won't get updated until the mouse is released
      if (i === sliderIndex) {
        sliderValue = sliderEventValue;
      }
      const sliderYear = sliderValue * 12;
      let sliderEarnings = 0;
      if (!sliderConfig.afterPercent) {
        sliderEarnings = sliderYear * sliderConfig.beforePercent;
      } else {
        sliderEarnings;
        sliderConfig.sliderLimitCap * sliderConfig.beforePercent +
          (sliderYear - sliderConfig.sliderLimitCap) *
          sliderConfig.afterPercent;
      }

      if (
        sliderConfig.sliderLimitCap &&
        sliderEarnings > sliderConfig.sliderLimitCap
      ) {
        sliderEarnings = sliderConfig.sliderLimitCap;
      }
      this.yearlyEarnings += sliderEarnings;
    }
  }

  private drawSliderLimit(sliderIndex: number, sliderValue: number) {
    const sliderConfig = this.calculator?.sliders[sliderIndex];
    if (
      !sliderConfig.sliderLimitCap ||
      sliderValue > sliderConfig.sliderLimitCap
    ) {
      return;
    }
    const sliderFillEl = document.querySelector(
      `#slider${sliderIndex} > div > div.mat-slider-track-wrapper > div.secondary-track-fill`
    );
    const sliderThumbContainer = document.querySelector(
      `#slider${sliderIndex} > div > div.mat-slider-thumb-container`
    );
    // This is going to be something like "translateX(-97.6%)"
    const sliderTransform = (sliderThumbContainer as HTMLElement).style
      .transform;

    const transformValue = sliderTransform.substring(
      sliderTransform.indexOf('(') + 1,
      sliderTransform.indexOf(')') - 1
    );
    const fillPercent = (100 - Math.abs(Number(transformValue))) / 100;
    (
      sliderFillEl as HTMLElement
    ).style.transform = `translateX(0px) scale3d(${fillPercent}, 1, 1)`;
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-saml-login',
  template: '',
  styleUrls: []
})
export class SamlLoginComponent implements OnInit {
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit(): void {
    // TODO: Add in user GET call
    // const accountID = this.activatedRoute.snapshot.url[2].path;
    this.router.navigate(['/summary']);
  }
}

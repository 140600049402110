<div class="container">
  <div class="section row">
    <div class="column">
      <h1 class="title">See How Much You Can Save!</h1>
      <p class="faq-copy">
        Click on the tabs below to see your exclusive Cardmember and GM Vehicle
        Offers. Most can be combined with your Current Earnings<br
          class="hide-on-mobile"
        />
        so you can enjoy even greater value toward your next new GM vehicle.
      </p>
    </div>
  </div>

  <div class="row tab-list desktop-nav" id="tab-list">
    <nav mat-tab-nav-bar style="width: 100%" data-dtm="offers">
      <a
        mat-tab-link
        id="card-tab"
        class="tab-style active-tab stat-tab-link no-underline-link"
        (click)="toggleCardOffers()"
        data-dtm="offers"
        >Cardmember-Only Offers</a
      >
    </nav>
  </div>

  <div class="mobile-nav" id="tab-list">
    <button
      id="card-tab-mobile"
      class="mobile-nav-element active-tab-mobile stat-tab-link"
      (click)="toggleCardOffers()"
      [attr.data-dtm]="'offers'"
    >
      CARDMEMBER-ONLY OFFERS
    </button>
    <button
      id="vehicle-tab-mobile"
      class="mobile-nav-element stat-tab-link"
      (click)="toggleVehicleOffers()"
      [attr.data-dtm]="'offers'"
    >
      CURRENT GM VEHICLE OFFERS
    </button>
  </div>

  <!-- CARDMEMBER-ONLY OFFERS tab -->
  <div class="cardmember-offers window row" *ngIf="showCardOffers">
    <div class="column" *ngIf="!hasCardOffers">
      <br />
      <p>No offers are currently available. Please check back later.</p>
      <br />
    </div>

    <div class="column" *ngIf="cardMemberOffers.length">
      <div *ngIf="hasCardOffers" class="accordion-container">
        <div class="column" *ngIf="cardMemberOffers.length === 0">
          <h2>Checking for offers...</h2>
          <mat-spinner [diameter]="150"></mat-spinner>
        </div>
        <mat-accordion multi *ngIf="cardMemberOffers.length > 0">
          <ng-container>
            <div
              #offerPanel
              *ngFor="let offer of cardMemberOffers"
              [id]="'offer-' + offer?.offerId"
            >
              <mat-expansion-panel [expanded]="true" hideToggle>
                <mat-expansion-panel-header
                  [attr.data-dtm]="'cardmember-only offers:' + offer.offersPage.ctaDtm"
                  [ngClass]="[currentRewardCard.path === 'bpbc' ? 'business-header' : 'panel-header-green', 'panel-header', 'stat-expand-icon']"
                >
                  <mat-panel-title class="panel-title-green">
                    <mat-icon class="plus">add</mat-icon>
                    <mat-icon class="minus">remove</mat-icon>
                    <span [innerHTML]="offer.offersPage.title"></span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-expansion-panel-body>
                  <div class="offer-background">
                    <p
                      [innerHTML]="offer.offersPage.body | safeHtml"
                      [ngClass]="offer.offersPage.image.length ? 'has-image' : ''"
                    ></p>
                    <img
                      class="offer-image d-none d-xl-block"
                      *ngIf="offer.offersPage.image.length"
                      [src]="offer.offersPage.image"
                      alt=""
                    />
                    <a
                      *ngIf="offer.offersPage.cta.length"
                      target="_blank"
                      href="{{ offer.offersPage.ctaLink }}"
                      class="stat-button-link cta-primary"
                      [attr.data-dtm]="'cardmember-only offers:' + offer.offersPage.ctaDtm"
                      [innerHTML]="offer.offersPage.cta"
                    ></a>
                    <img
                      class="mobile-offer-image d-block d-lg-none"d
                      *ngIf="offer.offersPage.image.length"
                      [src]="offer.offersPage.image"
                      alt=""
                    />
                  </div>
                  <div class="offer-disclaimers">
                    <p
                      *ngFor="let disclaimer of offer?.offersPage?.disclaimers"
                      [innerHTML]="disclaimer"
                    ></p>
                  </div>
                </mat-expansion-panel-body>
              </mat-expansion-panel>
            </div>
          </ng-container>
        </mat-accordion>
      </div>
    </div>
  </div>

  <!-- CURRENT GM VEHICLE OFFERS tab -->
  <div class="vehicle-offers window row" *ngIf="showVehicleOffers">
    <div class="column">
      <h1 class="current-offers">Current Vehicle Offers</h1>
      <div>
        <a
          class="stat-image-link"
          href="https://www.chevrolet.com/current-deals.html?evar25=gm_card_offers_current_vehicles_offers_logo"
          target="_blank"
          title="open in a new tab"
          (click)="sendTrackingInfo()"
          data-dtm="current vehicle offers"
        >
          <img
            alt="Chevrolet Logo"
            class="logos"
            src="assets/img/logos/Chevy_Logo.png"
            title="open in a new tab"
        /></a>
        <a
          class="stat-image-link"
          href="https://www.buick.com/current-offers.html?evar25=gm_card_offers_current_vehicles_offers_logo"
          target="_blank"
          title="open in a new tab"
          (click)="sendTrackingInfo()"
          data-dtm="current vehicle offers"
        >
          <img
            alt="Buick Logo"
            class="logos"
            src="assets/img/logos/Buick_Logo.png"
            title="open in a new tab"
        /></a>
        <a
          class="stat-image-link"
          href="https://www.gmc.com/current-offers.html?evar25=gm_card_offers_current_vehicles_offers_logo"
          target="_blank"
          title="open in a new tab"
          (click)="sendTrackingInfo()"
          data-dtm="current vehicle offers"
        >
          <img
            alt="GMC Logo"
            class="logos"
            src="assets/img/logos/GMC_Logo.png"
            title="open in a new tab"
        /></a>
        <a
          class="stat-image-link"
          href="https://www.cadillac.com/current-offers.html?evar25=gm_card_offers_current_vehicles_offers_logo"
          target="_blank"
          title="open in a new tab"
          (click)="sendTrackingInfo()"
          data-dtm="current vehicle offers"
        >
          <img
            alt="Cadillac Logo"
            class="logos"
            src="assets/img/logos/Cadillac_Logo.png"
            title="open in a new tab"
        /></a>
      </div>

      <h2 class="title">Vehicle Offers</h2>
      <p>Enter your Zip Code to see pricing and incentives in your area.</p>

      <div class="row">
        <form [formGroup]="regionalOffersForm">
          <mat-form-field class="zip-input">
            <input
              matInput
              formControlName="zipCode"
              placeholder="Enter ZIP Code"
              class="stat-input-field"
              [attr.maxlength]="constants.ZIP_LENGTH"
              data-dtm="current vehicle offers"
            />
            <mat-error
              class="error"
              *ngIf="regionalOffersForm.get('zipCode').errors?.required"
            >
              Please enter your ZIP Code above to find Current GM Vehicle Offers
              near you.
            </mat-error>
            <mat-error
              *ngIf="regionalOffersForm.get('zipCode').errors?.pattern"
            >
              ZIP Code must be 5 characters long.
            </mat-error>
          </mat-form-field>
          <button
            mat-flat-button
            class="zip-btn stat-button-link"
            [disabled]="!regionalOffersForm.valid"
            (click)="submitZipCode(zipCode)"
            data-dtm="current vehicle offers"
          >
            <span class="material-icons">refresh</span>
          </button>
        </form>
      </div>

      <p class="large-top">
        <em
          >Please note: Vehicle offers shown reflect only what is currently
          available. Not all vehicles will be listed.</em
        >
      </p>

      <div class="row dropdown filter-row" *ngIf="showVehicleDropDowns">
        <div class="dropdown-input stat-dropdown" data-dtm="vehicle year">
          <p>Vehicle Year:</p>
          <select [(ngModel)]="selectedYear" (change)="resetFilters('year')">
            <option
              *ngFor="let year of vehicleOffers | unique: 'year' : false"
              [value]="year"
            >
              {{ year }}
            </option>
          </select>
        </div>

        <div class="dropdown-input stat-dropdown" data-dtm="vehicle make">
          <p>Vehicle Make:</p>
          <select [(ngModel)]="selectedMake" (change)="resetFilters('make')">
            <option value="" [selected]="defaultMake">All</option>
            <option
              *ngFor="let make of vehicleOffers | filter: { year: selectedYear } | unique: 'make' : false"
              [value]="make"
            >
              {{ make }}
            </option>
          </select>
        </div>

        <div class="dropdown-input stat-dropdown" data-dtm="vehicle model">
          <p>Vehicle Model:</p>
          <select
            [(ngModel)]="selectedModel"
            [disabled]="!selectedMake"
            (change)="resetFilters('model')"
          >
            <option value="" [selected]="defaultModel">All Vehicles</option>
            <option
              *ngFor="let model of vehicleOffers | filter: { year: selectedYear, make: selectedMake } | unique: 'model' : false"
              [value]="model"
            >
              {{ model }}
            </option>
          </select>
        </div>
      </div>

      <div
        *ngIf="showVehicleDropDowns"
        class="accordion-container filter-accordion"
      >
        <mat-accordion multi>
          <ng-container
            *ngIf="(vehicleOffers | filter: { year: selectedYear, make: selectedMake, model: selectedModel }) as filteredOffers"
          >
            <mat-expansion-panel
              class="stat-expand-icon"
              [attr.data-dtm]="make.toLowerCase()"
              *ngFor="let make of filteredOffers | unique: 'make' : false"
            >
              <mat-expansion-panel-header class="panel-header make-title">
                <mat-panel-title class="panel-title">
                  <span>{{ make }}</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container
                *ngIf="(filteredOffers | filter: { make: make }) as filteredOffersForMake"
              >
                <div
                  class="row offers-text"
                  *ngFor="let offer of filteredOffersForMake"
                >
                  <!-- Model Name column -->
                  <div class="col">
                    <p class="uppercase model">{{ offer.model }}</p>
                  </div>

                  <!-- APR OFFER column -->
                  <div class="col">
                    <p>APR OFFER</p>
                    <p *ngIf="offer.bestApr" class="value">
                      {{ offer.bestApr.apr }}% APR<sup>&dagger;</sup> for {{
                      offer.bestApr.months }} months for qualified buyers
                    </p>
                    <p
                      *ngIf="!offer.bestApr && !offer.bonusCashApr"
                      class="value"
                    >
                      No Available APR
                    </p>
                  </div>

                  <!-- OR column -->
                  <div class="or-col">
                    <p>&mdash; OR &mdash;</p>
                  </div>

                  <!-- CASH OFFER column -->
                  <div class="col">
                    <p>CASH OFFER</p>
                    <p *ngIf="offer.bonusCash > 0" class="value">
                      {{offer.bonusCash | currency: 'USD':'symbol':'1.0-0'}}
                      Purchase Bonus Cash<sup>**</sup>
                    </p>
                    <p *ngIf="offer.consumerCash > 0" class="value">
                      <span
                        *ngIf="offer.bonusCash > 0 && offer.consumerCash > 0"
                        >Plus</span
                      >
                      {{offer.consumerCash | currency: 'USD':'symbol':'1.0-0'}}
                      Consumer Purchase Cash<sup>**</sup>
                    </p>
                    <p *ngIf="offer.otherCashValues > 0" class="value">
                      <span
                        *ngIf="(offer.otherCashValues > 0 && offer.bonusCash > 0) || (offer.otherCashValues > 0 && offer.consumerCash > 0)"
                        >Plus</span
                      >
                      {{offer.otherCashValues | currency:
                      'USD':'symbol':'1.0-0'}} Other Cash Values<sup>**</sup>
                    </p>
                    <p *ngIf="offer.cash > 0" class="value">
                      {{offer.cash | currency: 'USD':'symbol':'1.0-0'}} Total
                      Cash Allowance
                    </p>
                    <p
                      *ngIf="offer.cash <= 0 || offer.cash === null"
                      class="value"
                    >
                      No Available Cash Offer
                    </p>
                  </div>
                </div>
              </ng-container>
            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="column">
      <h2 class="title">If you've got a question, we're here to help.</h2>
      <p class="no-top no-bottom">
        Find answers to your questions in the
        <a [routerLink]="'/faqs'" class="stat-text-link" data-dtm="get answers"
          >FAQs</a
        >. Not finding what you're looking for?
      </p>
      <p class="no-top">Click to contact us.</p>
      <a
        target="_blank"
        [href]="contactUsLink"
        class="stat-button-link cta-primary"
        data-dtm="get answers"
        >CONTACT US</a
      >
    </div>
  </div>
</div>

<mat-card appearance="outlined" class="login d-flex flex-row">
  <div class="d-none d-lg-block flex-45">
    <img src="../../assets/img/signin-person.png" class="left-image" />
  </div>

  <div class="content flex-90 flex-50">
    <img src="../../assets/img/logos/gmrewardscards-vehicle-brands.png" />
    <header class="login-header">
      Important changes to your account login
    </header>
    <p class="login-title">
      You can now use <strong>one email address and password</strong> to sign in
      to your GM accounts, including your Chevrolet, Buick, GMC and Cadillac
      Account or mobile app.
    </p>
    <ul>
      <li>
        <strong>If you have an existing GM Account,</strong> enter the email
        address associated with the account below.
      </li>
      <li>
        <strong>If you do not have an existing GM Account,</strong> please enter
        the email address you’d like to use to create a new account.
      </li>
    </ul>
    <p class="login-title">
      Once signed in, you may need to link your<br />GM Rewards Card to
      associate it with your<br />GM Account, if you haven’t done so already.
    </p>

    <form [formGroup]="signInForm" (submit)="verifyEmail()">
      <section class="input-login-form gb-medium-margin">
        <div class="login-info-container-main-form-input gb-input">
          <input
            aria-label="emailAddress"
            formControlName="emailAddress"
            id="emailAddress"
            placeholder="Email Address"
            type="text"
            class="stat-input-field"
            data-dtm="single sign in"
          />
          <span class="animate-underline"></span>
          <mat-progress-bar
            *ngIf="loginValidation"
            mode="indeterminate"
          ></mat-progress-bar>

          <div
            class="error-container"
            *ngIf="signInForm.controls['emailAddress'].invalid && (signInForm.controls['emailAddress'].dirty || signInForm.controls['emailAddress'].touched)"
          >
            <span
              class="error"
              *ngIf="signInForm.controls['emailAddress'].errors.required"
              >Email Address is required</span
            >
            <span
              class="error"
              *ngIf="signInForm.controls['emailAddress'].errors.email"
              >Email Address is in an invalid format</span
            >
          </div>
        </div>
      </section>
      <section class="gb-medium-margin checkbox-wrapper">
        <mat-checkbox
          formControlName="hidePage"
          class="example-margin stat-checkbox"
          data-dtm="single sign in"
          >Click here if you don't want this message<br
            class="hide-on-mobile"
          />
          to appear again.
        </mat-checkbox>
      </section>
      <section class="input-login-form gb-medium-margin">
        <button
          (click)="verifyEmail()"
          [disabled]="loginValidation || signInForm.invalid"
          class="gb-primary-button blue-button stat-button-link"
          data-dtm="single sign in"
          id="checkEarnings"
        >
          Continue
        </button>
      </section>
    </form>
    <section class="gb-medium-margin">
      <p>
        If you have questions or need assistance,<br />
        please call 1-844-764-2665.
      </p>
    </section>

    <section class="gb-medium-margin h3-styling">
      <h3>One Sign in for your rewards, vehicles and apps.</h3>
      <img
        class="brand-images"
        src="../../assets/img/logos/vehicle-brands.png"
      />
    </section>
  </div>
</mat-card>

import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AdobeDtmService } from '../../../../_shared/services/adobe-dtm.service';
import { PageInfo } from '../../../../_shared/models/adobe/page-info';
import { CardService } from '../../../../authenticated/services/card.service';
import { Subject } from 'rxjs';
import { Card } from '../../../../_shared/models/card-models/card';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-redeem-cancel',
  templateUrl: './redeem-cancel.component.html',
  styleUrls: ['./redeem-cancel.component.scss']
})
export class RedeemCancelComponent implements OnInit, AfterViewInit, OnDestroy {
  componentDestroyed = new Subject();
  cardType;

  constructor(
    private readonly adobeDTMService: AdobeDtmService,
    private readonly cardService: CardService
  ) {}

  ngOnInit(): void {
    this.cardService
      .getCurrentCard()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((card: Card) => {
        this.cardType = card;
      });
  }

  ngAfterViewInit(): void {
    this.sendTrackingInfo();
  }

  sendTrackingInfo() {
    let digitalData: PageInfo = {
      pageInfo: {
        pageName: 'redemption.cancel | logged | ' + this.cardType.analyticsType,
        pageType: 'redeem',
        pageSubType: '',
        cardType: this.cardType.analyticsType,
        visitorStatus: 'logged',
        cardTypeOwned: this.cardType.analyticsTypeOwned,
        offerID: '',
        loginType: this.cardType.analyticsLoginType,
        pageGoal: 'nogoal'
      },

      formInfo: {
        formName: 'redeem form',
        formFieldName: '',
        errorMessage: ''
      }
    };

    digitalData = this.adobeDTMService.setupWindowPageInfoData(
      digitalData,
      null,
      'Redemption Credit'
    );

    this.adobeDTMService.setWindowObjectData(digitalData);
    this.adobeDTMService.sendAdobeLaunchData();
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }
}

<div class="container">
  <div class="row-no-center">
    <div class="column-no-justify">
      <h1>Statement Credit Redemption Confirmation</h1>
      <div class="receipt">
        <p>Statement Credit Redeemed: {{ amountToRedeem | currency }}</p>
        <p>Authorization Number: {{ this.authorizationNumber }}</p>
        <p>Earnings Remaining: {{ this.amountRemaining | currency }}</p>
      </div>
      <p>
        Congratulations! You have successfully requested your statement credit
        redemption.<br />The GM Cardmember Services Redemption Center will
        process your request.<br />You should see a credit to your Account
        within 48 hours.
      </p>

      <div class="button-container">
        <button
          class="gb-primary-button green-button stat-button-link"
          (click)="printAuthClick()"
          style="margin-right: 20px"
          data-dtm="cash redemption:confirmation"
        >
          Print Confirmation
        </button>
      </div>
    </div>
  </div>
</div>

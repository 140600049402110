import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { SummaryService } from '../../services/summary.service';
import { ApiService } from '../../../_shared/services/api.service';
import { CardService } from '../../services/card.service';
import { Card } from '../../../_shared/models/card-models/card';
import { Earnings } from '../../../_shared/models/earning-models/earnings';
import { LastRedemption } from '../../../_shared/models/earning-models/last-redemption';
import { EarningsResponse } from '../../../_shared/models/earning-models/earnings-response';
import { ContentService } from '../../../_shared/services/content.service';
import { CurrentEarningsResponse } from '../../../_shared/models/statement-credit/current-earnings-response';
import { PageInfo } from 'src/app/_shared/models/adobe/page-info';
import { AdobeDtmService } from 'src/app/_shared/services/adobe-dtm.service';
import { ErrorModalComponent } from 'src/app/_shared/components/error-modal/error-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit, OnDestroy {
  componentDestroyed = new Subject();

  cardType: Card;
  earnings: Earnings;
  lastRedemption: LastRedemption;
  cardRedemptionInfo: EarningsResponse;
  currentCardEarnings: CurrentEarningsResponse;
  earningsSubscription: Subscription;
  user: any;
  earningsResponse: any;
  contactUsLink: string;
  digitalData: PageInfo;
  viewReady: boolean;

  constructor(
    private readonly summaryService: SummaryService,
    private readonly apiService: ApiService,
    private readonly cardService: CardService,
    private readonly contentService: ContentService,
    private readonly adobeDTMService: AdobeDtmService,
    public dialog: MatDialog,
    public router: Router,
    public viewportScroller: ViewportScroller,
    private readonly titleService: Title,
  ) { }

  contentData;
  ngOnInit(): void {
    this.contactUsLink =
      this.contentService.getContactUsLink() + '?evar25=gm_card_get_answers';
    this.cardService
      .getCurrentCard()
      .pipe(
        takeUntil(this.componentDestroyed),
        switchMap((card: Card) => {
          this.cardType = card;
          this.titleService.setTitle(
            `Earnings Summary | ${this.cardType?.name}`
          );
          if (card !== null) {
            return this.apiService.getEarningsStatement(card.trackId);
          } else {
            return [];
          }
        })
      )
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(
        data => {
          this.cardRedemptionInfo = data;
          this.earnings = data?.earnings;
          this.summaryService.setCardMemberRedemptionInfo(data);
          this.lastRedemption = data?.lastRedemption;
          this.earningsResponse =
            this.summaryService.getCardMemberRedemptionInfo();
          this.router.routerState.root.fragment
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((fragment: string) => {
              setTimeout(() => {
                this.viewportScroller.scrollToAnchor(fragment);
              }, 0);
            });
          this.viewReady = true;
        },
        error => {
          this.dialog.open(ErrorModalComponent);
          console.error(error);
        }
      );

    this.earningsSubscription = this.summaryService.currentEarnings$.subscribe(
      (earnings: CurrentEarningsResponse) => {
        this.currentCardEarnings = earnings;
      }
    );

    let loginType = this.cardType.analyticsLoginType;
    if (loginType === 'copperplatinum' || loginType === 'platinum') {
      loginType = '';
    }
    this.digitalData = {
      pageInfo: {
        pageName: 'view.earnings | logged | ' + this.cardType.analyticsType,
        pageType: 'summary',
        pageSubType: '',
        cardType: this.cardType.analyticsType,
        visitorStatus: 'logged',
        cardTypeOwned: this.cardType.analyticsTypeOwned,
        offerID: '',
        loginType: loginType,
        pageGoal: 'nogoal'
      }
    };

    this.digitalData = this.adobeDTMService.setupWindowPageInfoData(
      this.digitalData,
      null,
      'Manage Card'
    );
    this.adobeDTMService.setWindowObjectData(this.digitalData);
    this.adobeDTMService.sendAdobeLaunchData();
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
    this.earningsSubscription.unsubscribe();
  }
}

<app-efc-program-rules
  *ngIf="card.path == 'efc'"
  [card]="card"
></app-efc-program-rules>
<app-business-program-rules
  *ngIf="card.path == 'bpbc'"
  [card]="card"
></app-business-program-rules>
<app-copperplatinum-program-rules
  *ngIf="card.path == 'copperplatinum'"
  [card]="card"
></app-copperplatinum-program-rules>
<app-bluegold-program-rules
  *ngIf="card.path == 'bluegold'"
  [card]="card"
></app-bluegold-program-rules>

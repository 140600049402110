<div [ngClass]="benefitsClass">
  <h2
    [ngClass]="benefitsClass+'--header'"
    [innerHTML]="benefits?.components?.teasers?.heading"
  ></h2>
  <div [ngClass]="benefitsClass+'--items'">
    <div
      *ngFor="let item of benefits?.components?.teasers?.items"
      [ngClass]="benefitsClass+'--item'"
    >
      <div [ngClass]="benefitsClass+'--item__image'">
        <img [alt]="item.icon?.alt" [src]="item.icon?.src" />
      </div>
      <div [ngClass]="benefitsClass+'--item--content'">
        <h3 [ngClass]="benefitsClass+'--item--content__title'">
          <span [innerHTML]="item.title | uppercase"></span>
          <br *ngIf="item.title2" />
          <span [innerHTML]="item.title2 | uppercase"></span>
        </h3>
        <a (click)="toggleTeaser(item.id)" class="mobile-learn-more"
          >LEARN MORE</a
        >
        <p [className]="item.id + ' mobile-hidden'">
          <span
            [ngClass]="benefitsClass+'--item--content__1'"
            [innerHTML]="item.content"
          ></span>
          <br *ngIf="!item.noBreaks" />
          <a
            *ngIf="item.link"
            [href]="item.link.url"
            [ngClass]="benefitsClass+'--item--link__1 stat-text-link'"
            [rel]="item.link.rel"
            [target]="item.link.target"
            [attr.data-dtm]="item.link.dataDtm"
            (click)="sendTrackingInfo()"
          >
            {{ item.link.text }}
            <img
              *ngIf="item.link.image"
              [src]="item.link.image"
              alt="link opens in a new tab"
            />
          </a>
          <span
            *ngIf="item.content2"
            [ngClass]="benefitsClass+'--item--content__2'"
            [innerHTML]="item.content2"
          ></span>
          <span
            *ngIf="item.content3"
            [ngClass]="benefitsClass+'--item--content__3'"
            [innerHTML]="item.content3"
          ></span>
          <a
            *ngIf="item.link2"
            [href]="item.link2.url"
            [ngClass]="benefitsClass+'--item--link__2 stat-text-link'"
            class="stat-text-link"
            [rel]="item.link2.rel"
            [target]="item.link2.target"
            [attr.data-dtm]="item.link.dataDtm"
            [innerHTML]=""
          >
            {{item.link2.text}}
            <img
              *ngIf="item.link2.image"
              [src]="item.link2.image"
              alt="link opens in a new tab"
            />
          </a>
          <span
            *ngIf="item.content4"
            [ngClass]="benefitsClass+'--item--content__4'"
            [innerHTML]="item.content4"
          ></span>
        </p>
      </div>
    </div>
  </div>
</div>

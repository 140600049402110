import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StatementCreditResponse } from 'src/app/_shared/models/statement-credit/statement-credit-response';

@Injectable({
  providedIn: 'root'
})
export class RedeemAmountService {
  private redeemAmountSource = new BehaviorSubject('0');
  private redeemRemainingSource = new BehaviorSubject('0');
  private authorizationData = new BehaviorSubject<StatementCreditResponse>(
    null
  );
  currentTotal: string;
  currentAmount = this.getAmountObservable();
  currentRemaining = this.getRemainingObservable();

  changeAmount(amount: string) {
    this.redeemAmountSource.next(amount);
  }

  changeRemaining(amount: string) {
    this.redeemRemainingSource.next(
      String(Number(this.currentTotal) - Number(amount))
    );
  }

  getAmount() {
    return this.redeemAmountSource.getValue();
  }

  getRemaining() {
    return this.redeemRemainingSource.getValue();
  }

  setCurrentTotal(total: string) {
    this.currentTotal = total;
  }

  getAuthorizationData(): Observable<StatementCreditResponse> {
    return this.authorizationData.asObservable();
  }

  setAuthorizationData(authorization: StatementCreditResponse) {
    this.authorizationData.next(authorization);
  }

  getAmountObservable() {
    return this.redeemAmountSource.asObservable();
  }

  getRemainingObservable() {
    return this.redeemRemainingSource.asObservable();
  }
}

import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { CardService } from 'src/app/authenticated/services/card.service';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  cardApiUrl = '/cardrs/rest';

  constructor(
    private readonly router: Router,
    private cardService: CardService,
    private location: Location
  ) {}

  handleUnauthRoutes() {
    const locationRoute = this.location.href;
    const unauthenticatedRoutes = ['error', 'corp', 'dealerapr', 'login'];
    if (unauthenticatedRoutes.some(_ => locationRoute.indexOf(_) > -1)) {
      return false;
    } else {
      return true;
    }
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Modifications for all requests
    let newRequest;
    if (request.url.includes('https://gmcard.preprod.drmp.gm.com')) {
      newRequest = request;
    } else {
      newRequest = request.clone({
        url: `${this.cardApiUrl}/${request.url}`
        // Modifications for all POST requests
      });
    }

    if (newRequest.method === 'POST') {
      newRequest = newRequest.clone({
        withCredentials: true, // to allow cookies to go from localhost
        headers: newRequest.headers.set('Content-Type', 'application/json')
      });
    }

    return next.handle(newRequest).pipe(
      //TODO: this cause a optimization warning when running app
      catchError(err => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          // Replace clear cards logic somewhere else
          this.cardService.getUsersRewardCards()
            ? this.cardService.clearCards()
            : (this.cardService.rewardCards = null);
          if (this.handleUnauthRoutes()) {
            void this.router.navigate(['/']).then();
          }
        }
        throw err;
      })
    );
  }
}

<div id="redeem-print">
  <div class="center-text-container" *ngIf="data">
    <h1 class="title">How to Redeem</h1>
    <div class="redeem-earnings">
      <h1>Your Current Earnings:</h1>
      <h1 class="total-earnings">{{data.totalEarnings | currency:'USD'}}</h1>
    </div>

    <h1 class="title">Redeeming Earnings is Easy</h1>

    <div class="redeem-guide">
      <div class="redeem-guide--item" *ngFor="let item of data.redeemGuideList">
        <div class="redeem-guide--item__image">
          <img [src]="item.image" />
        </div>
        <div class="redeem-guide--item--text">
          <div
            class="redeem-guide--item--text__big"
            [innerHtml]="item.bigText"
          ></div>
          <div
            class="redeem-guide--item--text__small"
            [innerHtml]="item.smallText"
          ></div>
        </div>
      </div>
    </div>
    <div class="redeem-guide-efc--info" *ngIf="data.selectedCard === 'efc'">
      GM employees and retirees can go to
      <a>GM Family First</a> {{data.gmFamilyFirstUrl}} or call 1-800-235-4646.
      GM Suppliers can visit
      <a [href]="gmSupplierDiscountUrl">
        {{data.gmSupplierDiscountUrl}} GM Supplier Discount</a
      >
      or call 1-800-960-3375
    </div>

    <div class="search-result">
      <h2>Visit a Nearby Dealer</h2>
      <p>All Chevrolet, Buick, GMC and Cadillac Dealers participate.</p>
      <div class="select-make">
        {{data.searchResult ? data.searchResult.searchInput.make : 'Select\
        make'}}
      </div>
      <div class="select-zipcode">
        {{data.searchResult ? data.searchResult.searchInput.zipcode :
        'zipcode'}}
      </div>
      <div class="search-dealer">
        {{data.searchResult ? data.searchResult.result.length : ''}} Dealer
        Found
      </div>
      <div class="search-result-table" *ngIf="data.searchResult">
        <div class="search-result-table--item">
          <p>{{data.searchResult.result[0].dealerName}}</p>
          <p>{{data.searchResult.result[0].dealerAddress}}</p>
          <p>
            {{data.searchResult.result[0].dealerCity}},
            {{data.searchResult.result[0].dealerState}}
          </p>
          <p>{{data.searchResult.result[0].dealerPhone}}</p>
        </div>
      </div>
    </div>
    <h1 class="title">Get Answers</h1>
    <p>
      If you have any questions, check out our
      <a [routerLink]="['/faqs']">FAQs</a> page or
      <a target="_blank" [href]="contactUsLink">Contact Us.</a>
    </p>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Card } from '../../../../_shared/models/card-models/card';
import { DescriptionModalComponent } from '../../../../_shared/components/description-modal/description-modal.component';
import { Subject, Subscription } from 'rxjs';
import { SummaryService } from '../../../../authenticated/services/summary.service';
import { CurrentEarningsResponse } from '../../../../_shared/models/statement-credit/current-earnings-response';
@Component({
  selector: 'app-earnings',
  templateUrl: './earnings.component.html',
  styleUrls: ['./earnings.component.scss']
})
export class EarningsComponent implements OnInit {
  @Input() cardType: Card;
  @Input() earnings: any;

  earningsOverviewHeader: string;
  isFlex: boolean; // Flex has unique categories
  isBusiness: boolean; // Business Card has unique categories
  redemptionHeader: string;
  currentEarnings: CurrentEarningsResponse;
  earningsSubscription: Subscription;
  componentDestroyed = new Subject();
  displayLast4 = '';

  constructor(
    public dialog: MatDialog,
    private readonly summaryService: SummaryService
  ) {}

  ngOnInit(): void {
    // TODO: Move appropriate values to constants
    this.earningsOverviewHeader = 'Earnings Overview as of last statement date';
    this.redemptionHeader = 'Redemption Activity';
    this.displayLast4 = this.cardType.last4CardNumber.padStart(4, '0') 

    this.earningsSubscription = this.summaryService.currentEarnings$.subscribe(
      (earnings: CurrentEarningsResponse) => {
        this.currentEarnings = earnings;
      }
    );
  }

  showPopup(template: string): void {
    const dialogRef = this.dialog.open(DescriptionModalComponent, {
      width: '780px'
    });
    dialogRef.componentInstance.descriptionCode = template;
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
    this.earningsSubscription.unsubscribe();
  }
}

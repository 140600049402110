import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CardService } from '../../../authenticated/services/card.service';
import { Card } from '../../models/card-models/card';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  componentDestroyed = new Subject();
  currentRewardCard: Card;
  navLink: string;
  authenticated: boolean;

  constructor(private readonly cardService: CardService) {}

  ngOnInit(): void {
    this.cardService
      .getCurrentCard()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(card => {
        this.currentRewardCard = card;
        if (this.currentRewardCard.path) {
          this.navLink = '/summary';
          this.authenticated = true;
        } else {
          this.navLink = '';
          this.authenticated = false;
        }
      });
  }

  ngAfterViewInit(): void {
    this.addCardAttributes();
  }

  addCardAttributes(): void {
    if (this.currentRewardCard) {
      let dataDtm =
        'global nav:' + this.currentRewardCard.name.toLocaleLowerCase();
      if (!this.authenticated) {
        dataDtm = 'global nav';
      }
      document.getElementById('logo_element').setAttribute('data-dtm', dataDtm);
      document.getElementById('marcus_logo').setAttribute('data-dtm', dataDtm);
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }
}

<h3 [innerHTML]="content.subhead"></h3>
<p [innerHTML]="content.copy"></p>
<div class="button-container">
  <a
    class="
      button-cancel
      gb-primary-button
      light-grey-button
      stat-button-link
      thirdparty-link-button
    "
    [attr.data-dtm]="content.dataDtm"
    (click)="closePopup()"
    >Cancel</a
  >
  <a
    class="
      button-continue
      gb-primary-button
      green-button
      stat-button-link
      thirdparty-link-button
    "
    (click)="closePopup()"
    target="_blank"
    [href]="content.redirectUrl"
    [attr.data-dtm]="content.dataDtm"
    >Continue</a
  >
</div>

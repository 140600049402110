import { Component, Input, AfterViewInit } from '@angular/core';
import { AdobeDtmService } from 'src/app/_shared/services/adobe-dtm.service';
import { PageInfo } from 'src/app/_shared/models/adobe/page-info';
import { Card } from '../../../../_shared/models/card-models/card';

@Component({
  selector: 'app-faqs-bpbc',
  templateUrl: './faqs-bpbc.component.html',
  styleUrls: ['./faqs-bpbc.component.scss']
})
export class FaqsBpbcComponent implements AfterViewInit {
  @Input() cardType: Card;
  constructor(private readonly adobeDTMService: AdobeDtmService) {}

  ngAfterViewInit(): void {
    this.sendTrackingInfo();
  }

  sendTrackingInfo() {
    let digitalData: PageInfo = {
      pageInfo: {
        cardType: 'bpbc',
        cardTypeOwned: 'business',
        loginType: 'bpc business',
        offerID: '',
        pageGoal: 'goal',
        pageName: 'faq | logged | bpbc',
        pageSubType: '',
        pageType: 'faq',
        visitorStatus: 'logged'
      }
    };

    digitalData = this.adobeDTMService.setupWindowPageInfoData(
      digitalData,
      '',
      'FAQ | ' + this.cardType.name.toLocaleLowerCase()
    );
    this.adobeDTMService.setWindowObjectData(digitalData);
    this.adobeDTMService.sendAdobeLaunchData();
  }
}

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RedeemAmountService } from 'src/app/authenticated/services/redeem-amount.service';
import { CardService } from '../../../services/card.service';
import { Card } from '../../../../_shared/models/card-models/card';
import { AdobeDtmService } from '../../../../_shared/services/adobe-dtm.service';
import { PageInfo } from '../../../../_shared/models/adobe/page-info';
import { SummaryService } from 'src/app/authenticated/services/summary.service';
import { ApiService } from 'src/app/_shared/services/api.service';

@Component({
  selector: 'app-redeem-receipt',
  templateUrl: './redeem-receipt.component.html',
  styleUrls: ['./redeem-receipt.component.scss']
})
export class RedeemReceiptComponent implements OnInit, AfterViewInit {
  amountToRedeem: string;
  amountRemaining: string;
  authorizationNumber = '';
  card: Card;
  componentDestroyed = new Subject();

  constructor(
    private redeemService: RedeemAmountService,
    private cardService: CardService,
    private summaryService: SummaryService,
    private apiService: ApiService,
    private readonly adobeDTMService: AdobeDtmService
  ) {}

  ngOnInit(): void {
    this.redeemService
      .getAuthorizationData()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(authorization => {
        this.authorizationNumber = authorization.authorizationNumber;
        this.amountToRedeem = authorization.earningApplied;
      });

    this.redeemService
      .getRemainingObservable()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(remaining => (this.amountRemaining = remaining));

    this.cardService
      .getCurrentCard()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(card => {
        this.card = card;
      });

    this.apiService
      .getCurrentEarnings(this.card.trackId, 'Cash')
      .subscribe(earnData => {
        this.summaryService.setCurrentEarnings(earnData);
      });
  }

  ngAfterViewInit() {
    this.sendTrackingInfo();
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  printAuthClick() {
    this.printScreen();
    this.sendTrackingInfo();
  }

  printScreen(): void {
    window.print();
  }

  sendTrackingInfo() {
    let digitalData: PageInfo = {
      pageInfo: {
        cardType: this.card.analyticsType,
        cardTypeOwned: this.card.analyticsTypeOwned,
        loginType: this.card.analyticsLoginType,
        offerID: '',
        pageGoal: 'nogoal',
        pageName: 'redemption.step3 | logged | ' + this.card.analyticsType,
        pageSubType: '',
        pageType: 'redeem',
        visitorStatus: 'logged'
      },
      formInfo: {
        formName: 'redeem form',
        formFieldName: '',
        errorMessage: ''
      }
    };

    digitalData = this.adobeDTMService.setupWindowPageInfoData(
      digitalData,
      null,
      'Redemption Credit Confirmation'
    );

    this.adobeDTMService.setWindowObjectData(digitalData);
    this.adobeDTMService.sendAdobeLaunchData();
  }
}

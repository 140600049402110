import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-privacy-modal',
  templateUrl: './privacy-modal.component.html',
  styleUrls: ['./privacy-modal.component.scss']
})
export class PrivacyModalComponent {
  @Input()
  label = 'GM Privacy Statement';

  constructor(public dialog: MatDialog) {}

  openDialog(): void {
    this.dialog.open(PrivacyModalContentComponent);
  }
}

@Component({
  selector: 'app-privacy-modal-content',
  templateUrl: './privacy-modal-content.component.html',
  styleUrls: ['./privacy-modal.component.scss']
})
export class PrivacyModalContentComponent {
  constructor(public dialogRef: MatDialogRef<PrivacyModalContentComponent>) {}

  closeDialog() {
    this.dialogRef.close();
  }
}

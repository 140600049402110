import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedContainerComponent } from './components/authenticated-container/authenticated-container.component';
import { SummaryComponent } from './components/summary/summary.component';
import { VehiclesComponent } from './components/vehicles/vehicles.component';
import { OffersComponent } from './components/offers/offers.component';
import { RedeemComponent } from './components/redeem/redeem.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { ProgramRulesContainerComponent } from './components/program-rules-container/program-rules-container.component';
import { RedeemAmountComponent } from './components/statement-credit/redeem-amount/redeem-amount.component';
import { RedeemCancelComponent } from './components/statement-credit/redeem-cancel/redeem-cancel.component';
import { RedeemConfirmComponent } from './components/statement-credit/redeem-confirm/redeem-confirm.component';
import { RedemptionHistoryComponent } from './components/redemption-history/redemption-history.component';
import { LoginGuard } from './services/guards/login.guard';
import { RedeemReceiptComponent } from './components/statement-credit/redeem-receipt/redeem-receipt.component';
import { ReturnGuard } from './services/guards/return.guard';
import { RedeemGuard } from './services/guards/redeem.guard';
import { LogoutComponent } from './components/logout/logout.component';

const routes: Routes = [
  { path: 'logout', component: LogoutComponent },
  { path: 'bluegold/:destination', redirectTo: ':destination' },
  { path: 'business/:destination', redirectTo: ':destination' },
  { path: 'copperplatinum/:destination', redirectTo: ':destination' },
  { path: 'efc/:destination', redirectTo: ':destination' },
  { path: 'flex/:destination', redirectTo: ':destination' },
  { path: 'mygmrewardscard/:destination', redirectTo: ':destination' },
  {
    path: 'route/:trackId',
    canActivate: [ReturnGuard],
    component: SummaryComponent
  },
  {
    path: '',
    component: AuthenticatedContainerComponent,
    canActivate: [LoginGuard],
    children: [
      { path: 'cash-redemption', redirectTo: 'redeem-amount' },
      { path: 'cash-redemption-step2', redirectTo: 'redeem-amount' },
      { path: 'cash-redemption-step3', redirectTo: 'redeem-amount' },
      { path: 'cash-redemption-error', redirectTo: 'redeem-amount' },
      { path: 'faqs', component: FaqsComponent },
      { path: 'faq', redirectTo: 'faqs', pathMatch: 'full' },
      { path: 'offers', redirectTo: 'offers/', pathMatch: 'full' },
      { path: 'offers/:offerId', component: OffersComponent },
      { path: 'program-rules', component: ProgramRulesContainerComponent },
      { path: 'redeem', component: RedeemComponent },
      { path: 'redeem-amount', component: RedeemAmountComponent },
      {
        path: '',
        canActivate: [RedeemGuard],
        children: [
          { path: 'redeem-cancel', component: RedeemCancelComponent },
          { path: 'redeem-confirm', component: RedeemConfirmComponent },
          { path: 'redeem-receipt', component: RedeemReceiptComponent }
        ]
      },
      { path: 'redemption-history', component: RedemptionHistoryComponent },
      { path: 'summary', component: SummaryComponent },
      { path: 'vehicles', component: VehiclesComponent },
      { path: 'eligible-vehicles', redirectTo: 'vehicles', pathMatch: 'full' },
      { path: '', redirectTo: 'summary', pathMatch: 'full' },
      { path: '**', pathMatch: 'full', redirectTo: 'summary' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticatedRoutingModule {}

<div class="container">
  <div class="row">
    <div class="column">
      <h1 class="title">Frequently Asked Questions</h1>
      <p class="faq-copy">
        If you have questions, you can find the answers below. Just click on the
        question, and the answer will appear below it.
      </p>
    </div>
  </div>

  <div class="row faq-accordion">
    <app-faqs-bluegold
      *ngIf="cardType.path === 'bluegold'"
      [cardType]="cardType"
    ></app-faqs-bluegold>
    <app-faqs-bpbc
      *ngIf="cardType.path === 'bpbc'"
      [cardType]="cardType"
    ></app-faqs-bpbc>
    <app-faqs-copperplatinum
      *ngIf="cardType.path === 'copperplatinum' || cardType.path === 'copper'"
      [cardType]="cardType"
    ></app-faqs-copperplatinum>
    <app-faqs-efc
      *ngIf="cardType.path === 'efc'"
      [cardType]="cardType"
    ></app-faqs-efc>
  </div>

  <div class="row">
    <div class="column">
      <p class="no-bottom">Not finding what you're looking for?</p>
      <p class="no-top">Click to contact us.</p>
      <a
        target="_blank"
        [href]="contactUsLink"
        class="stat-button-link cta-primary"
        data-dtm="get answers"
        >CONTACT US</a
      >
    </div>
  </div>
</div>

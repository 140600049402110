<mat-accordion multi>
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header
      data-dtm="faq:about the gm extended family card"
      class="stat-expand-icon"
    >
      <mat-panel-title class="panel-title">
        <mat-icon class="plus">add</mat-icon>
        <mat-icon class="minus">remove</mat-icon>
        <span class="panel-title">About the GM Extended Family&trade; Mastercard&reg;</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="text-left">
      <h2 class="question">What is the GM Extended Family&trade; Mastercard&reg;?</h2>
      <div class="answer">
        <p>
          The GM Extended Family&trade; Mastercard&reg; is a Credit Card that rewards you with
          Earnings on every purchase you make. Receive 1% Earnings redeemable
          toward the retail purchase or lease of an eligible, new Chevrolet,
          Buick, GMC or Cadillac vehicle.<sup>1</sup> There is no limit to the
          amount of Earnings you can accumulate within an Account year. Earnings
          are valid for seven years from the end of the billing cycle in which
          they were earned. Earnings expiration after seven years will occur
          month by month, so seven full years are maintained. Earnings can also
          be redeemed for statement credits in any amount over $50.
        </p>
        <p>
          Earnings can be redeemed in addition to most incentives GM may offer,
          including the
          <a
            class="stat-text-link"
            data-dtm="about the gm extended family card"
            href="https://www.gmfamilyfirst.com/about-the-discount/?evar25=gm_card_faq"
            target="_blank"
            >GM Employee Purchase Program</a
          >,
          <a
            class="stat-text-link"
            data-dtm="about the gm extended family card"
            href="https://www.gmsupplierdiscount.com/?evar25=gm_card_faq"
            target="_blank"
            >GM Supplier Discount Program</a
          >,
          <a
            class="stat-text-link"
            data-dtm="about the gm extended family card"
            href="https://www.gmfamilyfirst.com/vehicles/?evar25=gm_card_faq"
            target="_blank"
            >GM Employee QRD Purchase Program/Company Owned Vehicle Program</a
          >,
          <a
            class="stat-text-link"
            data-dtm="about the gm extended family card"
            href="https://www.gmeducatordiscount.com/?evar25=gm_card_faq"
            target="_blank"
            >GM Educator Discount</a
          >,
          <a
            class="stat-text-link"
            data-dtm="about the gm extended family card"
            href="https://www.gmfirstresponderdiscount.com/?evar25=gm_card_faq"
            target="_blank"
            >GM First Responder Discount</a
          >,
          <a
            class="stat-text-link"
            data-dtm="about the gm extended family card"
            href="https://www.gmcollegediscount.com/?evar25=gm_card_faq"
            target="_blank"
            >GM College Discount Program</a
          >, and
          <a
            class="stat-text-link"
            data-dtm="about the gm extended family card"
            href="https://www.gmmilitarydiscount.com/?evar25=gm_card_faq"
            target="_blank"
            >GM Military Program</a
          >.
        </p>
        <p>
          GM reserves the right to change the Program Rules, regulations and
          rewards. Please see the
          <a
            class="stat-text-link"
            data-dtm="about the gm extended family card"
            href="/program-rules"
            >GM Extended Family&trade; Mastercard&reg; Program Rules</a
          >
          for terms, conditions and limitations.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">What bank issues the GM Extended Family&trade; Mastercard&reg;?</h2>
      <div class="answer">
        <p>
          <a
            class="stat-text-link"
            data-dtm="about the gm extended family card"
            href="https://www.marcus.com/us/en"
            target="_blank"
            authenticatedCopy="false"
            >Goldman Sachs Bank USA<sup>&reg;</sup></a
          >
          is the issuer of the GM Extended Family&trade; Mastercard&reg;. GM is responsible for
          the operation and administration of the Earnings Program.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">
        Can I use my GM Employee or Supplier Discount with the GM Extended
        Family&trade; Mastercard&reg;?
      </h2>
      <div class="answer">
        <p>
          Yes, you can use your
          <a
            class="stat-text-link"
            data-dtm="about the gm extended family card"
            href=" https://www.gmfamilyfirst.com/about-the-discount?evar25=gm_card_faq"
            target="_blank"
            >GM Employee</a
          >
          or
          <a
            class="stat-text-link"
            data-dtm="about the gm extended family card"
            href="https://www.gmsupplierdiscount.com?evar25=gm_card_faq"
            target="_blank"
            >Supplier Discount</a
          >
          combined with most currently available incentives and your GM Extended
          Family&trade; Mastercard&reg; Earnings.
        </p>
        <p>
          If you’re eligible for a
          <a
            class="stat-text-link"
            data-dtm="about the gm extended family card"
            href=" https://www.gmfamilyfirst.com/about-the-discount?evar25=gm_card_faq"
            target="_blank"
            >GM Employee</a
          >
          or
          <a
            class="stat-text-link"
            data-dtm="about the gm extended family card"
            href="https://www.gmsupplierdiscount.com/?evar25=gm_card_faq"
            target="_blank"
            >GM Supplier</a
          >
          discount, when you’re ready to redeem, request an authorization
          number.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">
        Can my family and friends get the GM Extended Family&trade; Mastercard&reg;?
      </h2>
      <div class="answer">
        <p>
          The GM Extended Family&trade; Mastercard&reg; is available to family and friends but has
          been designed with GM Employee or Supplier Discount-qualified
          individuals in mind. They can compare Credit Cards to find the right Credit Card for
          them.
        </p>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header
      data-dtm="faq:gm extended family card earnings"
      class="stat-expand-icon"
    >
      <mat-panel-title class="panel-title">
        <mat-icon class="plus">add</mat-icon>
        <mat-icon class="minus">remove</mat-icon>
        <span class="panel-title">GM Extended Family&trade; Mastercard&reg; Earnings</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="text-left">
      <h2 class="question">How much can I accumulate in Earnings each year?</h2>
      <div class="answer">
        <p>
          There is no limit on the amount of Earnings that can be accumulated.
          For further details, see
          <a
            class="stat-text-link"
            data-dtm="gm extended family card earnings"
            href="/program-rules"
            >Program Rules</a
          >.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">How can I keep track of my Earnings?</h2>
      <div class="answer">
        <p>
          Current Cardmembers can view their Earnings Statement on the
          <a
            class="stat-text-link"
            data-dtm="gm extended family card earnings"
            href="/summary"
            >Summary Page</a
          >. You will also receive Earnings information in your GM Extended
          Family&trade; Mastercard&reg; billing statement from Marcus by Goldman Sachs.
        </p>
        <p>
          Earnings will appear in the “Earnings Summary” box. You may also call
          1-833-773-0988 with any questions.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">Can I receive Earnings on a Balance Transfer?</h2>
      <div class="answer">
        <p>
          Earnings can only be obtained on purchases. Earnings are not received
          on taxes, discounts, rebates, credits, incentives, cash advances,
          balance transfers, ATM withdrawals, finance charges or fees.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">
        Do I receive Earnings for purchases made by authorized users?
      </h2>
      <div class="answer">
        <p>
          Yes, Earnings are obtained for all purchases made on Credit Cards issued
          under the same GM Extended Family&trade; Mastercard&reg; Account Number, including
          authorized users.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">
        How long are my Earnings good for on the GM Extended Family&trade; Mastercard&reg;?
      </h2>
      <div class="answer">
        <p>
          Earnings are valid for seven years from the end of the billing cycle
          in which they were earned. Expiration after seven years will occur
          month by month, so seven full years of Earnings are maintained.
        </p>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header
      data-dtm="faq:redeeming earnings"
      class="stat-expand-icon"
    >
      <mat-panel-title class="panel-title">
        <mat-icon class="plus">add</mat-icon>
        <mat-icon class="minus">remove</mat-icon>
        <span class="panel-title">Redeeming Earnings</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="text-left">
      <h2 class="question">
        How do I redeem my GM Extended Family&trade; Mastercard&reg; Earnings toward a new
        Chevrolet, Buick, GMC or Cadillac vehicle?<sup>1</sup>
      </h2>
      <div class="answer">
        <p>
          To redeem your Earnings toward the retail purchase or lease of an
          eligible, new GM vehicle<sup>1</sup>, first check your available
          Earnings, then:
        </p>
        <ul>
          <li>
            Visit your Chevrolet, Buick, GMC or Cadillac Dealer and choose the
            new vehicle you want.
          </li>
          <li>
            Obtain a vehicle authorization number. GM employees and retirees can
            go to
            <a
              class="stat-text-link"
              data-dtm="redeeming earnings"
              href="https://www.gmfamilyfirst.com/about-the-discount?evar25=gm_card_faq"
              target="_blank"
              >gmfamilyfirst.com</a
            >
            or call 1-800-235-4646. GM suppliers can visit
            <a
              class="stat-text-link"
              data-dtm="redeeming earnings"
              href="https://www.gmsupplierdiscount.com?evar25=gm_card_faq"
              target="_blank"
              >gmsupplierdiscount.com</a
            >
            or call 1-800-960-3375.
          </li>
          <li>
            Combine your GM discount, if eligible, with most current offers and
            incentives.
          </li>
          <li>
            Then identify yourself as a GM Extended Family Cardmember and have
            the dealer deduct your available Earnings.
          </li>
        </ul>
        <p>
          Don't forget: If you qualify for the
          <a
            class="stat-text-link"
            data-dtm="redeeming earnings"
            href="https://www.gmfamilyfirst.com/about-the-discount?evar25=gm_card_faq"
            target="_blank"
            >GM Employee</a
          >
          or
          <a
            class="stat-text-link"
            data-dtm="redeeming earnings"
            href="https://www.gmsupplierdiscount.com/?evar25=gm_card_faq"
            target="_blank"
            >GM Supplier Discount</a
          >, these can be combined with most currently available incentives and
          your GM Extended Family&trade; Mastercard&reg; Earnings.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">
        How do I redeem my Earnings for a statement credit?
      </h2>
      <div class="answer">
        <p>
          GM Extended Family&trade; Mastercard&reg; Earnings can be redeemed for statement credits
          in any amount over $50. You can request a
          <a class="stat-text-link" data-dtm="redeeming earnings" href="/redeem"
            >statement redemption online</a
          >
          by following the steps. Or call the GM Extended Family&trade; Mastercard&reg; Services
          Redemption Center at 1-833-773-0988.
        </p>
        <p>
          The GM Cardmember Services Redemption Center will process the request,
          and a statement credit should be posted to your Marcus by Goldman
          Sachs account within 48 hours.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">Where can I learn more about vehicles online?</h2>
      <div class="answer">
        <p>
          You can search the great selection of cars, trucks and SUVs by
          visiting the
          <a
            class="stat-text-link"
            data-dtm="redeeming earnings"
            href="https://www.chevrolet.com/locate-inventory?evar25=gm_card_faq"
            target="_blank"
            title="open in a new tab"
            >Chevrolet</a
          >,
          <a
            class="stat-text-link"
            data-dtm="redeeming earnings"
            href="https://www.buick.com/locate-vehicle?evar25=gm_card_faq"
            target="_blank"
            title="open in a new tab"
            >Buick</a
          >,
          <a
            class="stat-text-link"
            data-dtm="redeeming earnings"
            href="https://www.gmc.com/view-inventory?evar25=gm_card_faq"
            target="_blank"
            title="open in a new tab"
            >GMC</a
          >
          and
          <a
            class="stat-text-link"
            data-dtm="redeeming earnings"
            href="https://www.cadillac.com/search-inventory?evar25=gm_card_faq"
            target="_blank"
            title="open in a new tab"
            >Cadillac</a
          >
          vehicle pages.
        </p>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header
      data-dtm="faq:gm extended family card account security"
      class="stat-expand-icon"
    >
      <mat-panel-title class="panel-title">
        <mat-icon class="plus">add</mat-icon>
        <mat-icon class="minus">remove</mat-icon>
        <span class="panel-title"
          >GM Extended Family&trade; Mastercard&reg; Account/Security</span
        >
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="text-left">
      <h2 class="question">
        If my Credit Card is lost or stolen, will I lose my accumulated Earnings?
      </h2>
      <div class="answer">
        <p>
          No. A new Account will be established for you and your accumulated
          Earnings balance will be transferred automatically. This transfer will
          appear on your next billing statement.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">
        Where can I find information about online security?
      </h2>
      <div class="answer">
        <p>
          GM and Marcus by Goldman Sachs take online security and identity theft
          seriously. Please visit the Marcus by Goldman Sachs
          <a
            class="stat-text-link"
            data-dtm="gm extended family card account security"
            href="https://www.marcus.com/us/en/banking-with-us/security-center"
            target="_blank"
            authenticated-link="false"
            >Security Center</a
          >
          for information about online security, "phishing" email scams and
          more.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">
        How can I manage my Account and pay my bill online?
      </h2>
      <div class="answer">
        You can manage your GM Extended Family&trade; Mastercard&reg; Account conveniently online
        through the
        <a
          class="stat-text-link"
          data-dtm="gm extended family card account security"
          href="https://www.marcus.com/us/en/login"
          target="_blank"
          >Marcus by Goldman Sachs</a
        >
        website.
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">
        Whom should I contact if I have questions or if I need help with my GM
        Extended Family&trade; Mastercard&reg; Account?
      </h2>
      <div class="answer">
        <p>
          Call 1-833-773-0988 for questions regarding your Earnings or Credit
          Card accounts. You can log in to your
          <a
            class="stat-text-link"
            data-dtm="gm extended family card account security"
            href="https://www.marcus.com/us/en/login"
            target="_blank"
            >Marcus by Goldman Sachs</a
          >
          account to access your financial information.
        </p>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header
      data-dtm="faq:my gm rewards"
      class="stat-expand-icon"
    >
      <mat-panel-title class="panel-title">
        <mat-icon class="plus">add</mat-icon>
        <mat-icon class="minus">remove</mat-icon>
        <span class="panel-title">My GM Rewards</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="text-left">
      <h2 class="question">
        What is My GM Rewards? Is this separate from the GM Extended Family&trade; Mastercard&reg;
        program?
      </h2>
      <div class="answer">
        <p>
          My GM Rewards<sup>3</sup> unlocks a world of appreciation that’s
          focused on you and your Chevrolet, Buick, GMC or Cadillac. Enjoy all
          the ways you can earn points, flexible options to redeem them, and
          surprise benefits along the way.
        </p>
        <p>
          You’ll earn points for eligible paid service and repairs<sup>4</sup>
          at participating GM dealerships, most OnStar<sup>&reg;</sup> and
          Connected Services plans,<sup>5</sup> plus SiriusXM<sup>&reg;</sup>
          subscriptions. You can then use these points on eligible purchases of
          accessories<sup>6</sup>, paid dealer service<sup>7</sup> and even toward the
          purchase or lease of your next new GM vehicle.<sup>8</sup>
        </p>
        <p>
          And even though you don’t have to enroll in My GM Rewards, as a GM
          Extended Family Cardmember, combining your Credit Card with My GM Rewards is
          a smart way to accelerate your Earnings. When you’re ready to purchase
          or lease a new GM vehicle,<sup>1</sup> redeem your Earnings for great
          savings.
        </p>
        <p>
          To learn more about My GM Rewards and all the ways you can earn, visit
          <a
            class="stat-text-link"
            data-dtm="my gm rewards"
            href="https://www.mygmrewards.com?evar25=gm_card_faq"
            target="_blank"
            >www.mygmrewards.com</a
          >
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">
        Can I use my GM Extended Family&trade; Mastercard&reg; Earnings with My GM Rewards points?
      </h2>
      <div class="answer">
        <p>
          Yes, you can combine your GM Extended Family&trade; Mastercard&reg; Earnings with your
          points from My GM Rewards toward the purchase or lease of a
          vehicle.<sup>8</sup> You can still combine your GM Extended Family&trade; Mastercard&reg;
          Earnings with your GM Employee and Supplier Discount for even greater
          savings.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">How do I join My GM Rewards?</h2>
      <div class="answer">
        <p style="margin-bottom: 0; font-weight: bold">Get In</p>
        <p style="margin-top: 0">
          Start by enrolling<sup>3</sup> — it’s quick, simple and there’s no
          cost to join! Earning is easy, and new ways to redeem let you use your
          points, your way.
        </p>
        <p style="margin-bottom: 0; font-weight: bold">Move Up</p>
        <p style="margin-top: 0.5em">
          Choose your path to more benefits and exclusive offers, with flexible
          ways to level up through three membership tiers.<sup>9</sup>
        </p>
        <p style="margin-bottom: 0; font-weight: bold">Drive On</p>
        <p style="margin-top: 0">
          Accelerate how you earn with the My GM Rewards&reg; Mastercard&reg;.<sup>10</sup> Get 7
          points for every $1<sup>11</sup> spent on GM purchases and 4 points
          for every $1<sup>12</sup> spent everywhere else.
        </p>
        <p>
          Learn more about My GM Rewards and sign up at
          <a
            class="stat-text-link"
            data-dtm="my gm rewards"
            href="https://www.mygmrewards.com?evar25=gm_card_faq"
            target="_blank"
            >www.mygmrewards.com</a
          >.
        </p>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>


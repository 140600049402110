export class Constants {
  public static readonly ZIP_LENGTH = 5;
  public static readonly ZIP_PATTERN = '([0-9]{5})';
  public static readonly DESKTOP_SMALL_SIZE = 768;
  public static readonly DESKTOP_XL_SIZE = 1600;
  //used when create mock vehicle field 
  public static readonly mock_vehicle_field = 'offers-accessory';
  //this is the programDetails mock end date field
  public static readonly mock_pgrm_detail_end_date = '2021/03/31';
  public static readonly api_service_email = 'TOM.FISHER@YOPMAIL.COM';
  //this is the variable used for header TestRequest of type content type
  public static readonly headerResponse = 'Content-Type';
  //this for repeated failed pathing message used in Inspector.spec 
  public static readonly error_path = 'Did not go through error path';
  //failed 400 error code 
  public static readonly failedErrorCode = 400;

}

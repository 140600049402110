import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-unauthenticated-container',
  templateUrl: './unauthenticated-container.component.html',
  styleUrls: ['./unauthenticated-container.component.scss']
})
export class UnauthenticatedContainerComponent
  implements OnInit, AfterViewInit {
  signInEl: HTMLElement;
  currentRoute: string;

  constructor(private router: Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        this.toggleSigninLink();
      }
    });
  }

  ngOnInit(): void {
    this.signInEl = document.getElementById('login_link');
  }

  ngAfterViewInit(): void {
    this.toggleSigninLink();
  }

  toggleSigninLink(): void {
    if (typeof this.signInEl === 'undefined') {
      return;
    }
    if (this.currentRoute === '/login') {
      this.signInEl.style.display = 'none';
    } else {
      this.signInEl.style.display = 'block';
    }
  }
}

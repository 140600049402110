import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { UnauthenticatedContainerComponent } from './components/unauthenticated-container/unauthenticated-container.component';
import { LoginComponent } from './components/login/login.component';
import { CookieGuard } from '../authenticated/services/guards/cookie.guard';
import { DealerAprComponent } from './components/dealer-apr/dealer-apr.component';
import { UnauthenticatedProgramRulesComponent } from './components/unauthenticated-program-rules/unauthenticated-program-rules.component';
import { UnauthenticatedBusinessProgramRulesComponent } from './components/unauthenticated-business-program-rules/unauthenticated-business-program-rules.component';
import { UnauthenticatedEfcProgramRulesComponent } from './components/unauthenticated-efc-program-rules/unauthenticated-efc-program-rules.component';

const routes: Routes = [
  {
    path: '',
    component: UnauthenticatedContainerComponent,
    children: [
      { path: '**', pathMatch: 'full', redirectTo: 'welcome' },
      { path: '', component: WelcomeComponent },
      { path: 'dealerapr', component: DealerAprComponent },
      {
        path: '',
        canActivate: [CookieGuard],
        children: [{ path: 'login', component: LoginComponent }]
      },
      {
        path: 'business/program-rules',
        component: UnauthenticatedBusinessProgramRulesComponent
      },
      {
        path: 'efc/program-rules',
        component: UnauthenticatedEfcProgramRulesComponent
      },
      {
        path: 'mygmrewardscard/program-rules',
        component: UnauthenticatedProgramRulesComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UnauthenticatedRoutingModule {}

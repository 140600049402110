<span class="navbar flex-column">
  <!-- Regular navbar -->
  <span class="d-none d-lg-flex justify-content-end align-items-center">
    <span> Your Earnings: {{totalEarnings | currency: 'USD'}} </span>
    <button
      data-dtm="global nav"
      [matMenuTriggerFor]="clickMobileMenu"
      #mainMenuTrigger="matMenuTrigger"
      class="drompdown-button stat-text-link"
    >
    <div class="d-flex flex-row">
      <div class="d-flex justify-content-center align-items-center flex-0">
        <span class="flex-0">{{userName}}</span>
          <span>
            <mat-icon inline>lock</mat-icon>
          </span>
        </div>
        <div
          class="arrow-icon"
          *ngIf="!mainMenuTrigger.menuOpen else showArrowUp"
        >
          <mat-icon inline>arrow_drop_down</mat-icon>
        </div>
        <ng-template #showArrowUp>
          <div class="arrow-icon">
            <mat-icon inline>arrow_drop_up</mat-icon>
          </div>
        </ng-template>
      </div>
    </button>
  </span>

  <span class="d-none d-lg-flex">
    <button
      class="stat-button-link"
      data-dtm="global nav"
      mat-button
      *ngFor="let item of menuItems"
      routerLink="/{{item | lowercase}}"
      routerLinkActive="active-navbar-item"
    >
      {{item}}
    </button>
  </span>

  <!-- Mobile navbar -->
  <button
  class="d-inline-block d-lg-none menu-btn-padding"
    mat-icon-button
    [matMenuTriggerFor]="clickMobileMenu"
    #MobileMenuTrigger="matMenuTrigger"
  >
    <span
      class="mobile-menu-icon d-flex flex-column justify-content-around align-items-center"
      data-dtm="global nav"
      *ngIf="!MobileMenuTrigger.menuOpen else showCross"
    >
      <mat-icon class="menu-icon">menu</mat-icon>
      <span>MENU</span>
    </span>
    <ng-template #showCross>
      <span
        class="mobile-menu-icon d-flex flex-column justify-content-around align-items-center"
        data-dtm="global nav"
      >
        <mat-icon class="mobile-icon">close</mat-icon>
        <span>CLOSE</span>
      </span>
    </ng-template>
  </button>
</span>

<!-- shared drop down -->
<span class="d-flex d-lg-none">
  <mat-menu #clickMobileMenu="matMenu" [overlapTrigger]="false">
    <span *ngFor="let card of rewardCards; index as i">
      <button
        mat-menu-item
        [ngClass]="card == currentRewardCard ? 'active-dropdown-item' : 'dropdown-item'"
        class="stat-text-link"
        data-dtm="global nav"
        (click)="switchCard(i)"
      >
        <span [innerHtml]="card.name | uppercase"></span>...{{card.last4CardNumber}}
      </button>
      <a
        target="_blank"
        mat-menu-item
        class="dropdown-item"
        href="{{rewardsDashboardLink}}"
      >
        GM Account
      </a>
      <span *ngIf="MobileMenuTrigger.menuOpen && card == currentRewardCard">
        <button
          mat-menu-item
          *ngFor="let item of menuItems"
          class="active-menu-section stat-button-link"
          data-dtm="global nav"
          routerLinkActive="active-menu-item"
          routerLink="/{{item | lowercase}}"
        >
          {{item}}
        </button>
      </span>
    </span>
    <button (click)="signOut(null)" data-dtm="global nav" mat-menu-item>
      SIGN OUT
    </button>
  </mat-menu>
</span>

<div class="d-flex flex-row justify-content-between align-items-center">
  <h1>ERROR HEADER TITLE</h1>
  <mat-icon
    class="d-flex align-content-center align-items-center mobile-icon"
    (click)="close()"
    style="font-size: 50px"
    >close
  </mat-icon>
</div>

<mat-dialog-content>
  <h3>SUB TITLE</h3>
  <p>ERROR: {{error}}</p>
</mat-dialog-content>

<button (click)="true" class="gb-primary-button green-button">Link</button>
<div
  (click)="close()"
  class="cancel-button d-flex flex-row align-content-center align-items-center"
>
  Cancel
</div>

<mat-accordion multi>
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header
      data-dtm="faq:about the gm card"
      class="stat-expand-icon"
    >
      <mat-panel-title class="panel-title">
        <mat-icon class="plus">add</mat-icon>
        <mat-icon class="minus">remove</mat-icon>
        <span class="panel-title">About the GM&trade; Mastercard&reg;</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="text-left">
      <h2 class="question">What is the GM&trade; Mastercard&reg;?</h2>
      <div class="answer">
        <p>
          The GM&trade; Mastercard&reg; rewards you with Earnings on everything you buy,
          redeemable toward the retail purchase or lease of an eligible, new
          vehicle<sup>1</sup> from Chevrolet, Buick, GMC or Cadillac. Use your
          GM&trade; Mastercard&reg; and receive 5% Earnings on all Credit Card purchases and
          accumulate up to $500 in Earnings each year. Earnings are valid for
          seven years from the end of the billing cycle in which they were
          earned and will occur month by month, to ensure seven full years of
          Earnings are maintained. You can also combine your Earnings with most
          current GM offers and incentives. However, Earnings may not be
          combined with the
          <a
            class="stat-text-link"
            data-dtm="about the gm card"
            href="https://www.gmfamilyfirst.com/about-the-discount/?evar25=gm_card_faq"
            target="_blank"
          >
            GM Employee Purchase Program</a
          >,
          <a
            class="stat-text-link"
            data-dtm="about the gm card"
            href="https://www.gmsupplierdiscount.com/?evar25=gm_card_faq"
            target="_blank"
            >GM Supplier Discount Program</a
          >, or any other GM&trade; Mastercard&reg; Product Earnings Program. Other exclusions may
          apply. GM reserves the right to change the Program Rules, regulations
          and rewards. You can check your Program Rules or call the phone number
          on the back of your Credit Card 1-833-773-0988.
        </p>
        <p>
          See the GM&trade; Mastercard&reg; Earnings
          <a
            class="stat-text-link"
            data-dtm="about the gm card"
            href="program-rules"
            >Program Rules</a
          >
          for terms, conditions and limitations.
        </p>
      </div>
      <div class="text-left">
        <h2 class="question">
          Can I use my GM&trade; Mastercard&reg; Earnings with the GM Employee or Supplier
          Discount?
        </h2>
        <div class="answer">
          <p>
            No, however, GM&trade; Mastercard&reg; Earnings can be combined with the GM College
            Program, GM Military Program, GM First Responder Program or GM
            Educator Program, if eligible.
          </p>
        </div>
      </div>
      <div class="text-left">
        <h2 class="question">Can I redeem my Earnings for cash?</h2>
        <div class="answer">
          <p>
            No, your Earnings may only be redeemed toward the purchase or lease
            of a new GM vehicle.<sup>1</sup>
          </p>
        </div>
      </div>
      <div class="text-left">
        <h2 class="question">What bank issues the GM&trade; Mastercard&reg;?</h2>
        <div class="answer">
          <p>
            <a
              href="https://www.marcus.com/us/en"
              class="stat-text-link"
              target="_blank"
              authenticatedCopy="false"
              data-dtm="about the gm card"
              >Goldman Sachs Bank USA</a
            >
            is the issuer of the GM&trade; Mastercard&reg;. GM is responsible for the operation
            and administration of the Earnings Program.
          </p>
        </div>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header
      data-dtm="faq:gm card earnings"
      class="stat-expand-icon"
    >
      <mat-panel-title class="panel-title">
        <mat-icon class="plus">add</mat-icon>
        <mat-icon class="minus">remove</mat-icon>
        <span class="panel-title">GM&trade; Mastercard&reg; Earnings</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="text-left">
      <h2 class="question">Can I accrue Earnings on Balance Transfers?</h2>
      <div class="answer">
        <p>
          Earnings can only be obtained on purchases. Earnings are not available
          on Balance Transfers, cash advances, convenience checks or Credit Card
          checks.
        </p>
      </div>
      <div class="text-left">
        <h2 class="question">
          Do I receive Earnings for purchases made by authorized users?
        </h2>
        <div class="answer">
          <p>
            Earnings are accumulated for all purchases made on Credit Cards issued
            under the same GM&trade; Mastercard&reg; Account Number, including authorized users.
          </p>
        </div>
      </div>
      <div class="text-left">
        <h2 class="question">Do my Earnings expire?</h2>
        <div class="answer">
          <p>
            Earnings are valid for seven years from the end of the billing cycle
            in which they were earned. Expiration after seven years will occur
            month by month, so seven full years of Earnings are maintained.
          </p>
        </div>
      </div>
      <div class="text-left">
        <h2 class="question">
          How much can I accumulate in Earnings each year?
        </h2>
        <div class="answer">
          <p>
            You may accumulate $500 in GM&trade; Mastercard&reg; Earnings each Account year. For
            further details, see
            <a
              class="stat-text-link"
              data-dtm="gm card earnings"
              href="/program-rules"
              >Program Rules</a
            >.
          </p>
        </div>
      </div>
      <div class="text-left">
        <h2 class="question">
          Is there a maximum on the Earnings I can redeem?
        </h2>
        <div class="answer">
          <p>
            No. Cardmembers who carry the GM&trade; Mastercard&reg; are not subject to Redemption
            Allowances but do have an annual Earnings limit of $500.
          </p>
        </div>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header
      data-dtm="faq:redeeming earnings"
      class="stat-expand-icon"
    >
      <mat-panel-title class="panel-title">
        <mat-icon class="plus">add</mat-icon>
        <mat-icon class="minus">remove</mat-icon>
        <span class="panel-title">Redeeming Earnings</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="text-left">
      <h2 class="question">
        How do I redeem my GM&trade; Mastercard&reg; Earnings toward a new Chevrolet, Buick, GMC
        or Cadillac vehicle?<sup>1</sup>
      </h2>
      <div class="answer">
        <p>
          To redeem your Earnings toward the retail purchase or lease of an
          eligible, new GM vehicle,<sup>1</sup> first check your available
          Earnings then:
        </p>
        <ol>
          <li>
            Visit the Chevrolet, Buick, GMC or Cadillac dealership of your
            choice.
          </li>
          <li>
            Agree on your best price, taking advantage of most current available
            offers and incentives.
          </li>
          <li>
            Identify yourself as a GM Cardmember and let the dealer
            representative know you would like to redeem your Earnings.
          </li>
        </ol>
        <p>
          If you would like to apply the Earnings to the purchase or lease of a
          new GM vehicle<sup>1</sup> by a household member of the Authorizing
          Officer, the Authorizing Officer will need to be present at the time
          of purchase or lease of the vehicle. The household member must reside
          at the Authorizing Officer's address and must not have, at the time of
          transfer, a GM Rewards Card product that provides Earnings or other
          rewards toward the purchase or lease of a new GM vehicle.<sup>1</sup>
          The Authorizing Officer and household member may be required to
          provide proof of address.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">
        Where can I learn more about the vehicles online?
      </h2>
      <div class="answer">
        <p>
          You can search the great selection of cars, trucks and SUVs by
          visiting the
          <a
            class="stat-text-link"
            data-dtm="redeeming earnings"
            href="https://www.chevrolet.com/locate-inventory?evar25=gm_card_faq"
            target="_blank"
            title="open in a new tab"
            >Chevrolet</a
          >,
          <a
            class="stat-text-link"
            data-dtm="redeeming earnings"
            href="https://www.buick.com/locate-vehicle?evar25=gm_card_faq"
            target="_blank"
            title="open in a new tab"
            >Buick</a
          >,
          <a
            class="stat-text-link"
            data-dtm="redeeming earnings"
            href="https://www.gmc.com/view-inventory?evar25=gm_card_faq"
            target="_blank"
            title="open in a new tab"
            >GMC</a
          >
          and
          <a
            class="stat-text-link"
            data-dtm="redeeming earnings"
            href="https://www.cadillac.com/search-inventory?evar25=gm_card_faq"
            target="_blank"
            title="open in a new tab"
            >Cadillac</a
          >
          vehicle pages.
        </p>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header
      data-dtm="faq:gm card account security"
      class="stat-expand-icon"
    >
      <mat-panel-title class="panel-title">
        <mat-icon class="plus">add</mat-icon>
        <mat-icon class="minus">remove</mat-icon>
        <span class="panel-title">GM&trade; Mastercard&reg; Account/Security</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="text-left">
      <h2 class="question">
        If my Credit Card is lost or stolen, will I lose my accumulated Earnings?
      </h2>
      <div class="answer">
        <p>
          No. A new Account will be established for you and your accumulated
          Earnings balance will be transferred automatically. This transfer will
          appear on your next billing statement.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">
        Where can I find information about online security?
      </h2>
      <div class="answer">
        <p>
          GM and Marcus by Goldman Sachs take online security and identity theft
          seriously. Please visit Marcus by Goldman Sachs
          <a
            class="stat-text-link"
            data-dtm="gm card account security"
            href="https://www.marcus.com/us/en/banking-with-us/security-center"
            target="_blank"
            authenticated-link="false"
            >Security Center</a
          >
          for information about online security, "phishing" email scams and
          more.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">
        How can I manage my Account and pay my bill online?
      </h2>
      <div class="answer">
        You can manage your GM&trade; Mastercard&reg; Account conveniently online through the
        <a
          class="stat-text-link"
          data-dtm="gm card account security"
          href="https://www.marcus.com/us/en/login"
          target="_blank"
          >Marcus by Goldman Sachs</a
        >
        site. website. To manage your Earnings Account, simply visit the
        <a
          class="stat-text-link"
          data-dtm="gm card account security"
          href="/summary"
          >Summary page</a
        >.
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">
        Whom should I contact if I have questions or if I need help with my GM&trade;
        Mastercard&reg; Account?
      </h2>
      <div class="answer">
        <p>
          Call 1-833-773-0988 for questions regarding your Earnings or Credit
          Card accounts. You can go to your
          <a
            class="stat-text-link"
            data-dtm="gm card account security"
            href="https://www.marcus.com/us/en/login"
            target="_blank"
          >
            Marcus by Goldman Sachs</a
          >
          account to access your financial information.
        </p>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header
      data-dtm="faq:my gm rewards"
      class="stat-expand-icon"
    >
      <mat-panel-title class="panel-title">
        <mat-icon class="plus">add</mat-icon>
        <mat-icon class="minus">remove</mat-icon>
        <span class="panel-title">My GM Rewards</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="text-left">
      <h2 class="question">
        What is My GM Rewards? Is this separate from the GM&trade; Mastercard&reg;?
      </h2>
      <div class="answer">
        <p>
          My GM Rewards<sup>2</sup> unlocks a world of appreciation that’s
          focused on you and your Chevrolet, Buick, GMC or Cadillac. Enjoy all
          the ways you can earn points, flexible options to redeem them, and
          surprise benefits along the way.
        </p>
        <p>
          You’ll earn points for eligible paid service and repairs<sup>3</sup>
          at participating GM dealerships, most OnStar<sup>&reg;</sup> and
          Connected Services plans,<sup>4</sup> plus SiriusXM<sup>&reg;</sup>
          subscriptions. You can then use these points on eligible purchases of
          accessories<sup>5</sup>, paid dealer service<sup>6</sup> and even toward the
          purchase or lease of your next new GM vehicle.<sup>7</sup>
        </p>
        <p>
          And even though you don’t have to enroll in My GM Rewards, as a GM
          Cardmember, combining your Credit Card with My GM Rewards is a smart way to
          accelerate your Earnings. Because when you’re ready to purchase or
          lease a new GM vehicle,<sup>1</sup> you can combine your Earnings with
          your points from My GM Rewards for even greater value.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">
        Can I use my GM&trade; Mastercard&reg; Earnings with My GM Rewards points?
      </h2>
      <div class="answer">
        <p>
          On eligible new GM purchases and leases, you can combine your GM&trade; Mastercard&reg;
          Earnings with points from My GM Rewards points for even greater value.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">How do I join My GM Rewards?</h2>
      <div class="answer">
        <p style="margin-bottom: 0; font-weight: bold">Get In</p>
        <p style="margin-top: 0">
          Start by enrolling<sup>2</sup> — it’s quick, simple and there’s no
          cost to join! Earning is easy, and new ways to redeem let you use your
          points, your way.
        </p>
        <p style="margin-bottom: 0; font-weight: bold">Move Up</p>
        <p style="margin-top: 0.5em">
          Choose your path to more benefits and exclusive offers, with flexible
          ways to level up through three membership tiers.<sup>8</sup>
        </p>
        <p style="margin-bottom: 0; font-weight: bold">Drive On</p>
        <p style="margin-top: 0">
          Accelerate how you earn with the My GM Rewards&reg; Mastercard&reg;.<sup>9</sup> Get 7
          points for every $1<sup>10</sup> spent on GM purchases and 4 points for
          every $1<sup>11</sup> spent everywhere else.
        </p>
        <p>
          Learn more about My GM Rewards and sign up at
          <a
            class="stat-text-link"
            data-dtm="my gm rewards"
            href="https://www.mygmrewards.com?evar25=gm_card_faq"
            target="_blank"
            >www.mygmrewards.com</a
          >.
        </p>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>

import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  BestApr,
  IncentiveDetails,
  ModelOffers,
  Program,
  ProgramDetails,
  Region,
  VehicleOffer
} from './regionalOffers.model';
import { takeUntil } from 'rxjs/operators';
import { ContentService } from '../../../_shared/services/content.service';
import { EarningsResponse } from '../../../_shared/models/earning-models/earnings-response';
import { SummaryService } from '../../services/summary.service';
import { UserService } from '../../services/user.service';
import { ApiService } from '../../../_shared/services/api.service';
import { UtilityService } from '../../../_shared/services/utility.service';
import { Constants } from '../../../app.constants';
import { AccountResponse } from 'src/app/_shared/models/account-models/account-response';
import { CardService } from '../../services/card.service';
import { Card } from 'src/app/_shared/models/card-models/card';
import { CardOffer } from 'src/app/_shared/models/offers-models/card-offer';
import { VehicleContent } from 'src/app/_shared/models/offers-models/vehicle-content';
import { PageInfo } from 'src/app/_shared/models/adobe/page-info';
import { AdobeDtmService } from 'src/app/_shared/services/adobe-dtm.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AemService } from '../../../_shared/services/aem-content.service';
import { DomSanitizer } from '@angular/platform-browser';

enum vehicleOrder {
  CHEVROLET = 1,
  BUICK,
  GMC,
  CADILLAC
}

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit, OnDestroy {
  componentDestroyed = new Subject();
  constants = Constants;
  regionalOffersForm: UntypedFormGroup;
  showCardOffers = true;
  showVehicleOffers = false;
  showVehicleDropDowns = false;
  hasCardOffers: boolean;
  activeTab: 'active-tab';
  activeTabMobile: 'active-tab-mobile';

  user: AccountResponse;
  regionData: Region;
  selectedYear = '';
  selectedMake = '';
  selectedModel = '';
  vehicleOffers: VehicleOffer[] = [];
  cardMemberOffers: CardOffer[] = [];

  cardTabElement: HTMLElement;
  vehicleTabElement: HTMLElement;

  cardTabElementMobile: HTMLElement;
  vehicleTabElementMobile: HTMLElement;

  cardOffers: CardOffer[];
  offerVehicleTemplates: VehicleContent[];

  userSubscription: Subscription;
  currentRewardCard: Card;

  userEarnings: EarningsResponse;
  excludedProgramIds: string[] = [
    '18-40AN',
    '18-40AW',
    '18-40AAE-0',
    '18-40ADN',
    '18-40AFB',
    '18-40AFJ',
    '18-40CBW',
    '18-40CB',
    '18-40AFS',
    '18-40AFN',
    '18-34AT',
    '18-40GJ'
  ];

  digitalData: PageInfo;

  contactUsLink: string;

  @ViewChildren('offerPanel') panels: QueryList<ElementRef>;

  constructor(
    private readonly api: ApiService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly contentService: ContentService,
    private readonly summaryService: SummaryService,
    private readonly userService: UserService,
    private readonly cardService: CardService,
    private readonly adobeDTMService: AdobeDtmService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private aem: AemService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.contactUsLink = this.contentService.getContactUsLink();

    this.initializeContentPartials();
    this.initializeCardSubscription();

    this.cardTabElement = document.getElementById('card-tab');
    this.vehicleTabElement = document.getElementById('vehicle-tab');

    this.cardTabElementMobile = document.getElementById('card-tab-mobile');
    this.vehicleTabElementMobile =
      document.getElementById('vehicle-tab-mobile');

    this.userSubscription = this.userService.user$.subscribe(
      (user: AccountResponse) => {
        this.user = user;
        if (this.summaryService.getCardMemberRedemptionInfo()) {
          this.userEarnings = this.summaryService.getCardMemberRedemptionInfo();
          this.initializeCardMemberOffers();
        } else {
          if (!user) {
            return;
          }
          this.api
            .getEarningsStatement(user?.accountDetails.trackID)
            .subscribe(data => {
              this.summaryService.setCardMemberRedemptionInfo(data);
              this.userEarnings = data;
              this.initializeCardMemberOffers();
            });
        }
        this.initializeRegionalOffersForm();
      }
    );

    // Add padding for mobile offers tab menu
    const mobileContainer = document.querySelector(
      'app-footer > div.mobile-container'
    );
    (mobileContainer as HTMLElement).style.paddingBottom = '80px';

    let redeemGoal = 'goal';
    if (this.currentRewardCard.analyticsType === 'platinum') {
      redeemGoal = 'nogoal';
    }
    let loginType = this.currentRewardCard.analyticsLoginType;
    if (this.currentRewardCard.analyticsType === 'platinum') {
      loginType = '';
    }
    this.digitalData = {
      pageInfo: {
        pageName:
          'offers-card-member-only-offers | logged | ' +
          this.currentRewardCard.analyticsType,
        pageType: 'offers',
        pageSubType: '',
        cardType: this.currentRewardCard.analyticsType,
        visitorStatus: 'logged',
        cardTypeOwned: this.currentRewardCard.analyticsTypeOwned,
        offerID: '',
        loginType: loginType,
        pageGoal: redeemGoal
      }
    };
    // We don't need to send the data here, it will be sent with toggleOffers()
  }

  initializeCardSubscription() {
    this.cardService
      .getCurrentCard()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(card => {
        this.currentRewardCard = card;
        this.titleService.setTitle(
          `Current Cardmember & Vehicle Offers | ${this.currentRewardCard.name}`
        );
      });
  }

  initializeContentPartials() {
    this.aem
      .currentContent
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(template => {
        const offersPartialsJson = JSON.parse(template?.offersPartialsjson);
        this.cardOffers = offersPartialsJson;
      });

    this.aem
      .currentContent
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(templateVehicle => {
        const vehiclesJson = JSON.parse(templateVehicle?.vehicleOfferTemplateJson);
        this.offerVehicleTemplates = vehiclesJson;
      });
  }

  toggleCardOffers(): void {
    this.vehicleTabElement.classList.remove(this.activeTab);
    this.cardTabElement.classList.add(this.activeTab);
    this.vehicleTabElementMobile.classList.remove(this.activeTabMobile);
    this.cardTabElementMobile.classList.add(this.activeTabMobile);
    this.showVehicleOffers = false;
    this.showCardOffers = true;
    let redeemGoal = 'goal';
    let loginType = this.currentRewardCard.analyticsLoginType;
    if (this.currentRewardCard.analyticsType === 'platinum') {
      redeemGoal = 'nogoal';
      loginType = '';
    }
    this.digitalData = {
      pageInfo: {
        pageName:
          'offers-card-member-only-offers | logged | ' +
          this.currentRewardCard.analyticsType,
        pageType: 'offers',
        pageSubType: '',
        cardType: this.currentRewardCard.analyticsType,
        visitorStatus: 'logged',
        cardTypeOwned: this.currentRewardCard.analyticsTypeOwned,
        offerID: '',
        loginType: loginType,
        pageGoal: redeemGoal
      }
    };
    this.sendTrackingInfo();
    this.adobeDTMService.sendAdobeLaunchData();
  }

  toggleVehicleOffers(): void {
    this.cardTabElement.classList.remove(this.activeTab);
    this.vehicleTabElement.classList.add(this.activeTab);
    this.cardTabElementMobile.classList.remove(this.activeTabMobile);
    this.vehicleTabElementMobile.classList.add(this.activeTabMobile);
    this.showCardOffers = false;
    this.showVehicleOffers = true;
    let redeemGoal = 'goal';
    let loginType = this.currentRewardCard.analyticsLoginType;
    if (this.currentRewardCard.analyticsType === 'platinum') {
      redeemGoal = 'nogoal';
      loginType = '';
    }
    this.digitalData = {
      pageInfo: {
        pageName:
          'offers-current-vehicles-offers | logged | ' +
          this.currentRewardCard.analyticsType,
        pageType: 'offers',
        pageSubType: '',
        cardType: this.currentRewardCard.analyticsType,
        visitorStatus: 'logged',
        cardTypeOwned: this.currentRewardCard.analyticsTypeOwned,
        offerID: '',
        loginType: loginType,
        pageGoal: redeemGoal
      }
    };
    this.sendTrackingInfo();
    this.adobeDTMService.sendAdobeLaunchData();
  }

  initializeRegionalOffersForm(): void {
    this.regionalOffersForm = this.formBuilder.group({
      zipCode: [
        '',
        [
          Validators.required,
          Validators.maxLength(Constants.ZIP_LENGTH),
          Validators.pattern(Constants.ZIP_PATTERN)
        ]
      ]
    });
  }

  initializeCardMemberOffers(): void {
    const currentDate: Date = new Date();
    this.aem
      .currentContent
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(offersData => {
        const data = JSON.parse(offersData?.offersJson)

        // Check for offers with showWithoutUserOfferId
        for (const offerId in data?.offers) {
          if (!Object.prototype.hasOwnProperty.call(data?.offers, offerId)) {
            continue;
          }
          const offer: CardOffer = data.offers[offerId];
          if (!offer.showWithoutUserOfferId) {
            continue;
          }
          const startDate = new Date(offer.startDate);
          const expirationDate = new Date(offer.expirationDate);
          if (currentDate >= startDate && currentDate <= expirationDate) {
            this.cardMemberOffers.push(offer);
          }
        }

        // Display offers associated with user
        this.userEarnings.offers.forEach(offer => {
          const currentOffer = data.offers[offer.offerID];
          if (typeof data.offers[offer.offerID] !== 'undefined') {
            const startDate = new Date(currentOffer.startDate);
            const expirationDate = new Date(currentOffer.expirationDate);
            if (currentDate >= startDate && currentDate <= expirationDate) {
              this.cardMemberOffers.push(currentOffer);
            }
          }
        });

        this.cardMemberOffers?.length
          ? (this.hasCardOffers = true)
          : (this.hasCardOffers = false);

        this.cardMemberOffersRouting();
      });
  }

  cardMemberOffersRouting(): void {

    let fragment = this.route.snapshot.fragment;
    if (typeof fragment === 'string') {
      fragment = fragment.split('?evar')[0];
      if (fragment.indexOf('vehicle-offers') !== -1) {
        this.toggleVehicleOffers();
      } else if (fragment) {
        this.toggleCardOffers();
        setTimeout(() => {
          const target = this.panels.find(
            panel => panel.nativeElement.id === 'offer-' + fragment
          );
          if (target) {
            target.nativeElement.scrollIntoView();
          }
        }, 500);
      }
    }
  }

  submitZipCode(): void {
    this.sendTrackingInfo();

    this.api
      .getRegionalOffers(this.regionalOffersForm.controls.zipCode.value)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((data: Region) => {
        this.regionData = data;
        this.vehicleOffers = this.generateOffers(this.regionData);
        this.selectedYear = this.getDefaultYear(this.regionData.incentives);
        this.resetFilters('year');
        this.showVehicleDropDowns = true;
        document.getElementById(
          'dynamic_offer_disclaimer'
        ).parentElement.style.display = 'block';
        const endDate = Object.values(data.programDetails)[0].endDate;
        document.getElementById('dynamic_offer_enddate').innerHTML = endDate;
      });
  }

  getDefaultYear(incentiveDetails: IncentiveDetails): string {
    return Object.keys(incentiveDetails || {}).sort(
      UtilityService.reverseSort
    )[0];
  }

  resetFilters(type: string): void {
    if (type === 'year') {
      this.selectedModel = '';
      this.selectedMake = '';
    } else if (type === 'make') {
      this.selectedModel = '';
    }
  }

  generateOffers(regionData: Region): VehicleOffer[] {
    const vehicleOffers: VehicleOffer[] = [];
    const incentives = regionData?.incentives;
    const programDetails = regionData?.programDetails;

    Object.keys(incentives || {}).forEach((year: string) => {
      const yearData = incentives[year];
      Object.keys(yearData || {}).forEach((make: string) => {
        const makeData = yearData[make];
        Object.keys(makeData || {}).forEach((model: string) => {
          const modelOffers = makeData[model];
          const vehicle = this.combineOffers(
            year,
            make,
            model,
            modelOffers,
            programDetails
          );

          if (vehicle) {
            if (vehicle.model.toLowerCase() === 'bolt euv' && vehicle.year === '2023') {

            } else {
              vehicleOffers.push(vehicle);
            }
          }
        });
      });
    });

    return vehicleOffers.sort((a, b) =>
      vehicleOrder[a.make] > vehicleOrder[b.make]
        ? 1
        : vehicleOrder[a.make] === vehicleOrder[b.make]
          ? 0
          : -1
    );
  }

  combineOffers(
    year: string,
    make: string,
    model: string,
    modelOffers: ModelOffers,
    programDetails: ProgramDetails
  ): VehicleOffer {
    const vehicle: VehicleOffer = {
      year,
      make,
      model,
      cash: 0.0,
      bonusCash: 0.0,
      consumerCash: 0.0,
      otherCashValues: 0.0,
      minimumDealCashValue: parseInt(
        modelOffers.cashValues?.minimumDealCashValue || '0',
        10
      ),
      bonusCashApr: parseFloat(
        modelOffers.financeValues?.minimumDealCashValue || '0.0'
      )
    };

    // Handles Cash Offers
    Object.keys(modelOffers.cashValues?.details || {}).forEach(
      (programName: string) => {
        const program: Program = programDetails[programName] || {};
        this.setVehicleAmount(program, modelOffers, programName, vehicle);
      }
    );

    // Handles APR Offers
    Object.keys(modelOffers.financeValues?.details || {}).forEach(
      (programName: string) => {
        const aprs = modelOffers.financeValues.details[programName];
        vehicle.bestApr = this.getBestApr(aprs);
      }
    );

    if (vehicle.cash > 0 || Object.keys(vehicle.bestApr || {}).length > 0) {
      return vehicle;
    }
    return null;
  }

  getBestApr(aprs: { [months: string]: string }): BestApr {
    // Setting unrealistic range to catch best deal
    let minAprValue = Number.POSITIVE_INFINITY;
    let maxMonths = Number.NEGATIVE_INFINITY;
    const bestAprValues: any = {};

    Object.keys(aprs || {})
      .filter((x: string) => x.toLowerCase().startsWith('apr')) // we only need APRs and not Cash
      .forEach((x: string) => {
        const months = parseInt(x.toLowerCase().replace('apr', ''), 10);
        const aprValue = parseFloat(aprs[x]);
        if (aprValue < minAprValue && months > maxMonths) {
          minAprValue = aprValue;
          maxMonths = months;
          bestAprValues.apr = aprValue;
          bestAprValues.months = months;
        }
      });
    return bestAprValues;
  }

  sendTrackingInfo() {
    let updatedDigitalData = this.digitalData;

    updatedDigitalData = this.adobeDTMService.setupWindowPageInfoData(
      updatedDigitalData,
      null,
      'Current Offers'
    );
    this.adobeDTMService.setWindowObjectData(updatedDigitalData);
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
    this.userSubscription.unsubscribe();
  }

  private setVehicleAmount(
    program: Program,
    modelOffers: ModelOffers,
    programName: string,
    vehicle: VehicleOffer
  ) {
    if (this.excludedProgramIds.indexOf(program.id) < 0) {
      const amount = parseInt(
        modelOffers?.cashValues?.details?.[programName].Cash || '0',
        10
      );
      switch (program.programType) {
        case 'Consumer Cash':
          vehicle.consumerCash += amount;
          break;
        case 'Bonus Cash':
          vehicle.bonusCash += amount;
          break;
        case 'Dealer Cash':
          vehicle.consumerCash += amount;
          break;
        case 'Employee Vehicle Allowance':
          vehicle.otherCashValues += amount;
          break;
      }
      vehicle.cash += amount;
    }
  }
}

import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VehicleModalComponent } from './vehicle-modal/vehicle-modal.component';
import { Brands } from '../../../../assets/static/vehicle/brand';
import { VehiclesService } from './vehicles.service';
import { CardService } from '../../services/card.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SummaryService } from '../../services/summary.service';
import { EarningsResponse } from '../../../_shared/models/earning-models/earnings-response';
import { ContentService } from '../../../_shared/services/content.service';
import { ApiService } from '../../../_shared/services/api.service';
import { GalleryVehicle } from 'src/app/_shared/models/vehicle-models/gallery-vehicle';
import { AdobeDtmService } from 'src/app/_shared/services/adobe-dtm.service';
import { PageInfo } from 'src/app/_shared/models/adobe/page-info';
import { Title } from '@angular/platform-browser';
import { Card } from 'src/app/_shared/models/card-models/card';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss']
})
export class VehiclesComponent implements OnInit, OnDestroy, AfterViewInit {
  componentDestroyed = new Subject();
  cardType: Card;
  modelSelection = {
    All: 'All',
    Car: 'Car',
    'SUV/Crossover': 'SUV',
    Truck: 'Truck'
  };
  expand;
  vehicleData = new Map<string, GalleryVehicle[]>();
  filteredVehicleData;
  brands = Brands;
  showPrev;
  totalEarnings;
  cardTypesWithRedemptionAllowance = new Set([
    'flex',
    'worldflex',
    'worldplat',
    'platinum',
    'copper',
    'copperplatinum'
  ]);
  showRedemptionAllowance = false;
  logos = [
    'assets/img/vehicle_logos/chevrolet.png',
    'assets/img/vehicle_logos/buick.png',
    'assets/img/vehicle_logos/gmc_logo.png',
    'assets/img/vehicle_logos/cadillac.png'
  ];

  contactUsLink: string;

  constructor(
    private readonly dialog: MatDialog,
    private readonly summaryService: SummaryService,
    private readonly cardService: CardService,
    private readonly vehicleService: VehiclesService,
    private readonly contentService: ContentService,
    private readonly adobeDTMService: AdobeDtmService,
    private readonly apiService: ApiService,
    private readonly titleService: Title
  ) {
    this.showPrev = [true, true, true, true];
  }

  ngOnInit(): void {
    this.contactUsLink =
      this.contentService.getContactUsLink() + '?evar25=gm_card_get_answers';
    this.summaryService.cardRedemption$.subscribe(
      (earnings: EarningsResponse) => {
        this.totalEarnings = earnings?.earnings?.availableEarnings?.total
          ? earnings?.earnings?.availableEarnings?.total
          : 0;
      }
    );
    this.cardService
      .getCurrentCard()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(card => {
        this.cardType = card;
        this.titleService.setTitle(`Vehicles | ${this.cardType.name}`);
        if (this.cardTypesWithRedemptionAllowance.has(this.cardType.path)) {
          this.showRedemptionAllowance = true;
        }
      });
    // Build vehicle data for the gallery
    this.apiService
      .getVehicleGallery()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(vehicleGallery => {
        this.vehicleData = new Map(Object.entries(vehicleGallery));
        this.filteredVehicleData = this.vehicleService.orderVehicleData([
          ...this.vehicleData.entries()
        ]);
      });
  }

  ngAfterViewInit() {
    this.sendTrackingInfo(true);
  }

  togglePreview(brand) {
    switch (brand) {
      case 'Chevrolet':
        this.showPrev[0] = !this.showPrev[0];
        break;
      case 'Buick':
        this.showPrev[1] = !this.showPrev[1];
        break;
      case 'GMC':
        this.showPrev[2] = !this.showPrev[2];
        break;
      case 'Cadillac':
        this.showPrev[3] = !this.showPrev[3];
        break;
    }
  }

  sendTrackingInfo(sendDirectCall: boolean) {
    let vehicleGoal = 'goal';
    if (
      this.cardType.analyticsType === 'platinum' ||
      this.cardType.analyticsType === 'bluegold'
    ) {
      vehicleGoal = 'nogoal';
    }

    let analyticsLoginType = this.cardType.analyticsLoginType;
    if (analyticsLoginType === 'platinum') {
      analyticsLoginType = '';
    }

    let digitalData: PageInfo = {
      pageInfo: {
        pageName: 'eligible.vehicles | logged | ' + this.cardType.analyticsType,
        pageType: 'eligible vehicles',
        pageSubType: '',
        cardType: this.cardType.analyticsType,
        visitorStatus: 'logged',
        cardTypeOwned: this.cardType.analyticsTypeOwned,
        offerID: '',
        loginType: analyticsLoginType,
        pageGoal: vehicleGoal
      }
    };

    digitalData = this.adobeDTMService.setupWindowPageInfoData(
      digitalData,
      '',
      'Vehicles'
    );
    this.adobeDTMService.setWindowObjectData(digitalData);
    this.adobeDTMService.sendAdobeLaunchData();
  }

  openVehicleDetailModal(selectedVehicle) {
    this.sendTrackingInfo(false);
    const currEarning = this.totalEarnings;
    const modalData = {
      image: selectedVehicle.image03,
      brand: selectedVehicle.make,
      vehicleDesc: `${selectedVehicle.year} ${selectedVehicle.make} ${selectedVehicle.model}`,
      earning: currEarning,
      redemptionAllowance: selectedVehicle.redemptionAllowance,
      showRedemptionAllowance: this.showRedemptionAllowance,
      urls: `https://${selectedVehicle.make}.com`
    };
    this.dialog.open(VehicleModalComponent, {
      data: modalData
    });
  }

  onSelectChanged(type) {
    this.showPrev = [true, true, true, true];
    this.filteredVehicleData = this.vehicleService.orderVehicleData([
      ...this.vehicleData.entries()
    ]);
    if (type !== 'All') {
      this.filteredVehicleData = this.vehicleService.filterVehiclesByType(
        type,
        this.filteredVehicleData
      );
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AccountDetails } from '../../_shared/models/account-models/account-details';
import { AccountResponse } from '../../_shared/models/account-models/account-response';

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {
  private readonly selectedAccount = new BehaviorSubject<AccountDetails>(null);
  private userSubject: BehaviorSubject<AccountResponse> =
    new BehaviorSubject<AccountResponse>(null);
  private user: AccountResponse;
  private userId: string;

  get user$(): Observable<AccountResponse> {
    return this.userSubject.asObservable();
  }

  setUserId(userId: string): void {
    this.userId = userId;
  }

  getUserId(): Observable<boolean> {
    return of(!!this.userId);
  }

  getAccount(): Observable<AccountDetails> {
    return this.selectedAccount.asObservable();
  }

  getUser(): AccountResponse {
    return this.user;
  }

  setAccount(account: AccountDetails): void {
    this.selectedAccount.next(account);
  }

  setUser(user: AccountResponse): void {
    this.user = user;
    this.userSubject.next(user);
    this.selectedAccount.next(user.accountDetails);
  }

  ngOnDestroy(): void {
    this.selectedAccount.unsubscribe();
  }
}

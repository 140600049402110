import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthenticatedRoutingModule } from './authenticated-routing.module';
import { SummaryComponent } from './components/summary/summary.component';
import { OffersComponent } from './components/offers/offers.component';
import { RedeemComponent } from './components/redeem/redeem.component';
import { AuthenticatedContainerComponent } from './components/authenticated-container/authenticated-container.component';
import { SharedModule } from '../_shared/shared.module';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { MaterialModule } from '../_shared/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VehicleModalComponent } from './components/vehicles/vehicle-modal/vehicle-modal.component';
import { EarningsComponent } from './components/summary/earnings/earnings.component';
import { BenefitsComponent } from './components/summary/benefits/benefits.component';
import { EarningsCalculatorComponent } from './components/summary/earnings-calculator/earnings-calculator.component';
import { ManageCardComponent } from './components/summary/manage-card/manage-card.component';
import { RedemptionComponent } from './components/summary/redemption/redemption.component';
import { QuickLinksComponent } from './components/quick-links/quick-links.component';
import { AuthenticatedFooterComponent } from './components/authenticated-footer/authenticated-footer.component';
import { VehiclesComponent } from './components/vehicles/vehicles.component';
import { RedeemPrintComponent } from './components/redeem/redeem-print/redeem-print.component';
import { ProgramRulesContainerComponent } from './components/program-rules-container/program-rules-container.component';
import { EfcProgramRulesComponent } from './components/efc-program-rules/efc-program-rules.component';
import { BusinessProgramRulesComponent } from './components/business-program-rules/business-program-rules.component';
import { CopperplatinumProgramRulesComponent } from './components/copperplatinum-program-rules/copperplatinum-program-rules.component';
import { BluegoldProgramRulesComponent } from './components/bluegold-program-rules/bluegold-program-rules.component';
import { RedeemAmountComponent } from './components/statement-credit/redeem-amount/redeem-amount.component';
import { RedeemCancelComponent } from './components/statement-credit/redeem-cancel/redeem-cancel.component';
import { RedeemConfirmComponent } from './components/statement-credit/redeem-confirm/redeem-confirm.component';
import { OffersBannerComponent } from './components/summary/offers-banner/offers-banner.component';
import { RedemptionHistoryComponent } from './components/redemption-history/redemption-history.component';
import { RedeemReceiptComponent } from './components/statement-credit/redeem-receipt/redeem-receipt.component';
import { VehiclePanelListComponent } from './components/offers/vehicle-panel-list/vehicle-panel-list.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { FaqsBluegoldComponent } from './components/faqs/faqs-bluegold/faqs-bluegold.component';
import { FaqsBpbcComponent } from './components/faqs/faqs-bpbc/faqs-bpbc.component';
import { FaqsCopperplatinumComponent } from './components/faqs/faqs-copperplatinum/faqs-copperplatinum.component';
import { FaqsEfcComponent } from './components/faqs/faqs-efc/faqs-efc.component';
import { LinkAccountComponent } from './components/summary/link-account/link-account.component';
import { NgxPrintModule } from 'ngx-print';

@NgModule({
    declarations: [
        SummaryComponent,
        OffersComponent,
        RedeemComponent,
        AuthenticatedContainerComponent,
        NavMenuComponent,
        VehiclesComponent,
        VehicleModalComponent,
        EarningsComponent,
        BenefitsComponent,
        EarningsCalculatorComponent,
        ManageCardComponent,
        RedemptionComponent,
        QuickLinksComponent,
        RedeemPrintComponent,
        AuthenticatedFooterComponent,
        ProgramRulesContainerComponent,
        EfcProgramRulesComponent,
        BusinessProgramRulesComponent,
        CopperplatinumProgramRulesComponent,
        BluegoldProgramRulesComponent,
        RedeemAmountComponent,
        RedeemCancelComponent,
        RedeemConfirmComponent,
        OffersBannerComponent,
        RedemptionHistoryComponent,
        RedeemReceiptComponent,
        VehiclePanelListComponent,
        FaqsComponent,
        FaqsBluegoldComponent,
        FaqsBpbcComponent,
        FaqsCopperplatinumComponent,
        FaqsEfcComponent,
        LinkAccountComponent
    ],
    imports: [
        CommonModule,
        AuthenticatedRoutingModule,
        SharedModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        NgxPrintModule
    ],
    providers: [VehicleModalComponent]
})
export class AuthenticatedModule {}

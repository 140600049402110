// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  myAccountPath: 'https://experience.gm.com/myaccount/',
  imagePath: '../assets/img',
  goldmanSachsPath: 'https://www.marcus.com/us/en',
  goldmanSachsPathForSsoConsentFalse: 'https://www.marcus.com/us/en/login?',
  goldmanSachsPathForSsoConsentTrue:
    'https://login.uatmarcus.com/sp/startSSO.ping?PartnerIdpId=https://gmb2ctest.b2clogin.com/669e8524-680a-42cb-bb68-f9edf41af46d/v2.0/&TargetResource=https://blue.uatmarcus.com/us/en/login/sso?returnUrl=/credit-cards/details?alternateAccountId=',
  adobeAnalyticsURL:
    '//assets.adobedtm.com/launch-ENbb043b8f11ea460eb87b12a8b0d0eeab-development.min.js',
  rewardsDashboardPath: 'https://experience.gm.com/myaccount/rewards',
  contactUsPath:
    'https://experience.gm.com/myaccount/rewards/contact-us'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { Injectable } from '@angular/core';
import { Constants } from 'src/app/app.constants';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  constructor() {}

  static reverseSort(a: string, b: string): number {
    return a > b ? -1 : a < b ? 1 : 0;
  }

  static calculateScaleFactor(width) {
    if (width < Constants.DESKTOP_SMALL_SIZE) {
      return '90vw';
    } else if (width < Constants.DESKTOP_XL_SIZE) {
      return '60vw';
    } else {
      return '40vw';
    }
  }
}

import {
  AfterViewInit,
  Component,
  Inject,
  SecurityContext
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-thirdparty-link-modal',
  templateUrl: './thirdparty-link-modal.component.html',
  styleUrls: ['./thirdparty-link-modal.component.scss']
})
export class ThirdpartyLinkModalComponent implements AfterViewInit {
  // The code to intercept links is in app.component
  content: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ThirdpartyLinkModalComponent>
  ) {
    this.content = data.content;
  }

  ngAfterViewInit(): void {
    // Angulars XSS protection will strip out the data attributes from the innerHTML
    const links = document.getElementsByClassName('thirdparty-link-button');
    for (let i = 0; i < links.length; i++) {
      links[i].setAttribute('data-dtm', this.content.dataDtm);
    }
  }

  closePopup(): void {
    this.dialogRef.close();
  }
}

<div class="d-flex flex-row justify-content-center align-items-center">
  <h1 class="center-text">Welcome back</h1>
</div>

<mat-dialog-content>
  <p class="center-text">
    Once you’ve signed in to your GM Account,<br class="hide-on-mobile" />
    you may need to link your card(s) to access your<br
      class="hide-on-mobile"
    />
    GM Rewards Card(s) information.
  </p>
</mat-dialog-content>

<button
  (click)="login()"
  class="gb-primary-button popup-white-button stat-button-link"
  data-dtm="modal:welcome back"
>
  Sign In
</button>
<div
  class="cancel-link stat-text-link d-flex flex-row justify-content-center align-items-center"
  data-dtm="modal:welcome back"
  (click)="close()"
>
  Cancel
</div>

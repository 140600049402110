import { Component, HostListener } from '@angular/core';
import { AdobeDtmService } from './_shared/services/adobe-dtm.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { ApiService } from 'src/app/_shared/services/api.service';
import { CardService } from './authenticated/services/card.service';
import { MatDialog } from '@angular/material/dialog';
import { ThirdpartyLinkModalComponent } from './_shared/components/thirdparty-link-modal/thirdparty-link-modal.component';
import { ContentService } from './_shared/services/content.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { ThirdPartyModal } from './_shared/models/third-party-modal/third-party-modal';
import { ThirdPartyLink } from './_shared/models/third-party-modal/third-party-link';
import { AemService } from './_shared/services/aem-content.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: []
})
export class AppComponent {
  thirdpartyLinkModals: ThirdPartyModal[];
  componentDestroyed = new Subject();
  title = 'GMCard';
  routerSubscription: Subscription;

  constructor(
    private adobeDtmService: AdobeDtmService,
    private bnIdle: BnNgIdleService,
    private readonly apiService: ApiService,
    private readonly cardService: CardService,
    private readonly contentService: ContentService,
    public dialog: MatDialog,
    private aem: AemService
  ) {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    document.onclick = this.interceptHref;

    // eslint-disable-next-line @typescript-eslint/unbound-method
    document.onclick = this.interceptHref;
  }

  @HostListener('window:onOpenLinkModal', ['$event.detail'])
  onOpenLinkModal(detail): void {
    const linkModal = this.thirdpartyLinkModals[detail.hostname];
    if (typeof linkModal !== 'undefined') {
      let thirdPartyLink: ThirdPartyLink;
      if (detail.authenticatedCopy) {
        if (typeof detail.linkSpecificKey !== 'undefined')
          thirdPartyLink = linkModal.authenticated[detail.linkSpecificKey];
        else thirdPartyLink = linkModal.authenticated.default;
      } else {
        if (typeof detail.linkSpecificKey !== 'undefined')
          thirdPartyLink = linkModal.unauthenticated[detail.linkSpecificKey];
        else thirdPartyLink = linkModal.unauthenticated.default;
      }

      thirdPartyLink.redirectUrl = detail.url;
      this.dialog.open(ThirdpartyLinkModalComponent, {
        data: { content: thirdPartyLink }
      });
    } else {
      if (!detail.target) window.location.href = detail.url;
      else window.open(detail.url, detail.target);
    }
  }

  interceptHref(_event: any): boolean {
    const tEvent = _event || window.event;
    const element = tEvent.target || tEvent.srcElement;

    if (
      element.tagName.toLowerCase() !== 'a' &&
      element.tagName.toLowerCase() !== 'img'
    )
      return;

    let hostname: string;
    let href: string;
    let target: string;
    let authenticatedCopy = true;
    let linkSpecificKey: string;

    if (element.tagName.toLowerCase() === 'a') {
      hostname = element.hostname.split('www.').pop();
      href = element.href;
      target = element.target;
    } else {
      if (typeof element.parentNode.hostname !== 'undefined') {
        hostname = element.parentNode.hostname.split('www.').pop();
      }
      href = element.parentNode.href;
      target = element.parentNode.target;
    }

    // Don't use the modal for links that are only there for click events
    if (typeof hostname === 'undefined') return;
    // Don't use the modal for local URLs
    if (hostname === window.location.hostname.split('www.').pop()) return;
    // Don't use the modal for class exceptions (ex: The continue button in the modal itself)
    if (
      element.classList.contains('thirdparty-link-button') ||
      element.parentNode.classList.contains('thirdparty-link-button')
    )
      return;
    // Don't open a model for empty or anchor links
    if (href === '' || href === '#') return;
    // Only prevent the clicks default behavior if we've passed all the above requirements
    _event.preventDefault();

    if (
      element.hasAttribute('authenticated-link') &&
      element.getAttribute('authenticated-link') === 'false'
    )
      authenticatedCopy = false;

    if (
      element.hasAttribute('link-specific') &&
      element.getAttribute('link-specific') !== ''
    ) {
      linkSpecificKey = element.getAttribute('link-specific');
    }

    window.dispatchEvent(
      new CustomEvent('onOpenLinkModal', {
        detail: {
          hostname: hostname,
          url: href,
          target: target,
          authenticatedCopy: authenticatedCopy,
          linkSpecificKey: linkSpecificKey
        }
      })
    );
  }

  ngOnInit() {
    this.apiService.getAemPage('json-content').subscribe(data =>{
      this.aem.setContent(data)
      let tplModals = JSON.parse(data?.thirdPartyLinkModalsJson);
      this.thirdpartyLinkModals = tplModals;

    }, error => {
      console.log(error)
    })
    this.adobeDtmService.initAdobeLaunchDataLayer();

    // Logout after 5 minutes of inactivity
    this.bnIdle.startWatching(300).subscribe((isTimedOut: boolean) => {
      if (window.location.host === 'localhost:4200') return;
      if (isTimedOut) {
        this.apiService.getLogoutUrl().subscribe(redirectUrl => {
          this.cardService.clearCards();
          window.location.href = redirectUrl;
        });
      }
    });
  }
}

<mat-accordion multi>
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header data-dtm="faq:about the buypower business card" class="stat-expand-icon">
      <mat-panel-title class="panel-title">
        <mat-icon class="plus">add</mat-icon>
        <mat-icon class="minus">remove</mat-icon>
        <span class="panel-title">About the GM Business&trade; Mastercard&reg;</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="text-left">
      <h2 class="question">What is the GM Business&trade; Mastercard&reg;?</h2>
      <div class="answer">
        <p>
          The GM Business&trade; Mastercard&reg; is a Credit Card that rewards business owners with Earnings on every purchase made by
          authorized users, redeemable toward the purchase or lease of an eligible, new Chevrolet, Buick, GMC or
          Cadillac
          <a class="stat-text-link" data-dtm="about the gm business card" href="/vehicles">vehicle.</a
          ><sup>1</sup> Receive 5% Earnings<sup>2</sup> on eligible purchases at authorized GM dealers on parts, accessories and
          <a
            class="stat-text-link"
            data-dtm="about the gm business card"
            href="http://www.mycertifiedservice.com/?evar25=gm_card_faq"
            target="_blank"
            >service</a
          >; 3% Earnings<sup>3</sup> on purchases at gas stations, restaurants and office supply stores; or 1% Earnings<sup>4</sup>
          on all other purchases. Plus, with the GM Business&trade; Mastercard&reg;, there is no annual fee.<sup>5</sup>
        </p>
        <p>
          The business owner obtains Earnings on every purchase made on Credit Cards issued under the same GM Business&trade; Mastercard&reg;
          Account (including other authorized users). There is no limit to the amount of Earnings you can accumulate or
          how much you can redeem toward your new vehicle, and Earnings don’t expire on an open Account. You can also
          combine your Earnings with most current GM offers and incentives. However, Earnings may not be used on the
          purchase of certain vehicles, such as those sold or obtained at auction, or with some offers and programs.
          Other exclusions may apply.
        </p>
        <p>
          GM Reserves the right to change the Program Rules and rewards, they may change at any time. Please see the
          <a
            class="stat-text-link"
            data-dtm="about the gm business card"
            href="https://www.gmcard.com/business/program-rules/?evar25=gm_card_faq"
            >GM Business&trade; Mastercard&reg; Program Rules</a
          >
          for terms, conditions and limitations.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">Can I use my GM Business&trade; Mastercard&reg; Earnings with the GM Employee or Supplier Discount?</h2>
      <div class="answer">
        <p>
          Yes. Your GM Business&trade; Mastercard&reg; Earnings can be combined with the
          <a
            class="stat-text-link"
            data-dtm="about the gm business card"
            href="https://www.gmfamilyfirst.com/about-the-discount/?evar25=gm_card_faq"
            target="_blank"
            >GM Employee</a
          >
          or
          <a
            class="stat-text-link"
            data-dtm="about the gm business card"
            href="https://www.gmsupplierdiscount.com/?evar25=gm_card_faq"
            target="_blank"
            >Supplier Discount</a
          >. However, this is only valid for personal vehicle purchases.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">What bank issues the GM Business&trade; Mastercard&reg;?</h2>
      <div class="answer">
        <p>
          <a
            class="stat-text-link"
            data-dtm="about the gm business card"
            href="https://www.marcus.com/us/en"
            target="_blank"
            authenticatedCopy="false"
            >Goldman Sachs Bank USA<sup>&reg;</sup></a
          >, is the issuer of the GM Business&trade; Mastercard&reg;. GM is responsible for the operation and administration of the
          Earnings Program.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">Is the GM Business&trade; Mastercard&reg; right for me even if I only have one vehicle?</h2>
      <div class="answer">
        <p>
          The GM Business&trade; Mastercard&reg; isn’t just designed for businesses with multiple vehicles. Even if your business consists
          of only one vehicle, the GM Business&trade; Mastercard&reg; will prove itself to be a great tool for your bottom line.
        </p>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header data-dtm="faq:gm business card earnings" class="stat-expand-icon">
      <mat-panel-title class="panel-title">
        <mat-icon class="plus">add</mat-icon>
        <mat-icon class="minus">remove</mat-icon>
        <span class="panel-title">GM Business&trade; Mastercard&reg; Earnings</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="text-left">
      <h2 class="question">How can I keep track of my Earnings?</h2>
      <div class="answer">
        <p>
          There are three ways to access your Earnings Statement. Current Cardmembers can view their Earnings Statement
          on the
          <a class="stat-text-link" data-dtm="gm business card earnings" href="/summary">Summary Page</a>. You can check
          your Earnings on your paper billing statement in the "Earnings Summary Box." You can also call 1-833-773-0990
          to get Earnings information.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">Can I accrue Earnings on Balance Transfers?</h2>
      <div class="answer">
        <p>
          Earnings can only be obtained on purchases. Earnings are not received on taxes, discounts, rebates, credits,
          incentives, cash advances, balance transfers, ATM withdrawals, finance charges or fees.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">Do I receive Earnings for purchases made by authorized users?</h2>
      <div class="answer">
        <p>Yes, all Earnings (including what is earned from employee Credit Card spend) go to the Business Owner.</p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">Do my Earnings expire?</h2>
      <div class="answer">
        <p>With the GM Business&trade; Mastercard&reg;, your Earnings don’t expire on an open Account.</p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">Can I issue my employees a GM Business&trade; Mastercard&reg; for their expenses?</h2>
      <div class="answer">
        <p>
          Yes. It is a great way to track their spending, set each Credit Card’s credit limit, track their spend activity
          online and accrue the Earnings for the Business Owner from the employee card spend.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">
        Along with Earnings and controls, what additional benefits come with the GM Business&trade; Mastercard&reg;?
      </h2>
      <div class="answer">
        <p>
          The GM Business&trade; Mastercard&reg; provides all the benefits and strength of
          <a
            class="stat-text-link"
            data-dtm="gm business card earnings"
            href="https://www.mastercard.us/en-us.html"
            target="_blank"
            >Mastercard</a
          ><sup>&reg;</sup>, including:
        </p>
        <ul>
          <li>Acceptance at millions of locations</li>
          <li>ATM access at over 911,000 locations worldwide*</li>
          <li>24/7 customer service</li>
          <li>24-hour emergency Credit Card replacement</li>
          <li>Business Travel Assistance and Purchase Protection Benefits**</li>
        </ul>
        <p>
          *The amount you withdraw from an ATM may be subject to daily limits and fees imposed by the financial
          institution or the owner of the ATM.
        </p>
        <p>
          **Certain restrictions, limitations and exclusions apply. Once your account is open, please see your Guide to
          Benefits for details.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">Are there limits on how much I can accumulate in Earnings?</h2>
      <div class="answer">
        <p>There is no limit to the amount of Earnings you can accumulate.</p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">Is there an Earnings limit on how much I can redeem?</h2>
      <div class="answer">
        <p>
          There is no limit to the amount of Earnings you can redeem towards a new Chevrolet, Buick, GMC or Cadillac
          vehicle.<sup>1</sup> Please see the
          <a class="stat-text-link" data-dtm="gm business card earnings" href="/program-rules">Program Rules</a>
          for complete details.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">What vehicles are eligible?</h2>
      <div class="answer">
        <p>
          Your GM Business&trade; Mastercard&reg; Earnings may be applied toward the purchase or lease of any eligible, new Chevrolet,
          Buick, GMC or Cadillac vehicle<sup>1</sup>
          delivered from an authorized GM dealer in the 50 United States or the District of Columbia. Vehicles include
          models from the following divisions: Chevrolet, Buick, GMC and Cadillac. Vehicles must be from the current
          vehicle model year, the previous two vehicle model years or the next model year.
        </p>
        <p>Earnings may not be applied to any vehicle sold or obtained at auction. Other exclusions may apply.</p>
        <p>See your authorized GM dealership for details and for vehicle eligibility.</p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">Where can I learn more about the vehicles online?</h2>
      <div class="answer">
        <p>
          You can search the great selection of cars, trucks and SUVs by visiting the
          <a
            class="stat-text-link"
            data-dtm="gm business card earnings"
            href="https://www.chevrolet.com/locate-inventory?evar25=gm_card_faq"
            target="_blank"
            title="open in a new tab"
            >Chevrolet</a
          >,
          <a
            class="stat-text-link"
            data-dtm="gm business card earnings"
            href="https://www.buick.com/locate-vehicle?evar25=gm_card_faq"
            target="_blank"
            title="open in a new tab"
            >Buick</a
          >,
          <a
            class="stat-text-link"
            data-dtm="gm business card earnings"
            href="https://www.gmc.com/view-inventory?evar25=gm_card_faq"
            target="_blank"
            title="open in a new tab"
            >GMC</a
          >
          and
          <a
            class="stat-text-link"
            data-dtm="gm business card earnings"
            href="https://www.cadillac.com/search-inventory?evar25=gm_card_faq"
            target="_blank"
            title="open in a new tab"
            >Cadillac</a
          >
          vehicle pages.
        </p>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header data-dtm="faq:redeeming earnings" class="stat-expand-icon">
      <mat-panel-title class="panel-title">
        <mat-icon class="plus">add</mat-icon>
        <mat-icon class="minus">remove</mat-icon>
        <span class="panel-title">Redeeming Earnings</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="text-left">
      <h2 class="question">
        How do I redeem my GM Business&trade; Mastercard&reg; Earnings toward a new Chevrolet, Buick, GMC or Cadillac vehicle?<sup>1</sup>
      </h2>
      <div class="answer">
        <p>
          To redeem your Earnings toward the retail purchase or lease of an eligible, new GM vehicle,<sup>1</sup> first
          check your available Earnings, then:
        </p>
        <ol>
          <li>Visit the authorized Chevrolet, Buick, GMC or Cadillac dealership of your choice.</li>
          <li>Agree on your best price by taking advantage of most current available offers and incentives.</li>
          <li>
            Identify yourself as a GM Business Cardmember and let the dealer representative know you would like to
            redeem your eligible Earnings.
          </li>
        </ol>
        <p>
          During the transaction, the dealer representative will be asked for certain vehicle sale information and then
          will be provided an authorization number to complete the transaction. Redeemed Earnings will be deducted from
          the Earnings balance on your Account. Confirmation of redemption will be sent to the Authorizing Officer via a
          separate letter and will be reflected in a subsequent GM Business&trade; Mastercard&reg; billing statement.
        </p>
        <p>
          If you would like to apply the Earnings to the purchase or lease of a new GM vehicle<sup>1</sup> by a
          household member of the Authorizing Officer, the Authorizing Officer will need to be present at the time of
          purchase or lease of the vehicle. The household member must reside at the Authorizing Officer's address and
          must not have, at the time of transfer, a GM Rewards Card product that provides Earnings or other rewards
          toward the purchase or lease of a new GM vehicle. The Authorizing Officer and household member may be required
          to provide proof of address.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">How can the GM Business&trade; Mastercard&reg; help manage my expenses?</h2>
      <div class="answer">
        <p>
          The GM Business&trade; Mastercard&reg; provides the flexibility of a business Credit Card rewards program combined with a host
          of easy-to-use tools. These tools can help you manage your Marcus by Goldman Sachs account, as well as other
          benefits unique to the GM Business&trade; Mastercard&reg; like account managers, business profile, tracking and spending
          analysis.
        </p>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header data-dtm="faq:gm business card account security" class="stat-expand-icon">
      <mat-panel-title class="panel-title">
        <mat-icon class="plus">add</mat-icon>
        <mat-icon class="minus">remove</mat-icon>
        <span class="panel-title">GM Business&trade; Mastercard&reg; Account/Security</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="text-left">
      <h2 class="question">If my Credit Card is lost or stolen, will I lose my accumulated Earnings?</h2>
      <div class="answer">
        <p>
          No, you will not lose your accumulated Earnings. If your GM Business&trade; Mastercard&reg; is lost or stolen, or your employee
          cards are lost or stolen, your balance will automatically be transferred to the new GM Business&trade; Mastercard&reg; Account
          you establish. This transfer will appear on your next billing statement.
        </p>
        <p>
          You or an employee card holder should immediately report a lost, stolen or compromised card to Marcus by
          chatting with a representative at
          <a
            href="https://www.marcus.com/us/en/banking-with-us/contact-marcus"
            class="stat-text-link"
            data-dtm="gm business card earnings"
            target="_blank"
            title="open in a new tab"
            authenticatedCopy="false"
            >Marcus.com</a
          >.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">Where can I find information about online security?</h2>
      <div class="answer">
        <p>
          Marcus by Goldman Sachs takes online security and identity theft seriously. Please visit the Marcus by Goldman
          Sachs
          <a
            href="https://www.marcus.com/us/en/banking-with-us/security-center"
            class="stat-text-link"
            data-dtm="gm business card earnings"
            target="_blank"
            title="open in a new tab"
            authenticatedCopy="false"
            >Marcus.com</a
          >
          for information about online security, "phishing" email scams and more.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">How can I manage my Account and pay my bill online?</h2>
      <div class="answer">
        <p>
          You can manage your GM Business&trade; Mastercard&reg; Account conveniently online through the
          <a
            class="stat-text-link"
            data-dtm="gm business card account security"
            href="https://www.marcus.com/us/en/login"
            target="_blank"
            authenticatedCopy="false"
            >Marcus by Goldman Sachs</a
          >
          site.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">
        Whom should I contact if I have questions or if I need help with my GM Business&trade; Mastercard&reg; Account?
      </h2>
      <div class="answer">
        <p>
          Call 1-833-733-0990 for questions regarding your Earnings or Credit Card Accounts. You can log in to your
          <a
            class="stat-text-link"
            data-dtm="gm business card account security"
            href="https://www.marcus.com/us/en/login"
            target="_blank"
            authenticatedCopy="false"
            >Marcus by Goldman Sachs</a
          >
          account to access your financial information.
        </p>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header data-dtm="faq:my gm rewards" class="stat-expand-icon">
      <mat-panel-title class="panel-title">
        <mat-icon class="plus">add</mat-icon>
        <mat-icon class="minus">remove</mat-icon>
        <span class="panel-title">My GM Rewards</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="text-left">
      <h2 class="question">What is My GM Rewards? Is this separate from the GM Business&trade; Mastercard&reg;?</h2>
      <div class="answer">
        <p>
          My GM Rewards<sup>6</sup> unlocks a world of appreciation that’s focused on you and your Chevrolet, Buick, GMC
          or Cadillac. Enjoy all the ways you can earn points, flexible options to redeem them, and surprise benefits
          along the way.
        </p>
        <p>
          You’ll earn points for eligible paid service and repairs<sup>7</sup> at participating GM dealerships, most
          OnStar<sup>&reg;</sup> and Connected Services plans,<sup>8</sup> plus SiriusXM<sup>&reg;</sup> subscriptions.
          You can then use these points on eligible purchases of accessories<sup>9</sup>, paid dealer service<sup>10</sup> and even
          toward the purchase or lease of your next new GM vehicle.<sup>11</sup>
        </p>
        <p>
          And even though you don't have to enroll in My GM Rewards, as a GM Business Cardmember, combining your Credit Card
          with My GM Rewards is a smart way to accelerate your Earnings. Because when you’re ready to purchase or lease
          a new GM vehicle,<sup>1</sup> you can combine your GM Business&trade; Mastercard&reg; Earnings with your points from My GM
          Rewards for even greater value (excludes purchases/leases in Oregon and Illinois and GM Employee Purchase
          Program, GM Supplier Discount Program, GM Employee QRD Purchase Program/Company-Owned Vehicle Program, GM
          Educator Program, GM First Responder Program, GM College Program and GM Military Program). Also excludes combining rewards 
          earned on your GM Business&trade; Mastercard&reg; with those earned on your My GM Rewards &reg; Mastercard&reg;.
        </p>
      </div>
    </div>
    <div class="text-left">
      <h2 class="question">How do I join My GM Rewards?</h2>
      <div class="answer">
        <p style="margin-bottom: 0; font-weight: bold">Get In</p>
        <p style="margin-top: 0">
          Start by enrolling<sup>6</sup> — it’s quick, simple and there’s no cost to join! Earning is easy, and new ways
          to redeem let you use your points, your way.
        </p>

        <p style="margin-bottom: 0; font-weight: bold">Move Up</p>
        <p style="margin-top: 0">
          Choose your path to more benefits and exclusive offers, with flexible ways to level up through three
          membership tiers.<sup>12</sup>
        </p>

        <p style="margin-bottom: 0; font-weight: bold">Drive On</p>
        <p style="margin-top: 0">
          Accelerate how you earn with the My GM Rewards&reg; Mastercard&reg;.<sup>13</sup> Get 7 points for every $1 spent
          on GM purchases<sup>14</sup> and 4 points for every $1 spent everywhere else<sup>15</sup>.
        </p>
        <p>
          Learn more about My GM Rewards and sign up at
          <a
            class="stat-text-link"
            data-dtm="gm business card account/security"
            href="https://experience.gm.com/rewards?evar25=gm_card_faq"
            target="_blank"
            >mygmrewards.com</a
          >.
        </p>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<div class="container">
  <div class="row-no-center">
    <div class="column-no-justify">
      <h1>Statement Credit Redemption Amount</h1>
    </div>
  </div>

  <div class="earnings-container">
    <h2>{{ wallet[0].availableEarnings || 0 | currency }}</h2>
    <p>
      <span 
        >Earnings available 
        <br class="d-block d-md-none" />
        for statement credit.
      </span>
    </p>
  </div>

  <div class="row-no-center">
    <div class="column-no-justify">
      <p>
        <span>
          Enter the amount to be redeemed as a statement credit
          <br class="d-none d-md-block"/>
          ($50 minimum):
        </span>
      </p>
      <form [formGroup]="redeemAmountForm">
        <div class="gb-input">
          <span>
            <div class="dollar-style">$</div>
            <input
              type="text"
              class="stat-input-field"
              id="statement-credit-amount"
              name="statement-credit-amount"
              formControlName="statementCreditAmount"
              placeholder="0.00"
              aria-label="my input"
              data-dtm="cash redemption:step1"
            />
            <span class="animate-underline"></span>
            <!-- animate-underline span is for GM Brand only -->
          </span>
        </div>

        <div *ngIf="redeemAmountForm.controls.statementCreditAmount.invalid">
          <div
            class="error"
            *ngIf="redeemAmountForm.controls.statementCreditAmount.errors.required"
          >
            Please enter an amount.
          </div>
          <div
            class="error"
            *ngIf="redeemAmountForm.controls.statementCreditAmount.errors.invalidNumericValue"
          >
            Please enter only Numeric values.
          </div>
          <div
            class="error"
            *ngIf="redeemAmountForm.controls.statementCreditAmount.errors.min"
          >
            Amount must be at least $50.00.
          </div>
          <div
            class="error"
            *ngIf="redeemAmountForm.controls.statementCreditAmount.errors.max"
          >
            Amount exceeds available balance.
          </div>
        </div>
      </form>
      <br />
      <div class="button-container">
        <button
          class="gb-primary-button light-grey-button stat-button-link"
          [routerLink]="'../redeem-cancel'"
          data-dtm="cash redemption:step1"
        >
          CANCEL
        </button>
        <button
          class="gb-primary-button green-button stat-button-link"
          (click)="updateAmount()"
          [disabled]="redeemAmountForm.invalid"
          data-dtm="cash redemption:step1"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</div>
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { InfoContainerComponent } from './components/info-container/info-container.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './material/material.module';
import { GoogleMapComponent } from './components/google-map/google-map.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LegalModalComponent } from './components/legal-modal/legal-modal.component';
import { DescriptionModalComponent } from './components/description-modal/description-modal.component';
import { DisclaimerModalComponent } from './components/disclaimer-modal/disclaimer-modal.component';
import {
  PrivacyModalComponent,
  PrivacyModalContentComponent
} from './components/privacy-modal/privacy-modal.component';
import { FilterPipe } from './pipes/filter/filter.pipe';
import { UniquePipe } from './pipes/unique/unique.pipe';
import { StringifyPipe } from './pipes/stringify.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';


@NgModule({
  declarations: [
    FooterComponent,
    GoogleMapComponent,
    HeaderComponent,
    InfoContainerComponent,
    LegalModalComponent,
    PrivacyComponent,
    DescriptionModalComponent,
    DisclaimerModalComponent,
    PrivacyModalComponent,
    PrivacyModalContentComponent,
    FilterPipe,
    StringifyPipe,
    UniquePipe,
    SafeHtmlPipe
  ],
  exports: [
    FooterComponent,
    GoogleMapComponent,
    HeaderComponent,
    InfoContainerComponent,
    PrivacyModalComponent,
    FilterPipe,
    StringifyPipe,
    UniquePipe,
    SafeHtmlPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    GoogleMapsModule,
    HttpClientModule,
    MaterialModule,
    MatToolbarModule,
    ReactiveFormsModule,
    RouterModule
  ],
  providers: [
    DescriptionModalComponent,
    DisclaimerModalComponent,
    DatePipe,
    CurrencyPipe
  ]
})
export class SharedModule {}

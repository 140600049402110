import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountResponse } from 'src/app/_shared/models/account-models/account-response';
import { Redemption } from 'src/app/_shared/models/redemption-models/redemption-response';
import { ApiService } from '../../../_shared/services/api.service';
import { CardService } from '../../services/card.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-redemption-history',
  templateUrl: './redemption-history.component.html',
  styleUrls: ['./redemption-history.component.scss']
})
export class RedemptionHistoryComponent implements OnInit {
  componentDestroyed = new Subject();
  user: AccountResponse;
  redemptionHistory: Redemption[];
  userSubscription: Subscription;
  showLoader = true;

  constructor(
    private readonly api: ApiService,
    private readonly userService: UserService,
    private readonly titleService: Title,
    private readonly cardService: CardService
  ) {}

  ngOnInit(): void {
    this.cardService
      .getCurrentCard()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(card => {
        this.titleService.setTitle(`Redemption History | ${card.name}`);
      });

    this.userSubscription = this.userService.user$.subscribe(
      (user: AccountResponse) => {
        this.user = user;

        this.api
          .getRedemptionHistory(this.user?.accountDetails?.trackID)
          .pipe(takeUntil(this.componentDestroyed))
          .subscribe(data => {
            this.redemptionHistory = data.redemptions;
            this.showLoader = false;
          });
      }
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
    this.userSubscription.unsubscribe();
  }

  printHistory(): void {
    window.print();
  }
}

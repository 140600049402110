<h2>Looks like we’re having trouble on our end.</h2>
<p>
  Try clicking the link below. If you’re still having trouble, <br />please try
  again later.
</p>
<a
  class="
    button-continue
    gb-primary-button
    green-button
    stat-button-link
    thirdparty-link-button
  "
  (click)="closePopup()"
  >Try Again</a
>

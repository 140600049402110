<div class="container vehicles-container">
  <div class="section row">
    <div class="column info">
      <h1 class="title">Choose a Model</h1>
      <p class="info-paragraph">
        <span *ngIf="cardType.path === 'efc'">
          Now comes the best part of being a GM Extended Family Cardmember:
          redeeming your Earnings toward an eligible, new Chevrolet, Buick, GMC
          or Cadillac vehicle.<sup>1</sup> Click on any brand below to see
          available models. <br />Happy shopping!
        </span>
        <span *ngIf="cardType.path === 'bpbc'">
          Now comes the best part of being a GM Business Cardmember: redeeming
          your Earnings toward a new Chevrolet, Buick, GMC or Cadillac
          vehicle.<sup>1</sup> Click on any brand below to see available models.
          <br />Happy shopping!
        </span>
        <span *ngIf="cardType.path !== 'efc' && cardType.path !== 'bpbc'">
          Now comes the best part of being a GM Cardmember: redeeming your
          Earnings toward a new Chevrolet, Buick, GMC or Cadillac vehicle.<sup
            >1</sup
          >
          Click on any brand below to see available models. <br />Happy
          shopping!
        </span>
      </p>
      <h2 *ngIf="cardType.path === 'copperplatinum'" class="font-gotham-bold">
        Please note that redemption allowances apply. See amounts for each
        vehicle listed below.
      </h2>
      <div flex-direction: row>
        <div class="header-style">
          <label class="header-style--label" for="car-style">Style:</label>
          <select
            class="header-style--type-select stat-dropdown"
            data-dtm="vehicles"
            #typeSelect
            (change)="onSelectChanged(typeSelect.value)"
            id="car-style"
          >
            <option
              *ngFor="let item of modelSelection | keyvalue"
              [value]="item.value"
            >
              {{item.key}}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div
    class="gallery-wrapper"
    *ngFor="let vehicle of filteredVehicleData; let i = index;"
  >
    <mat-expansion-panel>
      <mat-expansion-panel-header
        class="disable-ripple stat-expand-icon"
        [id]="vehicle[0].toLowerCase()"
        (click)="togglePreview(vehicle[0])"
        [attr.data-dtm]="vehicle[0].toLowerCase() + ':show all'"
      >
        <mat-panel-title>
          <span class="expand-desc--title"> {{vehicle[0].toUpperCase()}} </span>
          <img [id]="'logo' + i" [src]="logos[i]" class="expand-desc--logo" />
        </mat-panel-title>
        <div
          class="expand-desc"
          [attr.data-dtm]="vehicle[0].toLowerCase()"
          *ngIf="showPrev[i]; else showLess"
        >
          <div class="expand-desc--text">
            SHOW ALL {{vehicle[0].toUpperCase()}} VEHICLES
          </div>
          <div class="expand-desc--image">
            <mat-icon>add</mat-icon>
          </div>
        </div>
        <ng-template #showLess>
          <div class="expand-desc">
            <div class="expand-desc--text">
              SHOW FEWER {{vehicle[0].toUpperCase()}} VEHICLES
            </div>
            <div class="expand-desc--image">
              <mat-icon>remove</mat-icon>
            </div>
          </div>
        </ng-template>
      </mat-expansion-panel-header>

      <div class="vehicle-gallery">
        <div class="vehicle-gallery--item" *ngFor="let car of vehicle[1]">
          <img
            (click)="openVehicleDetailModal(car)"
            [attr.data-dtm]="vehicle[0].toLowerCase()"
            [src]="car.image"
            class="stat-image-link"
          />
          <h3
            class="stat-text-link gallery-link"
            [attr.data-dtm]="vehicle[0].toLowerCase()"
            (click)="openVehicleDetailModal(car)"
          >
            <strong>{{car.year}} {{car.model}}</strong>
          </h3>
          <div
            class="vehicle-gallery--item--redemption-allowance"
            *ngIf="showRedemptionAllowance"
          >
            Redemption Allowance {{car.redemptionAllowance | currency:
            'USD':'symbol':'1.0-0'}}
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <div class="vehicle-gallery" *ngIf="showPrev[i]">
      <div
        class="vehicle-gallery--item"
        *ngFor="let car of vehicle[1].slice(0,4)"
      >
        <img
          (click)="openVehicleDetailModal(car)"
          [attr.data-dtm]="vehicle[0].toLowerCase()"
          [src]="car.image"
          class="stat-image-link"
        />
        <h3
          class="stat-text-link gallery-link"
          [attr.data-dtm]="vehicle[0].toLowerCase()"
          (click)="openVehicleDetailModal(car)"
        >
          <strong>{{car.year}} {{car.model}}</strong>
        </h3>
        <div
          class="vehicle-gallery--item--redemption-allowance"
          *ngIf="showRedemptionAllowance"
        >
          Redemption Allowance {{car.redemptionAllowance | currency:
          'USD':'symbol':'1.0-0'}}
        </div>
      </div>
    </div>
  </div>

  <div class="bottom row">
    <div class="column">
      <h2 class="title">If you've got a question, we're here to help.</h2>
      <p class="no-top no-bottom">
        Find answers to your questions in the
        <a [routerLink]="'/faqs'" class="stat-text-link" data-dtm="get answers"
          >FAQs</a
        >. Not finding what you're looking for?
      </p>
      <p class="no-top">Click to contact us.</p>
      <a
        target="_blank"
        [href]="contactUsLink"
        class="stat-button-link cta-primary"
        data-dtm="get answers"
        >CONTACT US</a
      >
    </div>
  </div>
</div>

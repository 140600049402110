import { Component} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-legal-modal',
  templateUrl: './legal-modal.component.html',
  styleUrls: []
})
export class LegalModalComponent {
  constructor(public dialogRef: MatDialogRef<LegalModalComponent>) {}


  closePopup(): void {
    this.dialogRef.close();
  }
}

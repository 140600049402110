<div class="offers-section">
  <div *ngFor="let currentOffer of bannerOffers">
    <div class="row" *ngIf="visibleOffers.indexOf(currentOffer.offerId) !== -1">
      <div
        [ngClass]="['banner-container', cardType.path === 'bpbc' ? 'business-container' : '']"
      > 
        <img
          class="tag-image"
          [src]="currentOffer?.summaryPage?.imageSrc"
          [alt]="currentOffer?.summaryPage?.imageAlt" style="width:35px"
        />
        <div class="offer-text text-left">
          <span [innerHTML]="currentOffer?.summaryPage?.banner"></span>
        </div>
        <button
          mat-button
          class="learn-more stat-button-link"
          [attr.data-dtm]="'intro banner:' + currentOffer?.summaryPage?.ctaDtm"
        >
          <a [routerLink]="'../offers'" [fragment]="currentOffer.offerId"
            >Learn more</a
          >
        </button>
        <button
          mat-icon-button
          class="close-btn stat-button-close"
          [attr.data-dtm]="'intro banner:' + currentOffer?.summaryPage?.ctaDtm"
          (click)="closeBanner(currentOffer.offerId)"
        >
          <mat-icon>close</mat-icon>
        </button>
        <a
          class="mobile-learn-more stat-button-link"
          [attr.data-dtm]="'intro banner:' + currentOffer?.summaryPage?.ctaDtm"
          [routerLink]="'../offers'"
          [fragment]="currentOffer.offerId"
          >Learn more</a
        >
      </div>
    </div>
  </div>
</div>

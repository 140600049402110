import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PageInfo } from '../models/adobe/page-info';
import { ScriptInjectService } from './script-inject.service';
declare const window: Window;
declare const _satellite: any;
declare global {
  interface Window {
    digitalData: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AdobeDtmService {
  constructor(private scriptInjectService: ScriptInjectService) {}

  initAdobeLaunchDataLayer() {
    (window as Window).digitalData = {
      pageInfo: {
        universalID: '',
        pageName: '',
        url: window.location.href,
        seoStrategyPageName: '',
        pageType: 'general',
        pageSubType: '',
        languageSelected: 'en',
        country: 'us',
        region: 'gmna',
        renderedExperience: '',
        viewport: '',
        orientation: '',
        cardType: 'common',
        visitorStatus: 'public',
        cardTypeOwned: '',
        offerID: '',
        loginType: '',
        pageGoal: ''
      },

      visitorProfile: {
        userLoginStart: '',
        userLoginComplete: '',
        userRegistrationStart: '',
        userRegistrationComplete: '',
        zipCode: ''
      },

      formInfo: {
        formName: '',
        formFieldName: '',
        errorMessage: ''
      }
    };
  }

  getWindowObjectData(): void {
    return window.digitalData;
  }

  setWindowObjectData(data) {
    this.initAdobeLaunchDataLayer();
    /**
     * Typescript types strip out empty strings and null values, so we need
     * to set the windows property types manually rather than overwriting the
     * entire object. This way we preserve empty values which Analytics expects.
     */
    data = this.cleanObject(data);
    for (let i = 0; i < Object.keys(data).length; i++) {
      const digitalDataProperty = Object.keys(data)[i];
      for (let j = 0; j < Object.keys(data[digitalDataProperty]).length; j++) {
        const propertyKey = Object.keys(data[digitalDataProperty])[j];
        const propertyValue = data[digitalDataProperty][propertyKey];
        window.digitalData[digitalDataProperty][propertyKey] = propertyValue;
      }
    }
  }

  setupWindowPageInfoData(
    data: PageInfo,
    pageName: string,
    seoStrategyPageName: string
  ): any {
    //Setup Global Page Info Data
    data.pageInfo.pageName = data.pageInfo.pageName
      ? data.pageInfo.pageName
      : pageName;
    data.pageInfo.url = window.location.href;
    data.pageInfo.seoStrategyPageName = seoStrategyPageName;
    data.pageInfo.languageSelected = 'en';
    data.pageInfo.country = 'us';
    data.pageInfo.region = 'gmna';
    data.pageInfo.pageSubType = data.pageInfo.pageSubType
      ? data.pageInfo.pageSubType
      : '';
    data.pageInfo.renderedExperience = this.getRenderedExperience();
    data.pageInfo.viewport = this.getViewPortSize();
    data.pageInfo.orientation = this.getOrientation();

    return data;
  }

  getViewPortSize(): string {
    return window.innerWidth + 'x' + window.innerHeight;
  }

  getOrientation(): string {
    let orientation;
    if (window.innerWidth > window.innerHeight) {
      orientation = 'landscape';
    } else {
      orientation = 'portrait';
    }
    return orientation;
  }

  getRenderedExperience(): string {
    let screenSize;

    if (window.innerWidth > 1024) {
      screenSize = 'large';
    } else if (window.innerWidth > 640 && window.innerWidth < 1023) {
      screenSize = 'medium';
    } else if (window.innerWidth < 639) {
      screenSize = 'small';
    }
    return screenSize;
  }

  sendAdobeLaunchData(directCallName?): void {
    if (typeof directCallName === 'undefined') {
      directCallName = 'page-view';
    }
    if (directCallName !== null) {
      _satellite.track(directCallName);
    }
  }

  private updateDTMLayer(propName, propValue, parent) {
    if (typeof parent === 'undefined') {
      parent = 'pageInfo';
    }
    if (typeof window.digitalData === 'undefined') {
      console.error('[Update Data Layer] Adobe digitalData object is not set');
    } else if (typeof window.digitalData[parent] === 'undefined') {
      console.error(
        '[Update Data Layer] Adobe digitalData.' + parent + ' is not set'
      );
    } else if (typeof window.digitalData[parent][propName] === 'undefined') {
      console.error(
        '[Update Data Layer] Adobe digitalData.' +
          parent +
          '.' +
          propName +
          ' is not set'
      );
    } else {
      window.digitalData[parent][propName] = propValue;
    }
  }

  async injectAdobeLaunchScript(): Promise<void> {
    try {
      await this.scriptInjectService.load(
        'launch',
        environment.adobeAnalyticsURL
      );
      _satellite.pageBottom();
    } catch (e) {
      console.error('Error loading Adobe Launch', e);
    }
  }

  cleanObject(obj: any): Record<string, unknown> {
    Object.keys(obj).forEach(key => {
      if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
        delete obj[key];
      }

      if (typeof obj[key] === 'object') {
        this.cleanObject(obj[key]);
      }
    });
    return obj;
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from '../../../_shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class CookieGuard  {
  constructor(
    private readonly cookieService: CookieService,
    private readonly api: ApiService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.cookieService.get('hide-migration')) {
      this.api.getSsoUrl().subscribe(redirectUrl => {
        window.location.href = redirectUrl;
      });
      return false;
    }
    return true;
  }
}

import { AfterViewInit, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { PageInfo } from 'src/app/_shared/models/adobe/page-info';
import { AdobeDtmService } from 'src/app/_shared/services/adobe-dtm.service';
import { ApiService } from '../../../_shared/services/api.service';

@Component({
  selector: 'app-login-email-found-notice',
  templateUrl: './login-email-found-notice.component.html',
  styleUrls: ['./login-email-found-notice.component.scss']
})
export class LoginEmailFoundNoticeComponent implements AfterViewInit {
  email: string;

  constructor(
    private readonly api: ApiService,
    public dialogRef: MatDialogRef<LoginEmailFoundNoticeComponent>,
    private readonly adobeDTMService: AdobeDtmService
  ) {}

  ngAfterViewInit() {
    this.sendTrackingInfo();
  }

  login(_window: Window = window): void {
    this.api
      .getSsoUrl(this.email)
      .pipe(first())
      .subscribe(redirectUrl => {
        _window.location.href = redirectUrl.result;
      });
    this.close();
  }

  close(): void {
    this.dialogRef.close();
  }

  sendTrackingInfo() {
    let digitalData: PageInfo = {
      pageInfo: {
        cardType: 'common',
        cardTypeOwned: '',
        loginType: '',
        offerID: '',
        pageName: 'earnings.login | public | common',
        pageSubType: '',
        pageType: 'homepage',
        visitorStatus: 'logged',
        seoStrategyPageName: ''
      }
    };

    digitalData = this.adobeDTMService.setupWindowPageInfoData(
      digitalData,
      null,
      'Redeem'
    );

    this.adobeDTMService.setWindowObjectData(digitalData);
  }
}

<div class="current-redemptions">
  <div class="current-redemptions--main">
    <!-- *ngIf="cardType.path = == 'flex' || cardType.path = == 'worldflex'"  -->
    <mat-expansion-panel [expanded]="true" class="move-margin" hideToggle>
      <mat-expansion-panel-header
        class="stat-expand-icon"
        data-dtm="redemption activity"
      >
        <mat-panel-title>
          <mat-icon class="plus">add</mat-icon>
          <mat-icon class="minus">remove</mat-icon>
          <span>{{redemptionHeader}}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="current-redemptions--main--row">
        <div class="current-redemptions--main--row--column1">
          <a
            id="modalButton"
            (click)="ShowPopup('P139');"
            class="redemption-link stat-text-link"
            data-dtm="redemption activity"
            >Last Redemption Date</a
          >
        </div>
        <div class="current-redemptions--main--row--column2">
          <span
            *ngIf="lastRedemption?.date"
            [textContent]="lastRedemption?.date | date : 'MM/dd/yyyy'"
          ></span>
          <span *ngIf="!lastRedemption?.date">None</span>
        </div>
      </div>
      <div class="current-redemptions--main--row">
        <div class="current-redemptions--main--row--column1">
          <a
            (click)="ShowPopup('P140');"
            class="redemption-link stat-text-link"
            data-dtm="redemption activity"
            >Last Redemption Amount</a
          >
        </div>
        <div class="current-redemptions--main--row--column2">
          <span
            [textContent]="lastRedemption?.amount || 0 | currency : 'USD' "
          ></span>
        </div>
      </div>
      <div
        class="current-redemptions--main--row"
        *ngIf="cardType.path !== 'efc'"
      >
        <div class="current-redemptions--main--row--column1">
          <a
            (click)="ShowPopup('P143');"
            class="redemption-link stat-text-link"
            data-dtm="redemption activity"
            >Last Vehicle Redeemed</a
          >
        </div>
        <div class="current-redemptions--main--row--column2">
          <span
            *ngIf="lastRedemption?.lastRedeemedVehicle?.make && lastRedemption?.lastRedeemedVehicle?.model && lastRedemption?.lastRedeemedVehicle?.year"
            >{{lastRedemption.lastRedeemedVehicle?.make}}
            {{lastRedemption.lastRedeemedVehicle?.model}}
            {{lastRedemption.lastRedeemedVehicle?.year}}
          </span>
          <span #elseBlock> None </span>
        </div>
      </div>
      <div
        class="current-redemptions--main--row"
        *ngIf="cardType.path === 'efc'"
      >
        <div class="current-redemptions--main--row--column1">
          <a
            (click)="ShowPopup('P114');"
            class="redemption-link stat-text-link"
            data-dtm="redemption activity"
            >Last Redemption Type</a
          >
        </div>
        <div class="current-redemptions--main--row--column2">
          <span [textContent]="lastRedemption?.type || 'None'"></span>
        </div>
      </div>
      <div class="current-redemptions--main--row">
        <div class="current-redemptions--main--row--column1">
          <a
            (click)="ShowPopup('P142');"
            class="redemption-link stat-text-link"
            data-dtm="redemption activity"
            >Last Redemption Authorization Number</a
          >
        </div>
        <div class="current-redemptions--main--row--column2">
          <span class=""
            >{{lastRedemption?.authorizationNumber || 'None'}}</span
          >
        </div>
      </div>

      <div class="current-redemptions--main--row">
        <div class="current-redemptions--main--row--column1">
          <a
            (click)="ShowPopup('P144');"
            class="redemption-link stat-text-link"
            data-dtm="redemption activity"
            >Lifetime Earnings Redeemed</a
          >
        </div>
        <div class="current-redemptions--main--row--column2">
          <span
          [textContent]="earnings?.availableEarnings?.lifeRedeemed || 0 | currency : 'USD' "
          ></span> 
        </div>
      </div>
      <div class="current-redemptions--main--row">
        <div class="current-redemptions--main--row--column1">
          <a
            [routerLink]="'../redemption-history'"
            class="redemption-link stat-text-link"
            data-dtm="redemption activity"
            >View Full Redemption History</a
          >
        </div>
        <div class="current-redemptions--main--row--column2">
          <span>$0.00</span>
        </div>
      </div>
      <div class="clear"></div>
    </mat-expansion-panel>
  </div>
</div>

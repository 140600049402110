import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Card } from '../../_shared/models/card-models/card';
import { CardItem } from '../../_shared/models/card-models/card-item';
import { map } from 'rxjs/operators';
import { CardType } from '../../_shared/models/card-models/CardType';

@Injectable({
  providedIn: 'root'
})
export class CardService implements OnDestroy {
  rewardCards = new BehaviorSubject<Card[]>([]);
  cardTypeMap = new Map<CardType, Card>([
    //TODO: Verify if "path" s are correct.
    [
      CardType.NGMC,
      {
        name: 'My GM Rewards Card',
        path: 'ngmc',
        trackId: '',
        last4CardNumber: '',
        analyticsType: 'core',
        analyticsTypeOwned: 'core',
        analyticsLoginType: 'core'
      }
    ],
    [
      CardType.FLEX,
      {
        name: 'My GM Rewards Card',
        path: 'flex',
        trackId: '',
        last4CardNumber: '',
        analyticsType: 'flex',
        analyticsTypeOwned: 'flex',
        analyticsLoginType: 'flex'
      }
    ],

    [
      CardType.BLUE,
      {
        name: 'GM&trade; Mastercard&reg;',
        path: 'bluegold',
        trackId: '',
        last4CardNumber: '',
        analyticsType: 'bluegold',
        analyticsTypeOwned: 'core',
        analyticsLoginType: 'bluegold'
      }
    ],
    [
      CardType.GOLD,
      {
        name: 'GM Card',
        path: 'bluegold',
        trackId: '',
        last4CardNumber: '',
        analyticsType: 'bluegold',
        analyticsTypeOwned: 'core',
        analyticsLoginType: 'bluegold'
      }
    ],
    [
      CardType.COPPER,
      {
        name: 'GM&trade; Mastercard&reg;',
        path: 'copperplatinum',
        trackId: '',
        last4CardNumber: '',
        analyticsType: 'platinum',
        analyticsTypeOwned: '',
        analyticsLoginType: 'platinum'
      }
    ],
    [
      CardType.PLATINUM,
      {
        name: 'GM Card',
        path: 'copperplatinum',
        trackId: '',
        last4CardNumber: '',
        analyticsType: 'platinum',
        analyticsTypeOwned: '',
        analyticsLoginType: 'platinum'
      }
    ],
    [
      CardType.CHROME,
      {
        name: 'GM Card',
        path: 'chrome',
        trackId: '',
        last4CardNumber: '',
        analyticsType: 'platinum',
        analyticsTypeOwned: '',
        analyticsLoginType: 'platinum'
      }
    ],
    [
      CardType.WORLDPLAT,
      {
        name: 'GM Card',
        path: 'worldplat',
        trackId: '',
        last4CardNumber: '',
        analyticsType: 'platinum',
        analyticsTypeOwned: '',
        analyticsLoginType: 'platinum'
      }
    ],
    [
      CardType.WORLDREG,
      {
        name: 'GM Card',
        path: 'worldpreg',
        trackId: '',
        last4CardNumber: '',
        analyticsType: 'platinum',
        analyticsTypeOwned: '',
        analyticsLoginType: 'platinum'
      }
    ],
    [
      CardType.WORLDFLEX,
      {
        name: 'GM Card',
        path: 'worldflex',
        trackId: '',
        last4CardNumber: '',
        analyticsType: 'flex',
        analyticsTypeOwned: 'flex',
        analyticsLoginType: 'flex'
      }
    ],

    [
      CardType.EFC,
      {
        name: 'GM Extended&trade; Family Mastercard&reg;',
        path: 'efc',
        trackId: '',
        last4CardNumber: '',
        analyticsType: 'efc',
        analyticsTypeOwned: 'efc',
        analyticsLoginType: 'efc'
      }
    ],
    [
      CardType.BPBC,
      {
        name: 'GM Business&trade; Mastercard&reg;',
        path: 'bpbc',
        trackId: '',
        last4CardNumber: '',
        analyticsType: 'bpbc',
        analyticsTypeOwned: 'business',
        analyticsLoginType: 'bpc business'
      }
    ],
    [
      CardType.NoCardFound,
      {
        name: 'No CARD FOUND',
        path: '',
        trackId: '',
        last4CardNumber: '',
        analyticsType: '',
        analyticsTypeOwned: '',
        analyticsLoginType: ''
      }
    ]
  ]);

  currentCardSource = new BehaviorSubject<Card>({
    name: '',
    path: '',
    trackId: '',
    last4CardNumber: '',
    analyticsType: '',
    analyticsTypeOwned: '',
    analyticsLoginType: ''
  });

  changeCurrentCard(i: number): void {
    this.currentCardSource.next(this.rewardCards.value[i]);
  }

  setCurrentCard(trackId: string): void {
    this.rewardCards
      .asObservable()
      .pipe(map(cards => cards.find(card => card.trackId === trackId)))
      .subscribe(card => {
        this.currentCardSource.next(card);
      });
  }

  getUsersRewardCards(): Observable<Card[]> {
    return this.rewardCards.asObservable();
  }

  getCurrentCard(): Observable<Card> {
    return this.currentCardSource.asObservable();
  }

  setUserCards(cards: CardItem[]): any {
    const allCards = [];
    cards.forEach(cardItem => {
      allCards.push({
        ...this.cardTypeMap.get(cardItem.cardType),
        trackId: cardItem.trackId,
        last4CardNumber: cardItem.last4CardNumber.toString()
      });
    });
    this.rewardCards.next(allCards);
    this.currentCardSource.next(allCards[0]);
  }

  clearCards() {
    this.currentCardSource.next(null);
    this.rewardCards.next(null);
  }

  ngOnDestroy(): void {
    this.currentCardSource.unsubscribe();
    this.rewardCards.unsubscribe();
  }
}

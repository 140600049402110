import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-disclaimer-modal',
  templateUrl: './disclaimer-modal.component.html',
  styleUrls: []
})
export class DisclaimerModalComponent implements OnInit {
  disclaimers: Array<string>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DisclaimerModalComponent>
  ) {
    this.disclaimers = data.disclaimers;
  }

  ngOnInit(): void {}

  closePopup(): void {
    this.dialogRef.close();
  }
}

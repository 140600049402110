<button
  class="close-button stat-button-close"
  data-dtm="close modal"
  mat-icon-button
  (click)="closePopup()"
  style="float: right"
>
  <mat-icon>close</mat-icon>
</button>

<h1>Copyright Information</h1>

<p>
  This site and all contents copyrigh Material
  contained in this site pertains to products and services for the USA market
  only. The information contained herein is designed to be as comprehensive and
  factual as possible. We reserve the right, however, to make changes at any
  time, without notice, in price, colors, materials, equipment, specifications,
  models and availability.
</p>

<h1>Legal Disclaimers</h1>

<p>
  Some links provided in the GM Card site lead to sites furnished by independent
  site owners. The information presented therein is the sole responsibility of
  those site owners. GM has no control or responsibility for the content of
  independent sites, and provides these links to its visitors for their
  convenience.
</p>

import { Component, Input, OnInit } from '@angular/core';
import { PageInfo } from 'src/app/_shared/models/adobe/page-info';
import { AdobeDtmService } from 'src/app/_shared/services/adobe-dtm.service';
import { Card } from '../../../../_shared/models/card-models/card';

@Component({
  selector: 'app-link-account',
  templateUrl: './link-account.component.html',
  styleUrls: ['./link-account.component.scss']
})
export class LinkAccountComponent implements OnInit {
  @Input() cardType: Card;
  @Input() accountPath: string;

  digitalData: PageInfo;
  constructor(private readonly adobeDTMService: AdobeDtmService) {}

  ngOnInit(): void {
    let loginType = this.cardType.analyticsLoginType;
    if (loginType === 'copperplatinum' || loginType === 'platinum') {
      loginType = '';
    }

    this.digitalData = {
      pageInfo: {
        pageName: 'view.earnings | logged | ' + this.cardType.analyticsType,
        pageType: 'summary',
        pageSubType: '',
        cardType: this.cardType.analyticsType,
        visitorStatus: 'logged',
        cardTypeOwned: this.cardType.analyticsTypeOwned,
        offerID: '',
        loginType: loginType,
        pageGoal: 'nogoal'
      }
    };

    this.digitalData = this.adobeDTMService.setupWindowPageInfoData(
      this.digitalData,
      null,
      'Manage Card'
    );
    this.adobeDTMService.setWindowObjectData(this.digitalData);
  }

  sendTrackingInfo() {
    this.digitalData = this.adobeDTMService.setupWindowPageInfoData(
      this.digitalData,
      null,
      'Manage Card'
    );
    this.adobeDTMService.setWindowObjectData(this.digitalData);
  }
}

<mat-toolbar class="topnav">
  <div
    class="header-container d-flex flex-row"
    [ngClass]="{'unauthenticated-width': !authenticated}"
  >
    <div
      class="logo d-flex flex-row justify-content-between align-items-center align-content-center padding-15"
    >
      <a
        *ngIf="authenticated"
        id="logo_element"
        class="stat-image-link d-flex flex-row align-content-center align-items-center"
        [attr.data-dtm]="'global nav:' + currentRewardCard.path"
        [routerLink]="navLink"
        [ngSwitch]="currentRewardCard.path"
      >
        <span *ngSwitchDefault>
          <img
            class="header-logo d-block d-lg-none"
            src="assets/img/logos/gmrewardscards-logo-white.png"
            alt="GM Rewards Card Logo"
          />
          <img
            class="header-logo d-none d-lg-block"
            src="assets/img/logos/gmrewardscards-logo-white-mobile.png"
            alt="GM Rewards Card Logo"
          />
        </span>
        <span *ngSwitchCase="'efc'">
          <img
            class="header-logo d-block d-lg-none"
            src="assets/img/logos/gmrewardscards-efc-logo-white.png"
            alt="GM Extended Family Card Logo"
          />
          <img
            class="header-logo d-none d-lg-block"
            src="assets/img/logos/gmrewardscards-efc-logo-white-mobile.png"
            alt="GM Extended Family Card Logo"
          />
        </span>
        <span *ngSwitchCase="'bpbc'">
          <img
            class="header-logo d-block d-lg-none"
            src="assets/img/logos/gmrewardscards-business-logo-white.png"
            alt="GM Business Card Logo"
          />
          <img
            class="header-logo d-none d-lg-block"
            src="assets/img/logos/gmrewardscards-business-logo-white-mobile.png"
            alt="GM Business Card Logo"
          />
        </span>
      </a>
      <a
        *ngIf="authenticated"
        class="marcus-logo stat-image-link"
        href="https://www.marcus.com/us/en/login"
        target="_blank"
        id="marcus_logo"
        [attr.data-dtm]="'global nav:' + currentRewardCard.path"
        ><img src="assets/img/logos/marcus-header.png" alt="Marcus Logo"
      /></a>

      <a
        *ngIf="!authenticated"
        class="stat-image-link d-flex flex-row align-content-center align-items-center"
        [ngSwitch]="currentRewardCard.path"
        data-dtm="global nav"
        id="logo_element"
      >
        <img
          class="gm-logo-wide"
          src="assets/img/logos/gmrewardscards-logo-white-wide.png"
          alt="GM Rewards Card Logo"
        />
        <img
          class="gm-logo"
          src="assets/img/logos/gmrewardscards-logo-white-wide-mobile.png"
          alt="GM Rewards Card Logo"
        />
      </a>
      <a
        *ngIf="!authenticated"
        id="marcus_logo"
        data-dtm="global nav"
        class="marcus-logo--cropped stat-image-link"
        href="https://www.marcus.com/us/en/login"
        target="_blank"
      >
        <img src="assets/img/logos/marcus-header.png" alt="Marcus Logo" />
      </a>
    </div>
    <span class="flex-0"></span>
    <ng-content></ng-content>
  </div>
</mat-toolbar>

<mat-card appearance="outlined" class="info-container">
  <mat-card-header class="info-container--header mat-h1 mat-primary">
    <div class="info-container--header--container">
      <ng-content select="[header]"></ng-content>
    </div>
  </mat-card-header>
  <mat-card-content class="info-container--content">
    <ng-content select="[main]"></ng-content>
  </mat-card-content>
</mat-card>

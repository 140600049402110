import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {UnauthenticatedProgramRulesComponent} from './components/unauthenticated-program-rules/unauthenticated-program-rules.component';
import {UnauthenticatedBusinessProgramRulesComponent} from './components/unauthenticated-business-program-rules/unauthenticated-business-program-rules.component';
import {UnauthenticatedEfcProgramRulesComponent} from './components/unauthenticated-efc-program-rules/unauthenticated-efc-program-rules.component';


import { UnauthenticatedRoutingModule } from './unauthenticated-routing.module';
import { CardContainerComponent } from './components/card-container/card-container.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { UnauthenticatedContainerComponent } from './components/unauthenticated-container/unauthenticated-container.component';
import { LoginComponent } from './components/login/login.component';
import { SharedModule } from '../_shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SamlLoginComponent } from './components/saml-login/saml-login.component';
import { LoginEmailFoundNoticeComponent } from './components/login-email-found-notice/login-email-found-notice.component';
import { LoginEmailNotFoundNoticeComponent } from './components/login-email-not-found-notice/login-email-not-found-notice.component';
import { DealerAprComponent } from './components/dealer-apr/dealer-apr.component';
import { ErrorNoticeComponent } from './components/error-notice/error-notice.component';
import { MaterialModule } from '../_shared/material/material.module';

@NgModule({
  declarations: [
    CardContainerComponent,
    DealerAprComponent,
    WelcomeComponent,
    UnauthenticatedContainerComponent,
    LoginComponent,
    SamlLoginComponent,
    LoginEmailFoundNoticeComponent,
    LoginEmailNotFoundNoticeComponent,
    ErrorNoticeComponent,
    UnauthenticatedProgramRulesComponent,
    UnauthenticatedBusinessProgramRulesComponent,
    UnauthenticatedEfcProgramRulesComponent
  ],
  imports: [
    CommonModule,
    UnauthenticatedRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  providers: []
})
export class UnauthenticatedModule {}

import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CardService } from 'src/app/authenticated/services/card.service';
import { RedeemAmountService } from 'src/app/authenticated/services/redeem-amount.service';
import { PageInfo } from '../../../../_shared/models/adobe/page-info';
import { Card } from 'src/app/_shared/models/card-models/card';
import { AdobeDtmService } from '../../../../_shared/services/adobe-dtm.service';
import { ApiService } from '../../../../_shared/services/api.service';
@Component({
  selector: 'app-redeem-confirm',
  templateUrl: './redeem-confirm.component.html',
  styleUrls: ['./redeem-confirm.component.scss']
})
export class RedeemConfirmComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  amountToRedeem: string;
  amountRemaining: string;
  componentDestroyed = new Subject();
  card: Card;
  loading: boolean;
  showError: boolean;
  errorMessage: string;

  constructor(
    private redeemService: RedeemAmountService,
    private api: ApiService,
    private cardService: CardService,
    private router: Router,
    private readonly adobeDTMService: AdobeDtmService
  ) {}

  ngOnInit(): void {
    this.redeemService
      .getAmountObservable()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(amount => (this.amountToRedeem = amount));

    this.redeemService
      .getRemainingObservable()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(remaining => (this.amountRemaining = remaining));

    this.cardService
      .getCurrentCard()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(card => {
        this.card = card;
      });
  }

  ngAfterViewInit() {
    this.sendTrackingInfo();
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  processRedemptionStatement() {
    this.loading = true;
    this.showError = false;
    this.api
      .getStatementCredit(this.card.trackId, this.amountToRedeem)
      .subscribe(
        data => {
          this.loading = false;
          this.redeemService.setAuthorizationData(data);
          this.router.navigate(['redeem-receipt']);
        },
        error => {
          this.loading = false;
          this.errorMessage = 'Error: ' + error.error.errors[0].message;
          this.showError = true;
        }
      );
  }

  sendTrackingInfo() {
    let digitalData: PageInfo = {
      pageInfo: {
        cardType: this.card.analyticsType,
        cardTypeOwned: this.card.analyticsTypeOwned,
        loginType: this.card.analyticsLoginType,
        offerID: '',
        pageGoal: 'nogoal',
        pageName: 'redemption.step2 | logged | ' + this.card.analyticsType,
        pageSubType: '',
        pageType: 'redeem',
        visitorStatus: 'logged'
      },
      formInfo: {
        formName: 'redeem form',
        formFieldName: '',
        errorMessage: ''
      }
    };
    digitalData = this.adobeDTMService.setupWindowPageInfoData(
      digitalData,
      null,
      'Redemption Credit Review'
    );

    this.adobeDTMService.setWindowObjectData(digitalData);
    this.adobeDTMService.sendAdobeLaunchData();
  }
}

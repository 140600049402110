import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { ApiService } from '../../../_shared/services/api.service';
import { CardService } from '../../services/card.service';

@Component({
  selector: 'app-logout',
  template: ''
})
export class LogoutComponent implements OnInit {
  constructor(
    private readonly apiService: ApiService,
    private readonly cardService: CardService
  ) {}

  ngOnInit(): void {
    this.apiService
      .getLogoutUrl()
      .pipe(first())
      .subscribe(redirectUrl => {
        this.cardService.clearCards();
        window.location.href = redirectUrl.result;
      });
  }
}

import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AdobeDtmService } from '../../services/adobe-dtm.service';
import { CardService } from '../../../authenticated/services/card.service';
import { Card } from '../../models/card-models/card';
import { PageInfo } from '../../models/adobe/page-info';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

const ZoomLevel = {
  RANGE_4: 4,
  RANGE_8: 8,
  RANGE_9: 9,
  RANGE_10: 10,
  RANGE_11: 11
};

export class DealerInfo {
  dealerName;
  dealerAddress;
  dealerCity;
  dealerState;
  dealerPhone;
  dealerMarker: DealerMarker;
  directionToDealer;
  dealerWebsite;
}

class DealerMarker {
  pos: MarkerPosition;
  label: Label;
  title;
  options;
}

class MarkerPosition {
  lat: number;
  lng: number;
}

class Label {
  color = 'blue';
  text;
}

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit, OnDestroy {
  headlineSmall = 'All Chevrolet, Buick, GMC and Cadillac Dealers participate.';
  headlineBig = 'Visit a Nearby Dealer';
  componentDestroyed = new Subject();
  @Output() mapOutput = new EventEmitter<any>();
  searchResult;
  map;
  zoom;
  options: any = {
    mapTypeId: 'roadmap',
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: false,
    zoom: ZoomLevel.RANGE_4
  };
  center: any;

  dealersList: DealerInfo[] = [];
  selectedValue;
  brandOptions = ['Chevrolet', 'Buick', 'GMC', 'Cadillac'];
  searchMessage;
  errorMessage;
  showLoader = false;
  digitalData: PageInfo;

  dealerSearchForm: UntypedFormGroup;

  constructor(
    private readonly apiService: ApiService,
    private readonly cardService: CardService,
    private readonly adobeDTMService: AdobeDtmService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.center = {
      lat: 39.6422089300891,
      lng: -96.98808958689617
    };
    this.searchMessage = '';

    this.initializeDealerSearchForm();

    this.cardService
      .getCurrentCard()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((card: Card) => {
        const redeemGoal = 'goal';
        let loginType = card.analyticsLoginType;
        if (card.analyticsType === 'platinum') {
          loginType = '';
        }

        this.digitalData = {
          pageInfo: {
            pageName: 'how.to.redeem | logged | ' + card.analyticsType,
            pageType: 'redeem',
            pageSubType: '',
            cardType: card.analyticsType,
            visitorStatus: 'logged',
            cardTypeOwned: card.analyticsTypeOwned,
            offerID: '',
            loginType: loginType,
            pageGoal: redeemGoal
          },

          formInfo: {
            formName: '',
            formFieldName: '',
            errorMessage: ''
          }
        };
        this.adobeDTMService.setupWindowPageInfoData(
          this.digitalData,
          'how.to.redeem',
          'Redeem'
        );
        this.adobeDTMService.setWindowObjectData(this.digitalData);
      });
  }

  initializeDealerSearchForm() {
    this.dealerSearchForm = this.formBuilder.group({
      selectedBrand: ['', Validators.required],
      zipCode: ['', [Validators.required, Validators.minLength(5)]]
    });
  }

  getDealersInfo() {
    this.dealersList = [];
    this.showLoader = true;

    const zipCode = this.dealerSearchForm.controls.zipCode.value;
    const selectedBrand = this.dealerSearchForm.controls.selectedBrand.value;

    this.apiService
      .getDealersInfoByZipcode(selectedBrand, zipCode)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(
        resp => {
          this.errorMessage = '';
          // @ts-ignore
          if (resp.status.responseCode === '0') {
            // @ts-ignore
            resp.dealerList.forEach(el => {
              this.dealersList.push(this.generateDealerInfo(el.vendor));
            });
            this.center = this.dealersList[0].dealerMarker.pos;
            this.zoom = ZoomLevel.RANGE_9;
            this.showLoader = false;
          }
          this.searchResult = {
            searchInput: {
              make: selectedBrand,
              zipcode: zipCode
            },
            result: this.dealersList
          };
          this.mapOutput.emit(this.searchResult);
        },
        () => {
          this.dealersList = [];
          this.zoom = 5;
          this.showLoader = false;
          this.errorMessage = `There is no dealership associated with brand ${selectedBrand} and zipcode: ${zipCode}. Please search again with different inputs!`;
        }
      );
  }

  selectBrand(event: MatSelectChange): void {
    const newbrandOptions = ['Chevrolet', 'Buick', 'GMC', 'Cadillac'];
    newbrandOptions.splice(newbrandOptions.indexOf(event.value), 1);
    newbrandOptions.unshift(event.value);
    this.brandOptions = newbrandOptions;
  }

  generateDealerInfo(data): DealerInfo {
    const vendor = data;
    const dealerInfo = new DealerInfo();
    if (vendor) {
      dealerInfo.dealerName = vendor.primaryName;
      const addressInfo = vendor.contact.address[0];
      dealerInfo.dealerAddress =
        addressInfo.addressLine1 + ' ' + addressInfo.addressLine2;
      dealerInfo.dealerCity = addressInfo.addressCity;
      dealerInfo.dealerState = addressInfo.addressState;
      dealerInfo.dealerPhone = this.getDealerPhone(vendor.contact.phone);
      dealerInfo.directionToDealer = vendor.directionsUrl;
      dealerInfo.dealerWebsite = vendor.url.value;
      dealerInfo.dealerMarker = this.getDealerMarker(vendor.coordinates);
    }
    return dealerInfo;
  }

  getDealerMarker(coords): DealerMarker {
    const dealerMarker = new DealerMarker();
    dealerMarker.pos = new MarkerPosition();
    dealerMarker.pos.lat = +coords.latitude;
    dealerMarker.pos.lng = +coords.longitude;
    dealerMarker.options = {
      icon: {
        url: 'assets/img/icons/icon-map-pin.png',
        anchor: { x: 0, y: 0 }
      },
      zIndex: 100
    };
    return dealerMarker;
  }

  openNewTab(websiteUrl) {
    window.open(websiteUrl);
  }

  sendTrackingInfo() {
    this.adobeDTMService.setWindowObjectData(this.digitalData);
  }

  updateMapPos() {
    //console.log('position updated')
  }

  formatPhoneNumber(phone) {
    if (phone.length !== 10) {
      return '';
    }
    return phone.slice(0, 3) + '-' + phone.slice(3, 6) + '-' + phone.slice(6);
  }

  zoomIn() {
    if (this.zoom < this.options.maxZoom) {
      this.zoom++;
    }
  }

  zoomOut() {
    if (this.zoom > this.options.minZoom) {
      this.zoom--;
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  private getDealerPhone(phones: any) {
    const mainPhone = phones.filter(el => el.type === 'dealerMain');
    return this.formatPhoneNumber(mainPhone[0].number);
  }
}

import { Component, Input, AfterViewInit } from '@angular/core';
import { PageInfo } from 'src/app/_shared/models/adobe/page-info';
import { Card } from '../../../../_shared/models/card-models/card';
import { AdobeDtmService } from 'src/app/_shared/services/adobe-dtm.service';

@Component({
  selector: 'app-faqs-copperplatinum',
  templateUrl: './faqs-copperplatinum.component.html',
  styleUrls: ['./faqs-copperplatinum.component.scss']
})
export class FaqsCopperplatinumComponent implements AfterViewInit {
  @Input() cardType: Card;
  constructor(private readonly adobeDTMService: AdobeDtmService) {}

  ngAfterViewInit(): void {
    this.sendTrackingInfo();
  }

  sendTrackingInfo() {
    let digitalData: PageInfo = {
      pageInfo: {
        cardType: 'platinum',
        cardTypeOwned: '',
        loginType: '',
        offerID: '',
        pageGoal: 'goal',
        pageName: 'faq | logged | platinum',
        pageSubType: '',
        pageType: 'faq',
        visitorStatus: 'logged'
      }
    };

    digitalData = this.adobeDTMService.setupWindowPageInfoData(
      digitalData,
      '',
      'FAQ | ' + this.cardType.name.toLocaleLowerCase()
    );
    this.adobeDTMService.setWindowObjectData(digitalData);
    this.adobeDTMService.sendAdobeLaunchData();
  }
}

export enum CardType {
  NGMC,
  FLEX,
  GOLD,
  COPPER,
  BLUE,
  PLATINUM,
  CHROME,
  WORLDPLAT,
  WORLDREG,
  WORLDFLEX,
  EFC,
  BPBC,
  NoCardFound
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { CardService } from 'src/app/authenticated/services/card.service';
import { Card } from 'src/app/_shared/models/card-models/card';

@Component({
  selector: 'app-vehicle-modal',
  templateUrl: './vehicle-modal.component.html',
  styleUrls: ['./vehicle-modal.component.scss']
})
export class VehicleModalComponent implements OnInit, OnDestroy {
  data: any;
  componentDestroyed = new Subject();
  card: Card;
  constructor(
    private readonly dialogRef: MatDialogRef<VehicleModalComponent>,
    private readonly cardService: CardService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.data = data;
  }
  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  ngOnInit(): void {
    this.cardService
      .getCurrentCard()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((card: Card) => {
        this.card = card;
      });
  }

  closeModel() {
    this.dialogRef.close();
  }
}

<h1>{{vehicleTemplateContent?.header}}</h1>
<p
  *ngFor="let paragraph of vehicleTemplateContent?.body"
  [innerHTML]="paragraph.content"
></p>
<mat-accordion multi>
  <mat-expansion-panel
    *ngFor="let brandList of vehicleTemplateContent?.vehicles"
  >
    <mat-expansion-panel-header class="panel-header">
      <mat-panel-title class="panel-title">
        <mat-icon class="plus">add</mat-icon>
        <mat-icon class="minus">remove</mat-icon>
        {{ brandList.brand }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-container>
      <div
        class="row offers-text"
        *ngFor="let vehicle of brandList?.vehicleList"
      >
        <!-- Model Name column -->
        <div class="col">
          <p class="uppercase model">{{ vehicle }}</p>
        </div>
      </div>
    </ng-container>
  </mat-expansion-panel>
</mat-accordion>

<h2 class="privacy-modal--title" mat-dialog-title>
  <div class="privacy-modal--title__inner-text">GM Privacy Statement</div>
  <div (click)="closeDialog()" class="privacy-modal--title__close">
    <mat-icon>close</mat-icon>
  </div>
</h2>
<mat-dialog-content class="mat-typography">
  <p>
    General Motors is responsible for the operation and administration of the
    Earnings program. To view the GM Privacy Statement,
    <a
      href="https://www.gm.com/privacy-statement#cardmember?evar25=gm_card_privacy_statement_modal"
      target="_blank"
      >click here.
      <mat-icon
        aria-hidden="false"
        aria-label="Click here to go to an external site."
        inline="true"
        >open_in_new
      </mat-icon>
    </a>
  </p>

  <p>
    Goldman Sachs Bank USA, Salt Lake City Branch, is the issuer of the GM Card,
    GM Extended Family Card and GM Business Card. GM is solely responsible for
    the operation and administration of the Earnings program. To view the Marcus
    by Goldman Sachs Bank USA<sup>&reg;</sup> Privacy Statement,
    <a
      href="https://www.marcus.com/us/en/privacy-policy"
      target="_blank"
      class="stat-text-link"
      data-dtm="modal:privacy statement:marcus"
      >click here.
      <mat-icon
        aria-hidden="false"
        aria-label="Click here to go to an external site."
        inline="true"
        >open_in_new
      </mat-icon>
    </a>
  </p>
</mat-dialog-content>

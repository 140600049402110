<div class="manage-card row-no-center">
  <div class="manage-card__details column-no-justify">
    <h2
      *ngIf="(
        cardType?.path === 'bluegold' ||
        cardType?.path === 'blue' ||
        cardType?.path === 'copperplatinum' ||
        cardType?.path === 'platinum'
        )"
    >
      Manage Your <span class="nowrap">GM&trade; Mastercard&reg;</span>
    </h2>
    <h2 *ngIf="cardType?.path === 'buypowercard' || cardType?.path === 'ngmc'">
      Manage Your <span class="nowrap">BuyPower Card</span>
    </h2>
    <h2 *ngIf="cardType?.path === 'business' || cardType?.path === 'bpbc'">
      Manage Your <span class="nowrap">GM Business&trade; Mastercard&reg;</span>
    </h2>
    <h2 *ngIf="cardType?.path === 'efc'">
      Manage Your <span class="nowrap">GM Extended&trade; <br /> Family Mastercard&reg;</span>
    </h2>
    <h2 *ngIf="cardType?.path === 'flex'">
      Manage Your <span class="nowrap">GM Flexible Earnings Card</span>
    </h2>
    <p>Log in to Marcus to:</p>
    <ul>
      <li>Pay your bill electronically</li>
      <li>View your account balance and activity</li>
      <li>Update your Credit Card account information</li>
      <li>Request additional Credit Cards</li>
    </ul>
  </div>
  <div class="manage-card__link column">
    <a
      [href]="goldmanSachsLink"
      class="stat-button-link cta-primary"
      data-dtm="manage your card"
      rel="external"
      target="_blank"
      title="open in a new tab"
      (click)="sendTrackingInfo()"
      >Log In to Marcus<img
        [src]="'/assets/img/icons/link_out_white.png'"
        alt="Click this link to go to an external site"
        class="link-out"
    /></a>
  </div>
</div>

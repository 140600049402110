import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, first } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { CardService } from '../../services/card.service';
import { Card } from '../../../_shared/models/card-models/card';
import { ApiService } from 'src/app/_shared/services/api.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit, OnDestroy {
  menuItems: string[] = ['SUMMARY', 'VEHICLES', 'OFFERS', 'REDEEM', 'FAQs'];
  componentDestroyed = new Subject();
  userName: string;
  @Input() totalEarnings: number;

  rewardCards: Card[];

  currentRewardCard: Card;
  activeLink = this.menuItems[0];
  rewardsDashboardLink = environment.rewardsDashboardPath;

  constructor(
    private readonly cardService: CardService,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly apiService: ApiService
  ) {}

  ngOnInit(): void {
    this.userService
      .getAccount()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(account => {
        this.userName = account?.cardHolderDetails?.firstName;
      });

    this.cardService
      .getUsersRewardCards()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(cards => {
        this.rewardCards = cards;
      });

    this.cardService
      .getCurrentCard()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(card => {
        this.currentRewardCard = card;
      });
  }

  signOut(_window): void {
    _window = _window || window; //This is just for helping us with unit tests
    this.apiService
      .getLogoutUrl()
      .pipe(first())
      .subscribe(redirectUrl => {
        this.cardService.clearCards();
        _window.location.href = redirectUrl.result;
      });
  }

  switchCard(i: number): void {
    this.cardService.changeCurrentCard(i);
    this.router.navigate(['/summary']).then(); // Bring back user to summary page after switching to another card
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unique'
})
export class UniquePipe implements PipeTransform {
  // propertyName is the object property to use when determining uniqueness
  // if propertyName doesn't exist, it will work on arrays of simple types (strings, ints, etc)
  // if propertyName does exist, it will filter objects based on the given property
  /*
    Example 1
      Inputs:
        values: ['a', 'a', 'b', 'b', 'c']
        propertyName: undefined,
        returnWholeObject: undefined (defaults to true)
      Outputs:
        ['a', 'b', 'c']

    Example 2
      Inputs:
        values: [{ name: 'A', number: 1 }, { name: 'A', number: 2 }, { name: 'B', number: 2 }]
        propertyName: 'name'
        returnWholeObject: undefined (defaults to true)
      Outputs:
        [{ name: 'A', number: 1 }, { name: 'B', number: 2 }]

    Example 3
      Inputs:
        values: [{ name: 'A', number: 1 }, { name: 'A', number: 2 }, { name: 'B', number: 2 }]
        propertyName: 'name'
        returnWholeObject: false
      Outputs:
        ['A', 'B']
  */
  transform(
    values: any[],
    propertyName?: any,
    returnWholeObject: boolean = true
  ): any {
    return (values || [])
      .filter(
        (value, index, array) =>
          array.indexOf(value) === index &&
          (!propertyName
            ? true
            : array.findIndex(t => t[propertyName] === value[propertyName]) ===
              index)
      )
      .map(element => (returnWholeObject ? element : element[propertyName]));
  }
}

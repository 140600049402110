import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { SummaryService } from '../../services/summary.service';
import { ApiService } from '../../../_shared/services/api.service';
import { CardService } from '../../services/card.service';
import { UserService } from '../../services/user.service';
import { EarningsResponse } from 'src/app/_shared/models/earning-models/earnings-response';
import { Card } from 'src/app/_shared/models/card-models/card';
import { CurrentEarningsResponse } from 'src/app/_shared/models/statement-credit/current-earnings-response';

@Component({
  selector: 'app-authenticated-container',
  templateUrl: './authenticated-container.component.html',
  styleUrls: ['./authenticated-container.component.scss'],
  encapsulation: ViewEncapsulation.None // Needed to enable css modification for: mat-menu-panel
})
export class AuthenticatedContainerComponent implements OnInit, OnDestroy {
  componentDestroyed = new Subject();
  totalEarnings: number;
  earningsSubscription: Subscription;
  currentEarningsSubscription: Subscription;
  card: Card;
  isAuthenticated: boolean;

  constructor(
    private readonly apiService: ApiService,
    private readonly cardService: CardService,
    private readonly summaryService: SummaryService,
    private readonly userService: UserService
  ) {}
  ngOnInit(): void {
    this.initializeEarningSubscription();
    this.isAuthenticated = true;

    //TODO: Remove some of those redundant calls

    this.cardService
      .getCurrentCard()
      .pipe(
        takeUntil(this.componentDestroyed),
        switchMap((card: Card) => {
          this.card = card;
          return this.apiService.getCustomer(card.trackId);
        })
      )
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(account => {
        this.userService.setUser(account);
        this.userService.setAccount(account?.accountDetails);

        this.apiService
          .getEarningsStatement(account.accountDetails.trackID)
          .subscribe(data => {
            this.summaryService.setCardMemberRedemptionInfo(data);
          });

        this.apiService
          .getCurrentEarnings(account.accountDetails.trackID, 'Cash')
          .subscribe(earnData => {
            this.summaryService.setCurrentEarnings(earnData);
          });
      });

    this.currentEarningsSubscription = this.summaryService.currentEarnings$
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((earnings: CurrentEarningsResponse) => {
        this.totalEarnings = earnings?.availableEarnings;
      });
  }

  initializeEarningSubscription() {
    this.earningsSubscription = this.summaryService.cardRedemption$.subscribe(
      (earnings: EarningsResponse) => {
        this.totalEarnings = earnings?.earnings?.availableEarnings?.total;
      }
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
    this.currentEarningsSubscription.unsubscribe();
    this.earningsSubscription.unsubscribe();
  }
}

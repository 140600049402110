<app-header>
  <div
    [routerLink]="'/login'"
    class="link stat-text-link padding-15"
    data-dtm="user menu"
    id="login_link"
  >
    <span class="font-bold">Sign in</span>
    <mat-icon>lock</mat-icon>
  </div>
</app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RedeemAmountService } from '../redeem-amount.service';

@Injectable({
  providedIn: 'root'
})
export class RedeemGuard  {
  constructor(
    private readonly router: Router,
    private readonly service: RedeemAmountService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    const value = !!+this.service.getAmount();

    if (value) {
      return true;
    } else {
      this.router.navigate(['redeem']).then();
      return false;
    }
  }
}

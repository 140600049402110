<div
  class="vehicle-modal"
  [ngClass]="{ 'business': card.name.toLowerCase().indexOf('business') > -1 }"
>
  <div class="vehicle-modal--title">
    <div class="vehicle-modal--title__inner-text header-title">
      {{data.vehicleDesc}}
    </div>
    <div class="vehicle-modal--title__close pointer" (click)="closeModel()">
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div class="vehicle-modal--image">
    <img [src]="data.image" alt="" />
  </div>
  <div class="vehicle-modal--links">
    <a
      target="_blank"
      [href]="data.urls +'?evar25=gm_card_eligible_vehicles_modal'"
      class="stat-text-link"
      [attr.data-dtm]="data.vehicleDesc.toLowerCase() +' modal'"
      >Learn more on {{data.brand}}.com
      <mat-icon style="font-size: 1em">open_in_new</mat-icon><br />
    </a>
    <a
      href="/offers#vehicle-offers"
      (click)="closeModel()"
      class="stat-text-link"
      [attr.data-dtm]="data.vehicleDesc.toLowerCase() +' modal'"
      >See Current GM Vehicle* Offers</a
    >
  </div>

  <div class="vehicle-modal--text">
    <div class="vehicle-modal--text__inner-text">
      <h1>
        Combine eligible offers with Card Earnings for an even greater value!
      </h1>
      <div class="" *ngIf="!data.showRedemptionAllowance">
        <div class="earning-text">Current Earnings:</div>
        <div class="earning-value">{{data.earning | currency }}</div>
      </div>
      <div class="" *ngIf="data.showRedemptionAllowance">
        <div class="earning-text">Vehicle Redemption Allowance:</div>
        <div class="earning-value">
          {{data.redemptionAllowance | currency }}
        </div>
      </div>

      <p>
        <a
          href="/redeem"
          (click)="closeModel()"
          [attr.data-dtm]="data.vehicleDesc.toLowerCase() +' modal'"
          class="stat-text-link"
          >Learn how to redeem</a
        ><br />
        Use the
        <a
          [routerLink]="'/summary'"
          (click)="closeModel()"
          fragment="earnings-calculator"
          [attr.data-dtm]="data.vehicleDesc.toLowerCase() +' modal'"
          class="stat-text-link"
          >Earnings Calculator</a
        >
        and watch how quickly your Earnings could add up.
      </p>
    </div>
  </div>
</div>

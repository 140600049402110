import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PageInfo } from 'src/app/_shared/models/adobe/page-info';
import { Card } from 'src/app/_shared/models/card-models/card';
import { AdobeDtmService } from 'src/app/_shared/services/adobe-dtm.service';
import { ContentService } from 'src/app/_shared/services/content.service';
import { environment as env } from '../../../../environments/environment';
import { CardService } from '../../services/card.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss']
})
export class QuickLinksComponent implements OnInit, OnDestroy {
  @Input() cardType: Card;

  componentDestroyed = new Subject();

  myAccountActiveLink = env.myAccountPath;
  rewardsDashboardLink = env.rewardsDashboardPath;
  contactUsLink: string;
  goldmanSachsLink: string;
  rewardCards: Card[];
  digitalData: PageInfo;

  constructor(
    private contentService: ContentService,
    private cardService: CardService,
    private userService: UserService,
    private router: Router,
    private readonly adobeDTMService: AdobeDtmService
  ) {}

  ngOnInit(): void {
    this.cardService
      .getUsersRewardCards()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(cards => {
        this.rewardCards = cards;
      });

    this.contactUsLink = this.contentService.getContactUsLink();

    this.cardService
      .getCurrentCard()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(card => {
        if (this.userService.getUser()?.accountDetails?.ssoConsent) {
          this.goldmanSachsLink =
            this.contentService.getGoldmanSachsLink(
              this.userService.getUser()?.accountDetails?.ssoConsent
            ) + card.trackId;
        } else {
          this.goldmanSachsLink = this.contentService.getGoldmanSachsLink(
            this.userService.getUser()?.accountDetails?.ssoConsent
          );
        }
      });

    this.digitalData = {
      pageInfo: {
        pageName: 'view.earnings | logged | ' + this.cardType?.analyticsType,
        pageType: 'summary',
        pageSubType: '',
        cardType: this.cardType?.analyticsType,
        visitorStatus: 'logged',
        cardTypeOwned: this.cardType?.analyticsTypeOwned,
        offerID: '',
        loginType: this.cardType?.analyticsType,
        pageGoal: 'nogoal'
      }
    };

    this.digitalData = this.adobeDTMService.setupWindowPageInfoData(
      this.digitalData,
      null,
      'Summary'
    );

    this.adobeDTMService.setWindowObjectData(this.digitalData);
  }

  switchCard(i: number): void {
    this.cardService.changeCurrentCard(i);
    this.router.navigate(['/summary']).then(); // Bring back user to summary page after switching to another card
  }

  sendTrackingInfo() {
    this.digitalData = this.adobeDTMService.setupWindowPageInfoData(
      this.digitalData,
      null,
      'Summary'
    );

    this.adobeDTMService.setWindowObjectData(this.digitalData);
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }
}

import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Benefits } from '../models/summary-models/benefits';
import { EarningsCalculator } from '../models/summary-models/earnings-calculator';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  httpWithoutInterceptor: HttpClient;
  private readonly CARD_PATH = 'content/cardtype';

  constructor(
    private readonly httpBackend: HttpBackend,
    private readonly http: HttpClient
  ) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend);
  }

  public getContactUsLink(): string {
    const link = environment.contactUsPath;
    return link;
  }

  public getGoldmanSachsLink(ssoConsent: boolean): string {
    return ssoConsent
      ? environment.goldmanSachsPathForSsoConsentTrue
      : environment.goldmanSachsPathForSsoConsentFalse;
  }
}

import { AfterViewInit, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PageInfo } from 'src/app/_shared/models/adobe/page-info';
import { AdobeDtmService } from 'src/app/_shared/services/adobe-dtm.service';

@Component({
  selector: 'app-login-email-not-found-notice',
  templateUrl: './login-email-not-found-notice.component.html',
  styleUrls: ['./login-email-not-found-notice.component.scss']
})
export class LoginEmailNotFoundNoticeComponent implements AfterViewInit {
  url: string;

  constructor(
    public dialogRef: MatDialogRef<LoginEmailNotFoundNoticeComponent>,
    private readonly adobeDTMService: AdobeDtmService
  ) {}

  ngAfterViewInit() {
    this.sendTrackingInfo();
  }

  createAccount(_window: Window = window) {
    _window.location.href = this.url;
    this.close();
  }

  close() {
    this.dialogRef.close();
  }

  sendTrackingInfo() {
    let digitalData: PageInfo = {
      pageInfo: {
        cardType: 'common',
        cardTypeOwned: '',
        loginType: '',
        offerID: '',
        pageName: 'earnings.login | public | common',
        pageSubType: '',
        pageType: 'homepage',
        visitorStatus: 'logged',
        seoStrategyPageName: ''
      }
    };

    digitalData = this.adobeDTMService.setupWindowPageInfoData(
      digitalData,
      null,
      'Redeem'
    );

    this.adobeDTMService.setWindowObjectData(digitalData);
  }
}

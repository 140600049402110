<div class="background">
  <div class="apr">
    <h1 class="apr-header title">APR Information as of April 1, 2020</h1>

    <p class="apr-info text-left">
      This page contains Finance Annual Percentage Rates (APRs) and fees for
      purchases made with the BuyPower Card<sup>&reg;</sup> and the GM Extended
      Family Card from Capital One. These numbers vary between cards that were
      acquired through the service and financial departments of your dealership.
      Simply scroll down to view the rates that apply for your card. The chart
      further below provides Annual Percentage Rates and fees for the BuyPower
      Business Card™ from Capital One.
    </p>

    <div class="content">
      <!-- BuyPower Card and GM Extended Family Card section -->

      <img
        src="../../../../assets/img/combined-apr-cards.jpg"
        alt="BuyPower Card and GM Extended Family Card"
      />
      <div class="table-wrapper text-left">
        <h2 class="table-title">
          <strong>
            Cards acquired through Dealer Finance Area: <br />
            BuyPower Card and GM Extended Family Card Rates and Fees
          </strong>
        </h2>

        <div class="table">
          <div class="row">
            <div class="one-col">
              <p class="cell"><strong>Annual Fee</strong></p>
            </div>
          </div>
          <div class="row">
            <div class="two-col">
              <p class="cell">Annual Fee:</p>
            </div>
            <div class="two-col">
              <p class="cell">$0</p>
            </div>
          </div>
          <div class="row">
            <p class="cell"></p>
          </div>
          <div class="row">
            <div class="one-col">
              <p class="cell"><strong>Rates</strong></p>
            </div>
          </div>
          <div class="row">
            <div class="two-col">
              <p class="cell">Introductory Purchase APR:</p>
            </div>
            <div class="two-col">
              <p class="cell">0% on purchases for the first 12 months</p>
            </div>
          </div>
          <div class="row">
            <div class="two-col">
              <p class="cell">Purchase APR:</p>
            </div>
            <div class="two-col">
              <p class="cell">
                15.24%, 18.24% or 24.24% Variable APR based on your
                creditworthiness
              </p>
            </div>
          </div>
          <div class="row">
            <div class="two-col">
              <p class="cell">Balance Transfer APR:</p>
            </div>
            <div class="two-col">
              <p class="cell">
                15.24%, 18.24% or 24.24% Variable APR (not available at time of
                application)
              </p>
            </div>
          </div>
          <div class="row">
            <div class="two-col">
              <p class="cell">Cash Advance APR:</p>
            </div>
            <div class="two-col">
              <p class="cell">24.90% Variable APR</p>
            </div>
          </div>
          <div class="row">
            <div class="two-col">
              <p class="cell">Grace Period:</p>
            </div>
            <div class="two-col">
              <p class="cell">25 Days</p>
            </div>
          </div>
          <div class="row">
            <p class="cell"></p>
          </div>
          <div class="row">
            <div class="one-col">
              <p class="cell"><strong>Usage Fees</strong></p>
            </div>
          </div>
          <div class="row">
            <div class="two-col">
              <p class="cell">Cash Advance Fee:</p>
            </div>
            <div class="two-col">
              <p class="cell">
                Greater of 3% of the Advance amount or $10. No minimum Interest
                Charge.
              </p>
            </div>
          </div>
          <div class="row">
            <p class="cell"></p>
          </div>
          <div class="row">
            <div class="one-col">
              <p class="cell"><strong>Penalty Rates and Fees</strong></p>
            </div>
          </div>
          <div class="row">
            <div class="two-col">
              <p class="cell">Late Payment Fee:</p>
            </div>
            <div class="two-col">
              <p class="cell">Up to $38</p>
            </div>
          </div>
        </div>
      </div>

      <div class="table-wrapper text-left">
        <h2 class="table-title">
          <strong>
            Cards acquired through Dealer Service Area: <br />
            BuyPower Card and GM Extended Family Card Rates and Fees
          </strong>
        </h2>

        <div class="table">
          <div class="row">
            <div class="one-col">
              <p class="cell"><strong>Annual Fee</strong></p>
            </div>
          </div>
          <div class="row">
            <div class="two-col">
              <p class="cell">Annual Fee:</p>
            </div>
            <div class="two-col">
              <p class="cell">$0</p>
            </div>
          </div>
          <div class="row">
            <p class="cell"></p>
          </div>
          <div class="row">
            <div class="one-col">
              <p class="cell"><strong>Rates</strong></p>
            </div>
          </div>
          <div class="row">
            <div class="two-col">
              <p class="cell">Introductory Purchase APR:</p>
            </div>
            <div class="two-col">
              <p class="cell">0% on purchases for the first 12 months</p>
            </div>
          </div>
          <div class="row">
            <div class="two-col">
              <p class="cell">Purchase APR:</p>
            </div>
            <div class="two-col">
              <p class="cell">
                15.24%, 18.24% or 24.24% Variable APR based on your
                creditworthiness
              </p>
            </div>
          </div>
          <div class="row">
            <div class="two-col">
              <p class="cell">Balance Transfer APR:</p>
            </div>
            <div class="two-col">
              <p class="cell">
                15.24%, 18.24% or 24.24% Variable APR (not available at time of
                application)
              </p>
            </div>
          </div>
          <div class="row">
            <div class="two-col">
              <p class="cell">Cash Advance APR</p>
            </div>
            <div class="two-col">
              <p class="cell">24.90% Variable APR</p>
            </div>
          </div>
          <div class="row">
            <div class="two-col">
              <p class="cell">Grace Period</p>
            </div>
            <div class="two-col">
              <p class="cell">25 days</p>
            </div>
          </div>
          <div class="row">
            <p class="cell"></p>
          </div>
          <div class="row">
            <div class="one-col">
              <p class="cell"><strong>Usage Fees</strong></p>
            </div>
          </div>
          <div class="row">
            <div class="two-col">
              <p class="cell">Cash Advance Fee:</p>
            </div>
            <div class="two-col">
              <p class="cell">
                Greater of 3% of the Advance amount or $10. No minimum Interest
                Charge.
              </p>
            </div>
          </div>
          <div class="row">
            <p class="cell"></p>
          </div>
          <div class="row">
            <div class="one-col">
              <p class="cell"><strong>Penalty Rates and Fees</strong></p>
            </div>
          </div>
          <div class="row">
            <div class="two-col">
              <p class="cell">Late Payment Fee:</p>
            </div>
            <div class="two-col">
              <p class="cell">Up to $38</p>
            </div>
          </div>
        </div>
      </div>

      <br /><br />

      <!-- BuyPower Business Card section -->

      <img
        src="../../../../assets/img/compare-bpbc-apr-update.jpg"
        alt="BuyPower Business Card"
      />
    </div>
  </div>
</div>

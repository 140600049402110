import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Card } from 'src/app/_shared/models/card-models/card';
import { DescriptionModalComponent } from '../../../../_shared/components/description-modal/description-modal.component';

@Component({
  selector: 'app-redemption',
  templateUrl: './redemption.component.html',
  styleUrls: ['./redemption.component.scss']
})
export class RedemptionComponent implements OnInit {
  @Input() cardType: Card;
  @Input() lastRedemption: any;
  @Input() earnings: any;
  @Output() ShowPopupEvent = new EventEmitter();

  earningsOverviewHeader: string;
  isFlex: boolean;
  redemptionHeader: string;

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
    this.redemptionHeader = 'Redemption Activity';
  }

  ShowPopup(template: string): void {
    const dialogRef = this.dialog.open(DescriptionModalComponent, {
      width: '780px'
    });
    dialogRef.componentInstance.descriptionCode = template;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Card } from 'src/app/_shared/models/card-models/card';
import { ContentService } from 'src/app/_shared/services/content.service';

@Component({
  selector: 'app-authenticated-footer',
  templateUrl: './authenticated-footer.component.html',
  styleUrls: ['./authenticated-footer.component.scss']
})
export class AuthenticatedFooterComponent implements OnInit {
  @Input() cardType: Card;
  contactUsLink: string;

  constructor(private router: Router, private contentService: ContentService) {}

  navigation(route: string) {
    this.router.navigate([route]);
    window.scrollTo(0, 0);
  }

  ngOnInit(): void {
    this.contactUsLink =
      this.contentService.getContactUsLink() + '?evar25=gm_card_footer';
  }
}

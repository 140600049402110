import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ContentService } from '../../../../_shared/services/content.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Benefits } from '../../../../_shared/models/summary-models/benefits';
import { Card } from '../../../../_shared/models/card-models/card';
import { AdobeDtmService } from 'src/app/_shared/services/adobe-dtm.service';
import { PageInfo } from 'src/app/_shared/models/adobe/page-info';
import { AemService } from '../../../../_shared/services/aem-content.service';

@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.scss']
})
export class BenefitsComponent implements OnInit, OnDestroy {
  componentDestroyed = new Subject();
  items: any;
  benefits: Benefits;
  benefitsClass: string;

  @Input() cardType: Card;

  digitalData: PageInfo;

  constructor(
    private readonly contentService: ContentService,
    private readonly adobeDTMService: AdobeDtmService,
    private readonly aem: AemService
  ) { }

  ngOnInit(): void {
    const cardPath = this.cardType?.path ?? 'flex';

    this.aem
      .currentContent
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(benefits => {
        if (cardPath === 'copperplatinum') {
          const cpJson = JSON.parse(benefits.cpOverviewJson);
          this.benefits = cpJson;
        } else if (cardPath === 'bluegold') {
          const bgJson = JSON.parse(benefits.blueGoldOverviewJson);
          this.benefits = bgJson;
        } else if (cardPath === 'bpbc') {
          const bpbcJson = JSON.parse(benefits.bpbcOverviewJson);
          this.benefits = bpbcJson;
        } else if (cardPath === 'efc') {
          const efcJson = JSON.parse(benefits.efcOverviewJson);
          this.benefits = efcJson;
        }
        else {
          const efcJson = JSON.parse(benefits.bpcOverviewJson);
          this.benefits = efcJson;
        }
      });

    this.benefitsClass =
      this.cardType?.path === 'bpbc' ? 'benefits-business' : 'benefits';

    this.digitalData = {
      pageInfo: {
        pageName: 'view.earnings | logged | ' + this.cardType.analyticsType,
        pageType: 'summary',
        pageSubType: '',
        cardType: this.cardType.analyticsType,
        visitorStatus: 'logged',
        cardTypeOwned: this.cardType.analyticsTypeOwned,
        offerID: '',
        loginType: this.cardType.analyticsLoginType,
        pageGoal: 'nogoal'
      }
    };

    this.adobeDTMService.setWindowObjectData(this.digitalData);
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  sendTrackingInfo() {
    this.digitalData = this.adobeDTMService.setupWindowPageInfoData(
      this.digitalData,
      null,
      'Benefits'
    );
    this.adobeDTMService.setWindowObjectData(this.digitalData);
  }

  getBenefitsClassName(): string {
    if (this.cardType.path === 'business') {
      return 'benefits-business';
    }
  }

  toggleTeaser(itemId: string): void {
    const el = document.getElementsByClassName(itemId)[0];
    const mobileHidden = 'mobile-hidden';
    if (el.classList.contains(mobileHidden)) {
      el.classList.remove(mobileHidden);
    } else {
      el.classList.add(mobileHidden);
    }
    console.log();
  }
}

<div class="google-map">
  <div class="headline">
    <div class="headline__big title">{{headlineBig}}</div>
    <div class="headline__small">{{headlineSmall}}</div>
  </div>
  <div class="map-wrapper">
    <div class="map-wrapper__search">
      <div
        class="d-flex flex-lg-row flex-column flex-gap"
        [formGroup]="dealerSearchForm"
      >
        <div class="w-100">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Select make</mat-label>
            <mat-select
              name="brand"
              class="pointer"
              formControlName="selectedBrand"
              (selectionChange)="selectBrand($event)"
            >
              <mat-option
                *ngFor="let brand of brandOptions"
                [value]="brand"
                data-dtm="visit a nearby dealer"
                >{{brand}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="d-flex flex-row justify-content-between inner-w gap-2">
          <mat-form-field class="zipcode-w">
            <mat-label>zip code</mat-label>
            <input
              (click)="sendTrackingInfo()"
              formControlName="zipCode"
              matInput
              data-dtm="visit a nearby dealer"
            />
          </mat-form-field>
          <button
            mat-flat-button
            [disabled]="!dealerSearchForm.valid"
            (click)="getDealersInfo()"
            class="map-wrapper__search__input--go-button stat-button-link button-w"
            data-dtm="visit a nearby dealer"
          >
            <span class="go-button-text"> GO </span>
          </button>
        </div>
        
      </div>
      <div *ngIf="!showLoader">
        <div
          class="map-wrapper__search__result-text left"
          *ngIf="dealersList && dealersList.length > 0"
        >
          {{dealersList.length}} Dealers Found
        </div>
        <div
          class="map-wrapper__search__result-text left"
          *ngIf="dealersList && dealersList.length == 0"
        >
          Dealers Found
        </div>
        <ng-template #searchmessage>
          <div class="search-message" *ngIf="!errorMessage && !showLoader">
            {{searchMessage}}
          </div>
        </ng-template>
        <div class="error-message" *ngIf="errorMessage && !showLoader">
          {{errorMessage}}
        </div>
      </div>
      <div class="spinner" *ngIf="showLoader">
        <mat-spinner
          class="field-loading-spinner"
          diameter="50"
          matTooltip="Loading..."
        ></mat-spinner>
      </div>

      <div class="map-wrapper__search__result-wrapper" *ngIf="!showLoader">
        <div
          class="map-wrapper__search__result-wrapper__result"
          *ngFor="let dealer of dealersList"
        >
          <div class="map-wrapper__search__result-wrapper__result--info">
            <div class="dealer-name">{{dealer.dealerName}}</div>
            <div class="address-city">
              <div class="dealer-address">{{dealer.dealerAddress}}</div>
              <div class="dealer-city">{{dealer.dealerCity}}</div>
            </div>
            <div class="dealer-phone">{{dealer.dealerPhone}}</div>
          </div>
          <div class="map-wrapper__search__result-wrapper__result--buttons">
            <input
              class="
                pointer
                direction-button
                stat-button-link
                green-button
                cta-primary
              "
              type="button"
              value="GET DIRECTIONS TO DEALER"
              (click)="openNewTab(dealer.directionToDealer + '&evar25=gm_card_how_to_redeem_visit_a_nearby_dealer')"
              data-dtm="visit a nearby dealer:results"
            />
            <input
              class="pointer inventory-button stat-button-link"
              type="button"
              (click)="openNewTab(dealer.dealerWebsite + '?evar25=gm_card_how_to_redeem_visit_a_nearby_dealer'); sendTrackingInfo()"
              value="SEARCH DEALER INVENTORY"
              data-dtm="visit a nearby dealer:results"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="map-wrapper__google-map">
      <google-map
        width="100%"
        [zoom]="zoom"
        [options]="options"
        [center]="center"
      >
        <map-marker
          *ngFor="let marker of dealersList"
          [position]="marker.dealerMarker.pos"
          [label]="marker.dealerMarker.label"
          [title]="marker.dealerMarker.title"
          [options]="marker.dealerMarker.options"
          (mapClick)="openNewTab(marker.directionToDealer)"
          (positionChanged)="updateMapPos()"
          [clickable]="true"
        ></map-marker>
      </google-map>
    </div>
  </div>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ContentService } from '../../services/content.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AemService } from '../../../_shared/services/aem-content.service';

@Component({
  selector: 'app-description-modal',
  templateUrl: './description-modal.component.html',
  styleUrls: []
})
export class DescriptionModalComponent implements OnInit, OnDestroy {
  componentDestroyed = new Subject();

  popupDescriptions: any;
  displayedText: string;
  public descriptionCode: string;
  constructor(
    public dialogRef: MatDialogRef<DescriptionModalComponent>,
    private aem: AemService
  ) {}

  ngOnInit(): void {
    this.aem.currentContent.subscribe(data => {
      this.popupDescriptions = JSON.parse(data?.popupContentJson);
      this.displayedText = this.popupDescriptions[this.descriptionCode];
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  closePopup(): void {
    this.dialogRef.close();
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { VehicleContent } from 'src/app/_shared/models/offers-models/vehicle-content';

@Component({
  selector: 'app-vehicle-panel-list',
  templateUrl: './vehicle-panel-list.component.html',
  styleUrls: ['./vehicle-panel-list.component.scss']
})
export class VehiclePanelListComponent {
  @Input() vehicleTemplateContent: VehicleContent;

}

<app-header>
  <app-nav-menu
    class="authNav-section-menu"
    [totalEarnings]="totalEarnings"
  ></app-nav-menu>
</app-header>
<div class="body-container">
  <mat-card
    appearance="outlined"
    [ngClass]="{ 'business': card.name.toLowerCase().indexOf('business') > -1 }"
  >
    <mat-card-content>
      <router-outlet></router-outlet>
    </mat-card-content>
  </mat-card>
</div>

<app-footer [isAuthenticated]="isAuthenticated">
  <app-authenticated-footer [cardType]="card"></app-authenticated-footer>
</app-footer>

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../user.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard  {
  userSubscription: Subscription;
  isLoggedIn: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly user: UserService
  ) {
    this.userSubscription = this.user.getUserId().subscribe((s: boolean) => {
      this.isLoggedIn = s;
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.isLoggedIn) {
      return true;
    } else {
      this.router.navigate(['']);
      return false;
    }
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }
}

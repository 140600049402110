import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AdobeDtmService } from 'src/app/_shared/services/adobe-dtm.service';
import { PageInfo } from 'src/app/_shared/models/adobe/page-info';
import { CardService } from 'src/app/authenticated/services/card.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/_shared/services/api.service';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-program-rules',
  templateUrl: './unauthenticated-program-rules.component.html',
  styleUrls: ['./unauthenticated-program-rules.component.scss']
})
export class UnauthenticatedProgramRulesComponent
  implements OnInit, AfterViewInit
{
  cardType: any;
  componentDestroyed = new Subject();
  contents: Array<any>;

  constructor(
    private readonly adobeDTMService: AdobeDtmService,
    private readonly cardService: CardService,
    private readonly apiService: ApiService,
    private domSanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.cardService
      .getCurrentCard()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(card => {
        this.cardType = card;
      });
    this.sendTrackingInfo();
  }

  ngAfterViewInit() {
    this.sendTrackingInfo();
    this.getContent();
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  
  getContent(): void {
    this.apiService.getAemPage('json-content').subscribe(data =>{
      this.programRules(data);
    })
  }

  programRules(programRulesData){
    const programRulesJson = JSON.parse(programRulesData?.programRules);
    this.contents = programRulesJson['unauthenticated-program-rules'];
    if(this.contents !== undefined){
    for (let i = 0; i < this.contents.length; i++) {
      this.contents[i] = this.domSanitizer.bypassSecurityTrustHtml(
        this.contents[i]
      )
    }
  }
}

  sendTrackingInfo() {
    let digitalData: PageInfo = {
      pageInfo: {
        cardType: this.cardType.analyticsType,
        cardTypeOwned: this.cardType.analyticsTypeOwned,
        loginType: this.cardType.analyticsLoginType,
        offerID: '',
        pageGoal: 'nogoal',
        pageName: 'program.rules | logged | ' + this.cardType.analyticsType,
        pageSubType: '',
        pageType: 'login',
        seoStrategyPageName: 'Program Rules | ' + this.cardType.analyticsType,
        visitorStatus: 'logged'
      },
      formInfo: {
        formName: '',
        formFieldName: '',
        errorMessage: ''
      }
    };

    digitalData = this.adobeDTMService.setupWindowPageInfoData(
      digitalData,
      null,
      'Current Offers'
    );

    this.adobeDTMService.setWindowObjectData(digitalData);
    this.adobeDTMService.sendAdobeLaunchData();
  }
}

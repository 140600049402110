import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dealer-apr',
  templateUrl: './dealer-apr.component.html',
  styleUrls: ['./dealer-apr.component.scss']
})
export class DealerAprComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

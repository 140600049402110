import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  // nullIsIncluded - for when you want things like empty string to count as "all"
  transform(values: any[], filters?: any, nullIsIncluded: boolean = true): any {
    Object.keys(filters).forEach(key => {
      values = (values || []).filter(
        value =>
          (nullIsIncluded && !filters[key]) || value[key] === filters[key]
      );
    });
    return values;
  }
}

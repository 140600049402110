import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ApiService } from '../../../_shared/services/api.service';
import { map, catchError, switchMap, first } from 'rxjs/operators';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { CardService } from '../card.service';
import { UserService } from '../user.service';
import { AccountResponse } from 'src/app/_shared/models/account-models/account-response';

@Injectable({
  providedIn: 'root'
})
export class ReturnGuard  {
  constructor(
    private apiService: ApiService,
    private router: Router,
    private userService: UserService,
    private cardService: CardService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const trackId = route.paramMap.get('trackId');

    return this.apiService
      .getVerifyUserSessionByAccountId(trackId)
      .pipe(
        switchMap((trackId: string) => {
          return this.apiService.getCustomer(trackId);
        })
      )
      .pipe(
        map((user: AccountResponse) => {
          this.userService.setUser(user);
          this.cardService.setCurrentCard(trackId);
          void this.router.navigate(['summary']);
          return true;
        }),
        catchError((err: any) => {
          this.apiService
            .getSsoUrl(null, true)
            .pipe(first())
            .subscribe(redirectUrl => {
              window.location.href = redirectUrl;
            });
          return of(false);
        })
      );
  }
}

<div class="container center-text">
  <div class="row">
    <div class="column">
      <h1 class="title">How to Redeem</h1>
      <div class="redeem-earnings">
        <p>Your Current Earnings</p>
        <h1 class="total-earnings">{{totalEarnings | currency:'USD'}}</h1>
      </div>

      <h1 class="title extra-margin">Redeeming Earnings is Easy</h1>
    </div>
  </div>

  <div class="row" [ngClass]="getRedeemGuideClassName()">
    <div
      class="column"
      [ngClass]="redeemGuideClass+'--item'"
      *ngFor="let item of redeemGuideList"
    >
      <div [ngClass]="redeemGuideClass+'--item__image ' + item.imgClass">
        <img [src]="item.image" />
      </div>
      <div [ngClass]="redeemGuideClass+'--item--text'">
        <div
          [ngClass]="redeemGuideClass+'--item--text__big'"
          [innerHTML]="item.bigText"
        ></div>
        <div
          [ngClass]="redeemGuideClass+'--item--text__small'"
          [innerHTML]="item.smallText"
        ></div>
      </div>
    </div>
  </div>

  <div class="redeem-guide-efc--info row" *ngIf="selectedCard === 'efc'">
    <div class="column">
      &dagger;GM employees and retirees can go to
      <a
        target="_blank"
        class="stat-text-link"
        (click)="sendTrackingInfo()"
        data-dtm="redeeming earnings is easy"
        [href]="gmFamilyFirstUrl + '?evar25=gm_card_redeeming_earnings'"
        >GM Family First</a
      >
      or call 1-800-235-4646. GM Suppliers<br class="d-none d-xl-block" />
      can visit
      <a
        target="_blank"
        class="stat-text-link"
        (click)="sendTrackingInfo()"
        data-dtm="redeeming earnings is easy"
        [href]="gmSupplierDiscountUrl + '?evar25=gm_card_redeeming_earnings'"
        >GM Supplier Discount</a
      >
      or call 1-800-960-3375.
    </div>
  </div>

  <div class="grey-section row">
    <div class="column">
      <h1 class="title">What You Need to Bring to Dealer</h1>
      <p>
        Simply<button
          class="print-button stat-text-link"
          data-dtm="redeem"
          printSectionId="redeem-print"
          [useExistingCss]="true"
          ngxPrint
        >
          print</button
        >this page, or your
        <a
          class="stat-text-link"
          (click)="sendTrackingInfo()"
          data-dtm="redeem"
          routerLink="summary"
          fragment="print"
          >Earnings Statement</a
        >
        , and bring your <span [innerHTML]="cardName"></span> when you're ready
        to redeem.
      </p>
    </div>
  </div>

  <div class="google-map">
    <app-google-map (mapOutput)="updateSearchResult($event)"></app-google-map>
  </div>

  <div class="statement-credit" *ngIf="selectedCard === 'efc'">
    <div class="statement-credit--label">Statement Credit Redemption</div>
    <div class="statement-credit--detail" *ngIf="accountInGoodStanding">
      <span
        >You can request a statement credit online or by calling
        <br class="d-none d-md-block" />
        the GM Cardmember Services Redemption Center at 1-833-773-0988.
      </span>
    </div>
    <div class="statement-credit--detail" *ngIf="!accountInGoodStanding">
      Redeeming for statement credits is currently unavailable due to the status
      of your GM<br class="hide-on-mobile" />
      Extended Family Card. For more information please reach out to
      <a href="https://www.marcus.com/us/en/login">Marcus by Goldman Sachs</a>.
    </div>
    <div>
      <button
        class="statement-credit--button stat-button-link cta-primary green-button"
        [disabled]="!accountInGoodStanding"
        (click)="requestStatementCredit(); sendTrackingInfo()"
        data-dtm="redeem for statement credit"
      >
        Request Statement Credit
      </button>
    </div>
  </div>

  <h2 class="title">If you've got a question, we're here to help.</h2>
  <p class="no-bottom">
    Find answers to your questions in the
    <a [routerLink]="'../faqs'" class="stat-text-link" data-dtm="get answers"
      >FAQs</a
    >. Not finding what you're looking for?
  </p>
  <p class="no-top">Click to contact us.</p>
  <a
    target="_blank"
    [href]="contactUsLink"
    class="stat-button-link cta-primary"
    data-dtm="get answers"
    >CONTACT US</a
  >
</div>

<!--Print div-->
<div [hidden]="true">
  <app-redeem-print
    [data]="{
      totalEarnings: this.totalEarnings,
      selectedCard: this.selectedCard,
      redeemGuideList: this.redeemGuideList,
      redeemGuideListEfc: this.redeemGuideListEfc,
      gmFamilyFirstUrl: this.gmFamilyFirstUrl,
      gmSupplierDiscountUrl: this.gmSupplierDiscountUrl,
      searchResult: this.searchResult}"
  >
  </app-redeem-print>
</div>

import {
  AfterContentInit,
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { environment as env } from '../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { ApiService } from '../../../_shared/services/api.service';
import { UserService } from '../../../authenticated/services/user.service';
import { CardService } from '../../../authenticated/services/card.service';
import { Card } from 'src/app/_shared/models/card-models/card';
import { AdobeDtmService } from '../../../_shared/services/adobe-dtm.service';
import { PageInfo } from '../../../_shared/models/adobe/page-info';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ErrorNoticeComponent } from '../error-notice/error-notice.component';
import { UtilityService } from '../../../_shared/services/utility.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit, OnDestroy, AfterContentInit {
  componentDestroyed = new Subject();

  digitalData: PageInfo;
  hasCard = false;
  step = 0;
  goldmanSachsLink: string;

  aemContent: any;

  constructor(
    private readonly api: ApiService,
    private readonly cardService: CardService,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly adobeDTMService: AdobeDtmService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private readonly titleService: Title
  ) {}

  setStep(index: number): void {
    this.step = index;
  }

  nextStep(): void {
    this.step++;
  }

  prevStep(): void {
    this.step--;
  }

  ngOnInit(): void {
    this.titleService.setTitle(
      'Earnings Account Management | GM Rewards Cards'
    );

    this.goldmanSachsLink = environment.goldmanSachsPath;

    const error = this.route.snapshot.queryParamMap.get('error');
    if (error) {
      this.openErrorNotice(error);
    }

    this.cardService
      .getCurrentCard()
      .pipe(
        takeUntil(this.componentDestroyed),
        switchMap((card: Card) => {
          card ? (this.hasCard = true) : (this.hasCard = false);
          return this.api.getCustomer(card.trackId);
        })
      )
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(
        user => {
          this.userService.setUser(user);
          void this.router.navigate(['summary']).then();
        },
        error => {
          //No card and no customer data
          this.hasCard = false;
        }
      );

    this.digitalData = {
      pageInfo: {
        cardType: 'common',
        cardTypeOwned: '',
        loginType: '',
        offerID: '',
        pageName: 'earnings.login | public | common',
        pageSubType: '',
        pageType: 'homepage'
      },
      formInfo: {
        formName: 'login',
        formFieldName: '',
        errorMessage: ''
      }
    };

    this.digitalData = this.adobeDTMService.setupWindowPageInfoData(
      this.digitalData,
      'welcome',
      'Welcome'
    );

    this.adobeDTMService.setWindowObjectData(this.digitalData);
    this.adobeDTMService.sendAdobeLaunchData();
  }

  ngAfterContentInit(): void {
    this.sendTrackingInfo();
  }

  continueToSignIn(): void {
    // TODO: remove this if interceptor will take care of the redirect
    void this.router.navigate(['login']).then();
  }

  sendTrackingInfo(): void {
    this.adobeDTMService.setWindowObjectData(this.digitalData);
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  openErrorNotice(error: string): void {
    const dialogRef = this.dialog.open(ErrorNoticeComponent, {
      width: UtilityService.calculateScaleFactor(window.innerWidth)
    });
    dialogRef.componentInstance.error = error;
  }
}

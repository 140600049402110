<div class="earnings-calculator" id="earnings-calculator">
  <div class="row">
    <div class="column">
      <div class="earnings-calculator__heading">
        <h1 [innerHTML]="calculator?.header?.h1"></h1>
        <h2>
          <span [innerHTML]="calculator?.header?.h2?.first"></span> {{earnings |
          currency : 'USD'}}
          <span [innerHTML]="calculator?.header?.h2?.second"></span>
        </h2>

        <p class="line1" *ngIf="cardType.path === 'bluegold'">
          Watch Earnings add up by using the slider below
        </p>
        <p class="line1" *ngIf="cardType.path !== 'bluegold'">
          Watch how Earnings can add up by using the slider below
        </p>
        <p class="line2">{{calculator?.header?.percent}}</p>
      </div>
    </div>
  </div>

  <div
    *ngFor="let sliderConfig of calculator?.sliders; index as i"
    class="earnings-calculator__slider row"
  >
    <label
      *ngIf="sliderConfig.title"
      for="slider{{i}}"
      class="earnings-calculator__slider--title"
      [innerHtml]="sliderConfig.title"
      ></label
    >

    <div class="earnings-calculator__slider--container">
      <mat-slider
        class="mat-slider-brick mat-accent stat-slider"
        [ngClass]="{ 'business-calculator': cardType.path === 'bpbc' }"
        data-dtm="earnings"
        [value]="sliderConfig.value"
        [displayWith]="formatLabel"
        [displayWith]="formatLabel.bind(this)"
        id="slider{{i}}"
        max="{{sliderConfig.max}}"
        min="{{sliderConfig.min}}"
        thumbLabel
        discrete
      #ngSlider><input [value]="sliderConfig.value" (input)="sliderConfig.value=$event.target.value" max="1000" min="0" matSliderThumb (change)="updateEarnings(i, {source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value})" #ngSliderThumb="matSliderThumb"  (input)="updateEarnings(i, {source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value}) " /></mat-slider>
    </div>
  </div>

  <div class="earnings-calculator__display">
    <div class="earnings-calculator__display--header row">
      <div class="column">
        <h1>{{calculator?.title?.h1}}</h1>
        <h2 *ngIf="calculator?.title?.h2">{{calculator?.title?.h2}}</h2>
      </div>
    </div>

    <div class="earnings-calculator__display--table">
      <div class="row header">
        <div class="column">
          <p><strong>Year</strong></p>
        </div>
        <div class="column">
          <p><strong>Earnings</strong></p>
        </div>
      </div>

      <div class="row">
        <div class="column">
          <p>&nbsp;&nbsp;Year 1</p>
        </div>
        <div class="column">
          <p>{{[yearlyEarnings | currency : 'USD' : 'symbol' : '1.0-0'] }}</p>
        </div>
      </div>

      <div class="row">
        <div class="column">
          <p>+ Year 2</p>
        </div>
        <div class="column">
          <p>
            {{(yearlyEarnings * 2) | currency : 'USD' : 'symbol' : '1.0-0' }}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="column">
          <p>+ Year 3</p>
        </div>
        <div class="column">
          <p>
            {{(yearlyEarnings * 3) | currency : 'USD' : 'symbol' : '1.0-0'}}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="column">
          <p>+ Year 4</p>
        </div>
        <div class="column">
          <p>
            {{(yearlyEarnings * 4) | currency : 'USD' : 'symbol' : '1.0-0' }}
          </p>
        </div>
      </div>

      <div class="row last">
        <div class="column">
          <p>+ Year 5</p>
        </div>
        <div class="column">
          <p>
            {{(yearlyEarnings * 5) | currency : 'USD' : 'symbol' : '1.0-0'}}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="calculate-earnings-tracker-disclaimer">
    <div class="calculate-earnings-tracker-disclaimer-content">
      <img
        alt="{{calculator?.disclaimer?.icon?.alt}}"
        src="{{calculator?.disclaimer?.icon?.src}}"
      />
      <p [innerHTML]="calculator?.disclaimer?.content"></p>
    </div>
    <p [innerHTML]="calculator?.disclaimer?.final"></p>
  </div>
</div>

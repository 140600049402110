<div class="container" *ngIf="!cardRedemptionInfo">
  <div class="row">
    <div class="column" style="padding: 5%">
      <h2>Loading your Earnings...</h2>
      <mat-spinner [diameter]="150"></mat-spinner>
    </div>
  </div>
</div>

<div *ngIf="viewReady">
  <app-offers-banner [cardType]="cardType" [userEarnings]="earningsResponse">
  </app-offers-banner>
</div>

<div class="container" *ngIf="cardRedemptionInfo">
  <div class="row-no-center info-padding-top">
    <div class="earnings column-no-justify">
      <app-earnings [cardType]="cardType" [earnings]="earnings"> </app-earnings>
      <app-redemption [cardType]="cardType" [lastRedemption]="lastRedemption" [earnings]="earnings">
      </app-redemption>
    </div>
    <app-quick-links
      [cardType]="cardType"
      class="quick-links column-no-justify-left"
    ></app-quick-links>
  </div>
</div>

<div *ngIf="cardRedemptionInfo">
  <app-benefits [cardType]="cardType"> </app-benefits>
  <app-earnings-calculator
    [cardType]="cardType"
    [earnings]="earnings?.availableEarnings?.total"
  ></app-earnings-calculator>
  <app-manage-card [cardType]="cardType" class="d-none d-sm-block d-lg-none"></app-manage-card>
  <app-link-account [cardType]="cardType" class="d-none d-sm-block d-lg-none"></app-link-account>
  <section class="get-answers">
    <h2 class="title">If you've got a question, we're here to help.</h2>
    <p class="no-bottom">
      Find answers to your questions in the
      <a [routerLink]="'/faqs'" class="stat-text-link" data-dtm="get answers"
        >FAQs</a
      >. Not finding what you're looking for?
    </p>
    <p class="no-top">Click to contact us.</p>
    <a
      target="_blank"
      [href]="contactUsLink"
      class="stat-button-link cta-primary"
      data-dtm="get answers"
      >CONTACT US</a
    >
  </section>
</div>

<div class="current-earnings">
  <div class="current-earnings--main column-no-justify-left">
    <h1 class="header1-small">
      <span [innerHTML]="cardType.name"></span>...{{displayLast4}}
    </h1>
    <h2 class="header1 blue-text">
      <sup>$</sup
      ><span
        class="header-strong"
        [textContent]="earnings?.availableEarnings?.total || 0 | number : '1.2-2'"
      ></span
      ><span class="header1-sub-text">Total Earnings</span>
    </h2>
    <!-- dynamic content -->
    <p *ngIf="cardType.path == 'efc'" class="font-gotham-bold">
      Use your Earnings toward an
      <a
        (click)="showPopup('P171');"
        class="stat-text-link"
        data-dtm="your current earnings"
        >eligible, new GM vehicle</a
      ><sup>1</sup>
      <span> or toward&nbsp;</span>
      <a
        (click)="showPopup('P167');"
        class="stat-text-link"
        data-dtm="your current earnings"
        >statement credits<sup>2</sup></a
      >
      (over $50)
    </p>
    <h3 *ngIf="cardType.path == 'bpbc'">
      Total amount of Earnings available for redemption toward the purchase or
      lease of an
      <a
        (click)="showPopup('P170');"
        class="stat-text-link"
        data-dtm="your current earnings"
        >eligible, new GM vehicle</a
      >.<sup>1</sup>
    </h3>
    <h3 *ngIf="cardType.path != 'efc' && cardType.path != 'bpbc'">
      Use your Earnings toward a
      <a
        (click)="showPopup('P169');"
        class="stat-text-link"
        data-dtm="your current earnings"
        >new GM vehicle</a
      ><sup>1</sup>
    </h3>

    <div
      *ngIf="cardType.path == 'copperplatinum' || cardType.path == 'worldplat' || cardType.path == 'copper' || cardType.path == 'platinum'"
    >
      <p class="font-weight-normal">
        <strong
          >Note: Your Earnings are subject to Redemption Allowances. Check to
          see the current amount you
          <a [routerLink]="'../vehicles'" class="stat-text-link"
            >could save on your new GM vehicle here.</a
          ></strong
        >
      </p>
    </div>
    <mat-expansion-panel [expanded]="true" hideToggle>
      <mat-expansion-panel-header
        class="stat-expand-icon"
        data-dtm="earnings overview"
      >
        <mat-panel-title>
          <mat-icon class="plus">add</mat-icon>
          <mat-icon class="minus">remove</mat-icon>
          <span>{{earningsOverviewHeader}}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="current-earnings--main--row">
        <div class="current-earnings--main--row--column1">
          <a
            (click)="showPopup('P104');"
            *ngIf="cardType.path == 'efc'"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >Earnings</a
          >
          <a
            (click)="showPopup('P086');"
            *ngIf="cardType.path == 'bpbc'"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >Previous Earnings</a
          >
          <a
            (click)="showPopup('P130');"
            *ngIf="cardType.path != 'flex' && cardType.path != 'bpbc' && cardType.path != 'efc'"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >Previous Earnings</a
          >
          <a
            (click)="showPopup('P121');"
            *ngIf="cardType.path == 'flex'"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >Base Earnings</a
          >
        </div>
        <div class="current-earnings--main--row--column2">
          <span
            *ngIf="cardType.path == 'bpbc'"
            [textContent]="earnings?.availableEarnings?.baseAvailable || 0 | currency : 'USD' "
          ></span>
          <span
            *ngIf="cardType.path != 'flex' && cardType.path != 'bpbc'"
            [textContent]="earnings?.availableEarnings?.baseAvailable || 0 | currency : 'USD' "
          ></span>
          <span
            *ngIf="cardType.path == 'flex'"
            [textContent]="earnings?.availableEarnings?.baseAvailable || 0 | currency : 'USD' "
          ></span>
        </div>
      </div>
      <div class="current-earnings--main--row">
        <div class="current-earnings--main--row--column1">
          <a
            (click)="showPopup('P087');"
            *ngIf="cardType.path == 'bpbc'"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >+5% Earnings at GM Dealers parts and services<sup>2</sup></a
          >
          <a
            (click)="showPopup('P168');"
            *ngIf="cardType.path == 'efc'"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >Earnings Received</a
          >
          <a
            (click)="showPopup('P131');"
            *ngIf="cardType.path != 'flex' && cardType.path != 'bpbc' && cardType.path != 'efc'"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >Earnings Received</a
          >
          <a
            (click)="showPopup('P122');"
            *ngIf="cardType.path == 'flex'"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >Base Earnings Received</a
          >
        </div>
        <div class="current-earnings--main--row--column2">
          <span
            *ngIf="cardType.path == 'bpbc'"
            [textContent]="earnings?.percentEarnings?.atGMDealersPartsAndServices || 0 | currency : 'USD' "
          ></span>
          <span
            *ngIf="cardType.path != 'flex' && cardType.path != 'bpbc'"
            [textContent]="earnings?.availableEarnings?.current || 0 | currency : 'USD' "
          ></span>
          <span
            *ngIf="cardType.path == 'flex'"
            [textContent]="earnings?.availableEarnings?.received || 0 | currency : 'USD' "
          ></span>
        </div>
      </div>
      <div class="current-earnings--main--row">
        <div class="current-earnings--main--row--column1">
          <a
            (click)="showPopup('P088');"
            *ngIf="cardType.path == 'bpbc'"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >+3% Earnings at gas stations<sup>3</sup></a
          >
          <a
            (click)="showPopup('P132');"
            *ngIf="cardType.path != 'flex' && cardType.path != 'bpbc'"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >Earnings Adjusted</a
          >
          <a
            (click)="showPopup('P123');"
            *ngIf="cardType.path == 'flex'"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >Base Earnings Adjusted
          </a>
        </div>
        <div class="current-earnings--main--row--column2">
          <span
            *ngIf="cardType.path == 'bpbc'"
            [textContent]="earnings?.percentEarnings?.atGasStations || 0 | currency : 'USD' "
          ></span>
          <span
            *ngIf="cardType.path != 'flex' && cardType.path != 'bpbc'"
            [textContent]="earnings?.availableEarnings?.adjusted || 0 | currency : 'USD' "
          ></span>
          <span
            *ngIf="cardType.path == 'flex'"
            [textContent]="earnings?.availableEarnings?.adjusted || 0 | currency : 'USD' "
          ></span>
        </div>
      </div>
      <div *ngIf="cardType.path == 'flex'" class="current-earnings--main--row">
        <div class="current-earnings--main--row--column1">
          <a
            (click)="showPopup('P145');"
            *ngIf="cardType.path == 'flex'"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >Base Earnings Total
          </a>
        </div>
        <div class="current-earnings--main--row--column2">
          <span
            [textContent]="earnings?.availableEarnings?.total || 0 | currency : 'USD' "
          ></span>
        </div>
      </div>
      <div *ngIf="cardType.path == 'flex'" class="current-earnings--main--row">
        <div class="current-earnings--main--row--column1">
          <a
            (click)="showPopup('P146');"
            *ngIf="cardType.path == 'flex'"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >GM Vehicle Earnings</a
          >
        </div>
        <div class="current-earnings--main--row--column2">
          <span
            [textContent]="earnings?.availableEarnings?.forNewGMVehicle || 0 | currency : 'USD' "
          ></span>
        </div>
      </div>
      <div class="current-earnings--main--row">
        <div class="current-earnings--main--row--column1">
          <a
            (click)="showPopup('P089');"
            *ngIf="cardType.path == 'bpbc'"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >+3% Earnings at restaurants<sup>3</sup></a
          >
          <a
            (click)="showPopup('P133');"
            *ngIf="cardType.path != 'flex' && cardType.path != 'bpbc'"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >Current Period Earnings
          </a>
          <a
            (click)="showPopup('P124');"
            *ngIf="cardType.path == 'flex'"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >Anniversary Date
          </a>
        </div>
        <div class="current-earnings--main--row--column2">
          <span
            *ngIf="cardType.path == 'bpbc'"
            [textContent]="earnings?.percentEarnings?.atRestaurants || 0 | currency : 'USD' "
          ></span>
          <span
            *ngIf="cardType.path != 'flex' && cardType.path != 'bpbc'"
            [textContent]="earnings?.availableEarnings?.total || 0 | currency : 'USD' "
          ></span>
          <span
            *ngIf="cardType.path == 'flex'"
            [textContent]="earnings?.availableEarnings?.anniversaryDate | date : 'MM/dd/yyyy'"
          ></span>
        </div>
      </div>
      <div class="current-earnings--main--row">
        <div class="current-earnings--main--row--column1">
          <a
            (click)="showPopup('P090');"
            *ngIf="cardType.path == 'bpbc'"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >+3% Earnings at office supply stores<sup>3</sup></a
          >
          <a
            (click)="showPopup('P134');"
            *ngIf="cardType.path != 'flex' && cardType.path != 'bpbc'"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >Anniversary Date</a
          >
          <a
            (click)="showPopup('P125');"
            *ngIf="cardType.path == 'flex'"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >Anniversary YTD Earnings
          </a>
        </div>
        <div class="current-earnings--main--row--column2">
          <span
            *ngIf="cardType.path == 'bpbc'"
            [textContent]="earnings?.percentEarnings?.atOfficeSupplyStores || 0 | currency : 'USD' "
          ></span>
          <span
            *ngIf="cardType.path != 'flex' && cardType.path != 'bpbc'"
            [textContent]="earnings?.availableEarnings?.anniversaryDate || 0 | date : 'MM/dd/yyyy'"
          ></span>
          <span
            *ngIf="cardType.path == 'flex'"
            [textContent]="earnings?.availableEarnings?.anniversaryYTD || 0 | currency : 'USD' "
          ></span>
        </div>
      </div>
      <div class="current-earnings--main--row">
        <div class="current-earnings--main--row--column1">
          <a
            (click)="showPopup('P091');"
            *ngIf="cardType.path == 'bpbc'"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >+1% Earnings on all other purchases<sup>4</sup></a
          >
          <a
            (click)="showPopup('P135');"
            *ngIf="cardType.path != 'flex' && cardType.path != 'bpbc'"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >Anniversary YTD Earnings</a
          >
          <a
            (click)="showPopup('P126');"
            *ngIf="cardType.path == 'flex'"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >Earnings Expiring This Month (if applicable)</a
          >
        </div>
        <div class="current-earnings--main--row--column2">
          <span
            *ngIf="cardType.path == 'bpbc'"
            [textContent]="earnings?.percentEarnings?.onOtherPurchases || 0 | currency : 'USD' "
          ></span>
          <span
            *ngIf="cardType.path != 'flex' && cardType.path != 'bpbc'"
            [textContent]="earnings?.availableEarnings?.anniversaryYTD || 0 | currency : 'USD' "
          ></span>
          <span
            *ngIf="cardType.path == 'flex'"
            [textContent]="earnings?.expiringEarnings?.thisMonth || 0 | currency : 'USD' "
          ></span>
        </div>
      </div>
      <div class="current-earnings--main--row">
        <div class="current-earnings--main--row--column1">
          <a
            (click)="showPopup('P092');"
            *ngIf="cardType.path == 'bpbc'"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >Earnings Adjustments</a
          >
          <a
            (click)="showPopup('P136');"
            *ngIf="cardType.path != 'flex' && cardType.path != 'bpbc'"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >Earnings Expiring This Month (if applicable)</a
          >
          <a
            (click)="showPopup('P127');"
            *ngIf="cardType.path == 'flex'"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >Earnings Expiring Next Month (if applicable)</a
          >
        </div>
        <div class="current-earnings--main--row--column2">
          <span
            *ngIf="cardType.path == 'bpbc'"
            [textContent]="earnings?.availableEarnings?.adjusted || 0 | currency : 'USD' "
          ></span>
          <span
            *ngIf="cardType.path != 'flex' && cardType.path != 'bpbc'"
            [textContent]="earnings?.expiringEarnings?.thisMonth || 0 | currency : 'USD' "
          ></span>
          <span
            *ngIf="cardType.path == 'flex'"
            [textContent]="earnings?.expiringEarnings?.nextMonth || 0 | currency : 'USD' "
          ></span>
        </div>
      </div>
      <div
        *ngIf="cardType.path != 'flex' && cardType.path != 'bpbc'"
        class="current-earnings--main--row"
      >
        <div class="current-earnings--main--row--column1">
          <a
            (click)="showPopup('P137');"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >Earnings Expiring Next Month (if applicable)</a
          >
        </div>
        <div class="current-earnings--main--row--column2">
          <span
            *ngIf="cardType.path != 'flex' && cardType.path != 'bpbc'"
            [textContent]="earnings?.expiringEarnings?.nextMonth || 0 | currency : 'USD' "
          ></span>
        </div>
      </div>
      <div class="current-earnings--main--row">
        <div class="current-earnings--main--row--column1">
          <a
            (click)="showPopup('P093');"
            *ngIf="cardType.path == 'bpbc'"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >Total Earnings available for Redemption</a
          >
          <span
            *ngIf="cardType.path != 'bpbc'"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >Earnings Expiring Two Months Later</span
          >
        </div>
        <div class="current-earnings--main--row--column2">
          <span
            *ngIf="cardType.path == 'bpbc'"
            [textContent]="earnings?.availableEarnings?.total || 0 | currency : 'USD' "
          ></span>
          <span
            *ngIf="cardType.path == 'efc'"
            [textContent]="earnings?.expiringEarnings?.nextTwoMonth || 0 | currency : 'USD' "
          ></span>
          <span
            *ngIf="cardType.path != 'bpbc' && cardType.path != 'efc'"
            [textContent]="earnings?.expiringEarnings?.nextOneStatement || 0 | currency : 'USD' "
          ></span>
        </div>
      </div>
      <div class="current-earnings--main--row" *ngIf="cardType.path == 'bpbc'">
        <div class="current-earnings--main--row--column1">
          <a
            (click)="showPopup('P108');"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >Anniversary Date</a
          >
        </div>
        <div class="current-earnings--main--row--column2">
          <span
            [textContent]="earnings?.availableEarnings?.anniversaryDate | date:'shortDate'"
          ></span>
        </div>
      </div>
      <div class="current-earnings--main--row" *ngIf="cardType.path == 'bpbc'">
        <div class="current-earnings--main--row--column1">
          <a
            (click)="showPopup('P109');"
            class="earnings-link stat-text-link"
            data-dtm="earnings overview"
            >Anniversary YTD Earnings</a
          >
        </div>
        <div class="current-earnings--main--row--column2">
          <span
            [textContent]="earnings?.availableEarnings?.anniversaryYTD || 0 | currency : 'USD' "
          ></span>
        </div>
      </div>
      <div class="clear"></div>
    </mat-expansion-panel>
  </div>
</div>

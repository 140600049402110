import { Component, OnInit } from '@angular/core';
import { CardService } from '../../services/card.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Card } from 'src/app/_shared/models/card-models/card';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-program-rules',
  templateUrl: './program-rules-container.component.html',
  styleUrls: ['./program-rules-container.component.scss']
})
export class ProgramRulesContainerComponent implements OnInit {
  card: Card;
  componentDestroyed = new Subject();

  constructor(
    private readonly cardService: CardService,
    private readonly titleService: Title
  ) {}

  ngOnInit(): void {
    this.cardService
      .getCurrentCard()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(card => {
        this.card = card;
        this.titleService.setTitle(`Program Rules | ${card.name}`);
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }
}

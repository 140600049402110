import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-redeem-print',
  templateUrl: './redeem-print.component.html',
  styleUrls: ['./redeem-print.component.scss']
})
export class RedeemPrintComponent {
  @Input() data: any;

}

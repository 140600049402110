import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EarningsResponse } from '../models/earning-models/earnings-response';
import { AccountResponse } from '../models/account-models/account-response';
import { StatementCreditResponse } from '../models/statement-credit/statement-credit-response';
import { CurrentEarningsResponse } from '../models/statement-credit/current-earnings-response';
import { RedemptionResponse } from '../models/redemption-models/redemption-response';
import { GalleryVehicle } from '../models/vehicle-models/gallery-vehicle';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private readonly http: HttpClient) {}


  getAemPage(webpage: string): Observable<any> {
    return this.http.get('getAemPage/' + webpage);
  }

  getVerifyUserSessionByAccountId(accountId: string): Observable<any> {
    return this.http.get('updateSession/' + accountId);
  }
  getCustomer(accountId: string): Observable<AccountResponse> {
    return this.http.get<AccountResponse>('customer/' + accountId);
  }

  getVerifyUserSession(): Observable<any> {
    return this.http.get('verifySession');
  }

  getValidateEmail(customer: any): Observable<any> {
    return this.http.post('validate', customer);
  }

  getSsoUrl(email?: string, fallBack?: boolean): Observable<any> {
    let param;
    if (!email && !fallBack) {
      param = '';
    } else {
      param = {
        params: {
          email: email ? email : '',
          fallBack: fallBack ? fallBack : 'false'
        }
      };
    }

    return this.http.get('login', param);
  }

  getDealersInfoByZipcode(brand, zipcode) {
    const endpoint = `dealer/${brand}/${zipcode}/25`;
    return this.http.get(endpoint);
  }

  getEarningsStatement(accountId: string): Observable<EarningsResponse> {
    const endpoint = `customer/earnings-statement/${accountId}`;
    return this.http.get<EarningsResponse>(endpoint);
  }

  getCurrentEarnings(
    accountId: string,
    redemptionType: string
  ): Observable<CurrentEarningsResponse> {
    const endpoint = `customer/current-earnings/${accountId}?source=CardSite&redemptionType=${redemptionType}`;
    return this.http.get<CurrentEarningsResponse>(endpoint);
  }

  getRegionalOffers(zipCode: string): Observable<any> {
    return this.http.get('offers/' + zipCode);
  }

  getStatementCredit(
    accountId: string,
    amount: string
  ): Observable<StatementCreditResponse> {
    const endpoint = `redeem/${accountId}?source=CardSite&redemptionType=Cash&amount=${amount}`;
    return this.http.get<StatementCreditResponse>(endpoint);
  }
  getRedemptionHistory(accountId: string): Observable<RedemptionResponse> {
    return this.http.get<RedemptionResponse>('redemption/history/' + accountId);
  }
  getLogoutUrl(): Observable<any> {
    return this.http.get('logout');
  }

  getVehicleGallery(): Observable<Map<string, GalleryVehicle[]>> {
    const endpoint = 'vehicles/gallery';
    return this.http.get<Map<string, GalleryVehicle[]>>(endpoint);
  }
}

<div class="quick-links" layout>
  <div>
    <h3>Other Accounts</h3>
    <ul>
      <li *ngFor="let card of rewardCards; index as i" (click)="switchCard(i)">
        <a *ngIf="card.last4CardNumber !== cardType.last4CardNumber">
          {{card.name}} {{card.last4CardNumber}}
        </a>
      </li>
      <li>
        <a
          href="{{rewardsDashboardLink + '?evar25=gm_card_other_accounts'}}"
          target="_blank"
          class="stat-text-link account-link"
          data-dtm="other accounts"
          >GM Account</a
        >
      </li>
    </ul>
  </div>
  <app-manage-card
    [cardType]="cardType"
    [goldmanSachsLink]="goldmanSachsLink"
    class="d-block d-sm-none d-lg-block"
  ></app-manage-card>
  <app-link-account
    [cardType]="cardType"
    [accountPath]="myAccountActiveLink"
    class="d-block d-sm-none d-lg-block"
  ></app-link-account>
</div>

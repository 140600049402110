import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ContentService } from '../../../../_shared/services/content.service';
import { EarningsResponse } from '../../../../_shared/models/earning-models/earnings-response';
import { Card } from '../../../../_shared/models/card-models/card';
import { CardOffer } from 'src/app/_shared/models/offers-models/card-offer';
import { AemService } from '../../../../_shared/services/aem-content.service';

@Component({
  selector: 'app-offers-banner',
  templateUrl: './offers-banner.component.html',
  styleUrls: ['./offers-banner.component.scss']
})
export class OffersBannerComponent implements OnInit {
  @Input() cardType: Card;
  @Input() userEarnings: EarningsResponse;

  componentDestroyed = new Subject();
  showBanner = false;
  bannerOffers: CardOffer[] = [];
  visibleOffers: Array<string> = [];

  constructor(
    private aem: AemService
  ) { }

  ngOnInit(): void {
    this.initializeBannerOffers();
  }

  initializeBannerOffers(): void {
    console.log('Initialize offers banner');
    const currentDate: Date = new Date();
    this.aem
      .currentContent
      .subscribe(offersData => {
        const data = JSON.parse(offersData?.offersJson)
        // Check for offers with showWithoutUserOfferId
        for (const offerId in data?.offers) {
          if (!Object.prototype.hasOwnProperty.call(data?.offers, offerId)) {
            continue;
          }
          const offer: CardOffer = data.offers[offerId];
          if (!offer.showWithoutUserOfferId) {
            continue;
          }
          const startDate = new Date(offer.startDate);
          const expirationDate = new Date(offer.expirationDate);
          if (currentDate >= startDate && currentDate <= expirationDate) {
            this.bannerOffers.push(offer);
            this.visibleOffers.push(offer.offerId);
          }
        }

        // Display offers associated with user
        this.userEarnings.offers.forEach(offer => {
          console.log('Offer data:', offer);
          const currentOffer = data.offers[offer.offerID];
          if (typeof data.offers[offer.offerID] !== 'undefined') {
            const startDate = new Date(currentOffer.startDate);
            const expirationDate = new Date(currentOffer.expirationDate);
            if (currentDate >= startDate && currentDate <= expirationDate) {
              this.bannerOffers.push(currentOffer);
              this.visibleOffers.push(offer.offerID);
            }
          }
        });

        this.bannerOffers?.length
          ? (this.showBanner = true)
          : (this.showBanner = false);
      });
  }

  closeBanner(offerId: string): void {
    console.log('remove', offerId);
    this.visibleOffers.splice(this.visibleOffers.indexOf(offerId), 1);
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { SummaryService } from '../../services/summary.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { CardService } from '../../services/card.service';
import { Router } from '@angular/router';
import { ContentService } from '../../../_shared/services/content.service';
import { AdobeDtmService } from '../../../_shared/services/adobe-dtm.service';
import { PageInfo } from '../../../_shared/models/adobe/page-info';
import { EarningsResponse } from 'src/app/_shared/models/earning-models/earnings-response';
import { Title } from '@angular/platform-browser';
import { CurrentEarningsResponse } from 'src/app/_shared/models/statement-credit/current-earnings-response';

@Component({
  selector: 'app-redeem',
  templateUrl: './redeem.component.html',
  styleUrls: ['./redeem.component.scss']
})
export class RedeemComponent implements OnInit, OnDestroy {
  redeemGuideClass: string;
  earningsSubscription: Subscription;
  currentEarningsSubscription: Subscription;

  componentDestroyed = new Subject();
  totalEarnings: number;
  searchResult;
  accountInGoodStanding: boolean;
  markers;
  google: any;
  statementCreditUrl: any;
  redeemGuideList = [
    {
      image: './assets/img/icon-one.png',
      imgClass: 'choose',
      bigText: 'CHOOSE',
      smallText:
        'your new Chevrolet,<br />Buick, GMC or Cadillac<br /> vehicle at your<br /> nearest dealership.'
    },
    {
      image: './assets/img/icon-two.png',
      imgClass: 'agree',
      bigText: 'AGREE',
      smallText:
        'on your best price<br /> after taking advantage<br />of vehicle offers.'
    },
    {
      image: './assets/img/icon-three.png',
      imgClass: 'deduct',
      bigText: 'DEDUCT YOUR <br />EARNINGS',
      smallText:
        'plus any exclusive<br />Cardmember offers<br />from your agreed<br />upon price.'
    }
  ];
  redeemGuideListEfc = [
    {
      image: './assets/img/icon-one.png',
      imgClass: 'choose',
      bigText: 'CHOOSE',
      smallText:
        'your new<br />Chevrolet,<br />Buick, GMC or<br />Cadillac vehicle<br />at your nearest<br />dealership.'
    },
    {
      image: './assets/img/icon-two.png',
      imgClass: 'call',
      bigText: 'CALL',
      smallText:
        'to obtain your<br />vehicle<br />authorization<br />number.&dagger;'
    },
    {
      image: './assets/img/icon-three.png',
      imgClass: 'agree',
      bigText: 'AGREE',
      smallText:
        'on your best<br />price after<br />taking<br />advantage of<br />vehicle offers<br />and your GM<br />Employee or<br />Supplier<br />Discount (if<br />eligible).'
    },
    {
      image: './assets/img/icon-four-efc.png',
      imgClass: 'deduct',
      bigText: 'DEDUCT<br /> YOUR<br /> EARNINGS',
      smallText:
        'plus any<br />exclusive<br />Cardmember<br />offers from your<br />agreed upon<br />price.'
    }
  ];
  redeemGuideListBusiness = [
    {
      image: './assets/img/icon-one-business.png',
      imgClass: 'choose',
      bigText: 'CHOOSE',
      smallText:
        'your new Chevrolet,<br />Buick, GMC or<br />Cadillac vehicle at<br /> your nearest<br />dealership.'
    },
    {
      image: './assets/img/icon-two-business.png',
      imgClass: 'agree',
      bigText: 'AGREE',
      smallText:
        'on your best price<br /> after taking advantage <br />of vehicle offers.'
    },
    {
      image: './assets/img/icon-three-business.png',
      imgClass: 'deduct',
      bigText: 'DEDUCT YOUR <br />EARNINGS',
      smallText:
        'plus any exclusive <br />Cardmember offers <br />from your agreed<br /> upon price.'
    }
  ];
  redeemGuideListCopperPlatinum = [
    {
      image: './assets/img/icon-one.png',
      imgClass: 'choose',
      bigText: 'CHOOSE',
      smallText:
        'your new Chevrolet,<br />Buick, GMC or Cadillac<br /> vehicle at your<br /> nearest dealership.'
    },
    {
      image: './assets/img/icon-two.png',
      imgClass: 'agree',
      bigText: 'AGREE',
      smallText:
        'on your best price<br /> after taking advantage<br />of vehicle offers.'
    },
    {
      image: './assets/img/icon-three.png',
      imgClass: 'deduct',
      bigText: 'DEDUCT YOUR <br />EARNINGS',
      smallText:
        'up to redemption allowances'
    }
  ];
  gmFamilyFirstUrl = 'https://www.gmfamilyfirst.com/';
  gmSupplierDiscountUrl = 'https://www.gmsupplierdiscount.com/';
  selectedCard: string;
  cardName: string;
  contactUsLink: string;
  digitalData: PageInfo;
  constructor(
    private readonly adobeDTMService: AdobeDtmService,
    private readonly summaryService: SummaryService,
    private readonly cardService: CardService,
    private readonly contentService: ContentService,
    private router: Router,
    private readonly titleService: Title
  ) { }

  ngOnInit(): void {
    this.contactUsLink =
      this.contentService.getContactUsLink() + '?evar25=gm_card_get_answers';

    this.earningsSubscription = this.summaryService.cardRedemption$.subscribe(
      (earnings: EarningsResponse) => {
        console.log('Subscription updated');
        this.accountInGoodStanding = earnings?.accountDetails?.canRedeem;
        this.totalEarnings = earnings?.earnings?.availableEarnings?.total;
      }
    );

    this.currentEarningsSubscription = this.summaryService.currentEarnings$
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((earnings: CurrentEarningsResponse) => {
        this.totalEarnings = earnings?.availableEarnings;
      });

    this.cardService
      .getCurrentCard()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(card => {
        this.titleService.setTitle(`How to Redeem | ${card.name}`);
        this.selectedCard = card.path;
        this.cardName = card.name;
        this.statementCreditUrl =
          '/' + this.selectedCard + '/redeem/redeem-amount';
        // select the right list depend on cardType
        if (this.selectedCard === 'efc') {
          this.redeemGuideList = this.redeemGuideListEfc;
        } else if (this.selectedCard === 'bpbc') {
          this.redeemGuideList = this.redeemGuideListBusiness;
        } else if (this.selectedCard === 'copperplatinum') {
          this.redeemGuideList = this.redeemGuideListCopperPlatinum;
        }
        const redeemGoal = 'goal';
        this.digitalData = {
          pageInfo: {
            pageName:
              'how.to.redeem | logged | ' +
              this.selectedCard?.toLocaleLowerCase(),
            seoStrategyPageName: '',
            pageType: 'redeem',
            pageSubType: '',
            cardType: card.analyticsType,
            visitorStatus: 'logged',
            cardTypeOwned: card.analyticsTypeOwned,
            offerID: '',
            loginType: card.analyticsLoginType,
            pageGoal: redeemGoal
          },

          formInfo: {
            formName: '',
            formFieldName: '',
            errorMessage: ''
          }
        };

        this.digitalData = this.adobeDTMService.setupWindowPageInfoData(
          this.digitalData,
          null,
          'Redeem'
        );

        this.adobeDTMService.setWindowObjectData(this.digitalData);
        this.sendTrackingInfo();
        this.adobeDTMService.sendAdobeLaunchData();
      });
  }

  getRedeemGuideClassName(): string {
    if (this.selectedCard === 'efc') {
      this.redeemGuideClass = 'redeem-guide-efc';
      return this.redeemGuideClass;
    } else if (this.selectedCard === 'bpbc') {
      this.redeemGuideClass = 'redeem-guide-bpbc';
      return this.redeemGuideClass;
    } else if (this.selectedCard === 'copperplatinum') {
      this.redeemGuideClass = 'redeem-guide-copperplatinum';
      return this.redeemGuideClass;
    } else {
      this.redeemGuideClass = 'redeem-guide';
      return this.redeemGuideClass;
    }
  }

  requestStatementCredit(): void {
    void this.router.navigate(['redeem-amount']);
    window.scrollTo(0, 0);
  }

  sendTrackingInfo() {
    this.digitalData = this.adobeDTMService.setupWindowPageInfoData(
      this.digitalData,
      null,
      'Redeem'
    );
    this.adobeDTMService.setWindowObjectData(this.digitalData);
  }

  updateSearchResult(event): void {
    this.searchResult = event;
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
    this.earningsSubscription.unsubscribe();
    this.currentEarningsSubscription.unsubscribe();
  }
}


import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { LoginEmailFoundNoticeComponent } from '../login-email-found-notice/login-email-found-notice.component';
import { LoginEmailNotFoundNoticeComponent } from '../login-email-not-found-notice/login-email-not-found-notice.component';
import { ErrorModalComponent } from 'src/app/_shared/components/error-modal/error-modal.component';
import { CookieService } from 'ngx-cookie-service';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '../../../_shared/services/api.service';
import { Constants } from '../../../app.constants';
import { UtilityService } from 'src/app/_shared/services/utility.service';
import { PageInfo } from 'src/app/_shared/models/adobe/page-info';
import { AdobeDtmService } from 'src/app/_shared/services/adobe-dtm.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  componentDestroyed = new Subject();
  signInForm: UntypedFormGroup;
  loginValidation = false;
  digitalData: PageInfo;
  constants = Constants;
  aemContent: any;

  constructor(
    private readonly api: ApiService,
    private readonly cookieService: CookieService,
    private readonly fb: UntypedFormBuilder,
    private readonly router: Router,
    public dialog: MatDialog,
    private readonly adobeDTMService: AdobeDtmService,
    private readonly titleService: Title
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Login | GM Rewards Cards');
    
    this.signInForm = this.fb.group({
      emailAddress: ['', [Validators.required, Validators.email]],
      hidePage: [false, []]
    });

    this.digitalData = {
      pageInfo: {
        cardType: 'common',
        pageName: 'earnings.login | public | common',
        pageType: 'homepage'
      },
      formInfo: {
        formName: 'login'
      }
    };

    this.digitalData = this.adobeDTMService.setupWindowPageInfoData(
      this.digitalData,
      'welcome',
      'Welcome'
    );

    this.adobeDTMService.setWindowObjectData(this.digitalData);
    this.adobeDTMService.sendAdobeLaunchData();
  }

  checkEarningsClick() {
    this.router.navigate(['auth']);
  }

  verifyEmail() {
    if (this.signInForm.valid) {
      this.loginValidation = true;
      this.signInForm.controls.hidePage.value
        ? this.cookieService.set('hide-migration', 'HIDE')
        : this.cookieService.delete('hide-migration');

      const customerEmail = this.signInForm.controls.emailAddress.value;
      let obj = {'emailAddress' : customerEmail}

      this.api
        .getValidateEmail(obj)
        .pipe(takeUntil(this.componentDestroyed))
        .subscribe(
          data => {
            if (data.iamStatus == IamStatus.Valid) {
              //In both cases for data.isLinkedAccount API will send the redirect Url and modals will redirect user to them.
              this.openFoundEmailModal(customerEmail);
            } else {
              this.openNotFoundEmailModal(data, customerEmail);
            }
            this.loginValidation = false;
          },
          error => {
            this.openErrorDialog();
            this.loginValidation = false;
            console.error(error);
          }
        );
    }
  }

  openErrorDialog() {
    this.dialog.open(ErrorModalComponent);
  }

  openFoundEmailModal(email: string) {
    const dialogRef = this.dialog.open(LoginEmailFoundNoticeComponent, {
      width: UtilityService.calculateScaleFactor(window.innerWidth),
      panelClass: 'blue-dialog'
    });
    dialogRef.componentInstance.email = email;
  }

  openNotFoundEmailModal(data: any, email: string) {
    const dialogRef = this.dialog.open(LoginEmailNotFoundNoticeComponent, {
      width: UtilityService.calculateScaleFactor(window.innerWidth),
      panelClass: 'blue-dialog'
    });
    dialogRef.componentInstance.url = data.redirectUrl;
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }
}

enum IamStatus {
  Valid,
  Invalid
}
